import styled from 'styled-components';
export const ArticlesNewsCardStyled = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    border-radius: 18px;
    border: 2px solid var(--secondary-surface, #f4f4f4);
    background: #fff;
    transition: background-color 0.3s linear;

    &:hover {
        background-color: #f4f4f4;
    }

    .imgContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .articleNews_img {
        height: 170px;
        object-fit: cover;
        border-radius: 10px;
    }

    .articleNews_title {
        color: var(--primary-text, #212121);
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        word-break: break-word;
    }

    .articleNews_footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 30px;
        row-gap: 8px;
        flex-wrap: wrap;
    }
    .articleNews_date,
    .articleNews_theme {
        color: var(--primary-text, #212121);
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }

    .categoryBlock {
        display: flex;
        align-items: center;
        gap: 8px;
        svg {
            height: 18px;
            width: 18px;
        }
    }

    @media (max-width: 768px) {
        gap: 30px;
    }

    @media (max-width: 500px) {
        border-radius: 12px;
        .articleNews_footer {
            column-gap: 12px;
        }
        .articleNews_title {
            font-size: 14px;
        }
    }
`;
