import React, { useEffect, useState } from 'react';
import { ConsultationListStl } from './consultationList.styled';
// import { useAppSelector } from '../../../../store/index.ts';
import { Outlet, useLocation } from 'react-router-dom';
import { ConsultationList } from './ConsultationList';
// import { selectFutureConsultations, selectPastConsultations } from '../../api/lkDoctorConsultationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ModalSuccessfulConsultation } from '../../lkDoctorModals/modalSuccessfulConsultation/ModalSuccessfulConsultation.jsx';
import { Modal } from '../../../share/Modal.jsx';
import { setIsModalSuccessfulConsultationDoctor } from '../../api/lkDoctorConsultationsSlice';

export const DoctorConsultationList = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const regex = /\/\d+$/; // проверка на наличие цифр (id консультации)
    const {
        doctorConsultations,
        isLoading,
        isModalSuccessfulConsultation,
        timeConsultation,
    } = useSelector((state) => state.doctorConsultations);
    const [consultationsList, setConsultationsList] = useState({});

    useEffect(()=>{
        !pathname.includes('future') ?
            setConsultationsList(doctorConsultations.past) :
            setConsultationsList(doctorConsultations.future);
    },[pathname, doctorConsultations])

    const closePopupSuccessfulConsultation = () => {
        dispatch(setIsModalSuccessfulConsultationDoctor());
    }

    // const { isLoading, consultationsList, next } = useAppSelector(
    //     pathname.includes('future') ? selectFutureConsultations : selectPastConsultations
    // );

    return (
        <ConsultationListStl>
            {regex.test(pathname) ? (
                <Outlet />
            ) : (
                <ConsultationList list={consultationsList} pathname={pathname} isLoading={isLoading} />
            )}
            {isModalSuccessfulConsultation &&
                <Modal toggle={closePopupSuccessfulConsultation}>
                    <ModalSuccessfulConsultation
                        closePopupSuccessfulConsultation={closePopupSuccessfulConsultation}
                        timeConsultation={timeConsultation}
                    />
                </Modal>
            }
        </ConsultationListStl>
    );
};
