import styled from 'styled-components';

export const StyledServices = styled.section`
  width: 100%;
  background: #F4F4F4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 180px 15px;

  @media (max-width: 768px) {
    padding: 50px 15px;
  }

  .services-wrapper {
    max-width: 950px;
    width: 100%;
  }

  position: relative;

  & .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 40px;
    width: 100%;
    justify-items: center;
  }

  title {
    align-self: start;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    display: flex;
    line-height: 120%;
    color: #212121;
  }
`;
