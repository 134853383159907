import styled from 'styled-components';

export const StyledDoctorCard = styled.li`
    padding: 40px;
    border: 2px solid #f4f4f4;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    transition: background-color 0.3s linear;
    cursor: pointer;
  
    :hover {
        background-color: #f4f4f4;
    }
    @media(max-width: 1024px) {
      padding: 24px;
    }
    /* @media(max-width: 844px) {
    } */
    @media(max-width: 575px) {
      padding: 10px;
      flex-direction: column;
    }
`;
