import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTopDoctors, getTopDoctorsAuth, removeFavoriteDoctorsUid, getFavoriteDoctors } from "./apiDoctorsTop";

export const fetchTopDoctors = createAsyncThunk(
  "topDoctorsSlice/fetchTopDoctors",
  async () => {
    const response = await getTopDoctors();
  return response.data 
  }
);

export const fetchTopDoctorsAuth = createAsyncThunk(
  "topDoctorsSlice/fetchTopDoctorsAuth",
  async () => {
    const response = await getTopDoctorsAuth();
  return response.data 
  }
);

const topDoctorsSlice = createSlice({
  name: "topDoctorsSlice",
  initialState: {
    topDoctors: [],
    error: null,
    isPreloader: false,
    isTopDoctorsAuth: false,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchTopDoctors.pending, (state, action) => {
      state.isPreloader = true;
    });
    builder.addCase(fetchTopDoctors.fulfilled, (state, action) => {
      state.isPreloader = false;
      state.topDoctors = action.payload.data.top.filter((item)=> item.doctor !== null);
    });
    builder.addCase(fetchTopDoctors.rejected, (state, action) => {
      state.isPreloader = false;
      state.error = action.payload;
    });
    builder.addCase(fetchTopDoctorsAuth.pending, (state, action) => {
      state.isTopDoctorsAuth = true;
    });
    builder.addCase(fetchTopDoctorsAuth.fulfilled, (state, action) => {
      state.topDoctors = action.payload.data.top.filter((item)=> item.doctor !== null);
      state.isPreloader = false;
      state.isTopDoctorsAuth = false;
    });
    builder.addCase(fetchTopDoctorsAuth.rejected, (state, action) => {
      state.isPreloader = false;
      state.isTopDoctorsAuth = false;
      state.error = action.payload;
    });
  },
});

export default topDoctorsSlice.reducer;
