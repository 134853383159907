import React from 'react';
import styled from 'styled-components';
import Button from '../../../share/Button';
import close from '../../../../img/close-icon.svg';
import Flex from '../../../share/Flex';
import { useSelector } from 'react-redux';
import { Loader } from '../../../loader/loader';
import { Modal } from '../../../share/Modal';

const CalendarModalStl = styled.div`
    background: #ffffff;
    border-radius: 15px;
    padding: 29px;
    width: 361px;
    display: flex;
    align-items: center;
    flex-direction: column;

    h2 {
        font-weight: 500;
        font-size: 28px;
        line-height: 33px;
        color: #06a7b3;
        margin: 15px 0 18px 0;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #062a3f;
        text-align: center;
        margin: 0 0 24px 0;
    }
    img {
        cursor: pointer;
        width: 14px;
    }
`;

const LoaderStl = styled.div`
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CalendarModal = ({ toggle }) => {
    const isLoading = useSelector((state) => state.doctorCalendar.isLoading);

    if (isLoading) {
        // процесс создания расписания на все дни сразу не быстрый, поэтому лоудер
        return (
            <Modal>
                <LoaderStl>
                    <Loader />
                </LoaderStl>
            </Modal>
        );
    }

    return (
        <CalendarModalStl>
            <Flex justify="flex-end" width="100%">
                <img src={close} alt="" onClick={() => toggle(false)} />
            </Flex>
            <Flex direction="column" width="100%" align="center">
                <h2>Уведомление</h2>
                <p>Выбранный интервал времени сохранён для выбранных дат</p>
            </Flex>
            <Flex margin="0 auto 41px" align="center">
                <Button green L onClick={() => toggle(false)}>
                    Продолжить{' '}
                </Button>
            </Flex>
        </CalendarModalStl>
    );
};
