import styled from 'styled-components';

export const StyledContacts = styled.div`
    h3 {
        font-weight: 400;
        font-size: 24px;
        color: #212121;
    }
    .where {
        &__title {
            margin-bottom: 62px;
            font-weight: 500;
            font-size: 42px;
        }
        margin-bottom: 179px;
    }
    .contact-us {
        &__title {
            margin-bottom: 63px;
            font-weight: 500;
            font-size: 42px;
        }
    }
    .cardList {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
        grid-template-rows: minmax(154px, auto);
        justify-content: space-between;
        margin-bottom: 62px;
    }
    .social {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 184px;
        &__title {
            font-size: 18px;
            margin-right: 32px;
        }
    }
    .address {
        margin-top: 30px;
    }

    @media (max-width: 879px) {
        .cardList {
            margin-bottom: 37px;
        }
        .social {
            margin-bottom: 124px;
        }
        .where {
            margin-bottom: 119px;
        }
    }
    @media (max-width: 768px) {
        .cardList {
            display: block;
            margin-bottom: 28px;
        }
        .social {
            margin-bottom: 78px;
            &__title {
                font-size: 16px;
            }
        }
        .where {
            margin-bottom: 75px;
            &__title {
                font-size: 28px;
                margin-bottom: 29px;
            }
            &__map iframe {
                height: 200px;
            }
        }
        .contact-us {
            &__title {
                font-size: 28px;
                margin-bottom: 30px;
            }
        }
    }
`;
