import React from 'react';
import { StyledLoader } from './loader.styled';

/**
 * This component renders a loader animation.
 *
 * @component
 * @example
 * return (
 *   <Loader />
 * )
 */

export const Loader = () => {
    return (
        <StyledLoader>
            <div className="loader__wrapper">
                <div className="loader"></div>
            </div>
        </StyledLoader>
    );
};
