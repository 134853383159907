import React from 'react';
import { NewFormSTL } from './newFormSTL.styled';
import InputPrice from '../../../../share/formFields/newElements/InputPrice';
import { validateRequired } from '../../../../share/formFields/validateInput';

const Price = () => {
    return (
        <NewFormSTL>
            <div className="price__wrapper">
            <InputPrice
                name="price.price"
                label="Стоимость консультации, руб."
                placeholder="10000"
                isRequired={true}
                validation={validateRequired(true)}
            />
            </div>
        </NewFormSTL>
    );
};

export default Price;
