import React, {useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import { WalletStl } from './wallet.styled.js';

export const Wallet = () => {
  const path = useLocation()
  const redirect = useNavigate()

  useEffect(() => {
    if(path.pathname === '/lk/doctor-profile/wallet') {
      redirect('/lk/doctor-profile/wallet/transactions', { replace: true });
    }
  }, [path])

  return (
      <WalletStl>
        <Outlet/>
      </WalletStl>
  );
}
