import styled from 'styled-components';

export const StyledCloseConsultationModal = styled.div`
    .modalCancel {
        max-width: 414px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        cursor: default;
        padding: 52px 40px 40px;
        border-radius: 24px;
        position: relative;
        &_cross__icon {
            position: absolute;
            cursor: pointer;

            top: 30px;
            right: 30px;
        }
        & .modalCancel__text {
            margin: 40px auto;
            font-family: Rubik;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            text-align: center;
            color: #666;
        }

        /* & .modalCancel__link {
            margin-bottom: 30px;
            color: #19be6f;
            text-align: center;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        } */
        & .modalCancel__conditions {
            margin-top: 22px;
            font-family: Rubik;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;

        }
        & .modalCancel__close {
            width: 100%;
            border: none;
            padding: 10px 24px;
            border-radius: 8px;
            /* border: 3px solid #19be6f;
            background: transparent;
            color: #19be6f; */
            background: #19BE6F;
            color: #FFF;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 19px;
        }
    }

    .buttons {
        margin-top: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        & button {
            cursor: pointer;
        }

        & button {
            width: 245px;
            height: 56px;
            padding: 16px;
            font-weight: 400;
            font-size: 16px;
        }
    }

    .close {
        background: #19be6f;
        border: 1px solid #19be6f;
        border-radius: 10px;
        color: #ffffff;
    }

    .back {
        background: #ffffff;
        border: 2px solid #19be6f;
        border-radius: 10px;
        color: #19be6f;
    }
    @media (max-width: 320px) {
        .modalContent {
            padding: 15px;
            & span {
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
            }
        }
        .buttons {
            margin-top: 18px;
        }
    }
`;
