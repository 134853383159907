import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FieldWrapper } from './FieldWrapper';
import { useDispatch } from 'react-redux';
import { clearMessage } from '../../authentification/api/authentificationSlice';

/**
 * Компонент InputPassword — это поле ввода для пароля, которое поддерживает
 * валидацию, в том числе проверку на совпадение паролей.
 * @param {string} name - Имя основного поля пароля (например, 'password').
 * @param {string} [confirmName=''] - Имя поля для подтверждения пароля (например, 'confirmPassword'), по умолчанию пустое.
 * @param {string} [placeholder] - Текст placeholder для поля пароля.
 * @param {object} [validation={}] - Объект с дополнительными правилами валидации.
 * @param {string} [label] - Текст лэйбла для поля.
 * @param {function} [onChange=() => {}] - Внешняя функция для обработки изменения значения поля.
 * @param {boolean} [isRequired=false] - параметр, который определяет, является ли поле обязательным для заполнения.
 * @param {object} [messagesError] - Сообщения об ошибках, переданные для вывода на UI.
 * @returns {JSX.Element} Поле ввода для пароля с валидацией.
 */
const InputPassword = ({
    name,
    confirmName = '',
    placeholder,
    validation = {},
    label,
    onChange: outSideOnChange = () => {}, // outSideOnChange можем передать внешний коллбек, если будет нужно
    isRequired = false,
    messagesError,
}) => {
    const dispatch = useDispatch();
    const { control, trigger, setError, setValue, clearErrors, watch } = useFormContext();
    const passwordValue = watch(name);
    const confirmPasswordValue = watch(confirmName);

    const validatePassword = (value) => {
        if (name === 'confirmPassword') {
            if (value !== confirmPasswordValue) {
                setError(name, {
                    type: 'manual',
                    message: 'Пароли не совпадают',
                });
                return 'Пароли не совпадают';
            }
            clearErrors(name);
        } else if (name === 'password') {
            if (!/^[A-Za-z0-9]+$/.test(value)) {
                return 'Используйте только латинские буквы или цифры';
            }

            if (value.length < 8) {
                return 'Минимальная длина: 8 символов';
            }

            if (!/[A-Za-z]/.test(value)) {
                return 'Пароль не может состоять только из цифр';
            }
            return true;
        }
    };

    const defaultValidation = {
        required: { value: true, message: 'Поле обязательно для заполнения' },
        validate: validatePassword,
        ...validation,
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules: defaultValidation });

    useEffect(() => {
        if (passwordValue && confirmPasswordValue) {
            if (passwordValue === confirmPasswordValue) {
                clearErrors(name);
                clearErrors(confirmName);
            } else {
                trigger(name);
                trigger(confirmName);
            }
        }
    }, [passwordValue, confirmPasswordValue, trigger, clearErrors, setError, name, confirmName]);

    const handleBlur = () => {
        trigger(field.name);
    };

    const clearedView = '';

    const handleKeyDown = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;

        if (messagesError) {
            dispatch(clearMessage(e.target.name));
        }

        setValue(e.target.name, value);
        trigger(e.target.name);
    };

    const handleClear = () => {
        field.onChange(clearedView);
        outSideOnChange(clearedView);
        trigger(field.name);
        dispatch(clearMessage(messagesError));
    };

    return (
        <FieldWrapper
            error={error}
            messagesError={messagesError}
            label={label}
            isRequired={isRequired}
            clearCondition={!!field.value}
            onClear={handleClear}
        >
            <input
                {...field}
                onBlur={handleBlur}
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                type="password"
                autoComplete={name === 'password' ? 'new-password' : 'current-password'}
                onChange={(e) => handleInputChange(e)}
                onKeyDown={handleKeyDown}
            />
        </FieldWrapper>
    );
};

export default InputPassword;
