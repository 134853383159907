import { Loader } from '../../../loader/loader';
import { PatientData } from './patientData/PatientData';
import React from 'react';
import { ViewPatientStl } from './viewPatient.styled';
import { useSelector } from 'react-redux';

export const ViewPatient = () => {
    const isLoaderProfile = useSelector((state) => state.patientProfile.isLoader);

    if (isLoaderProfile) {
        return <Loader />;
    }

    return (
        <ViewPatientStl>
            <PatientData />
        </ViewPatientStl>
    );
};
