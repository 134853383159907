import React from 'react';
import styled from 'styled-components';
import { FieldWrapper } from '../FieldWrapper';
import { useController, useFormContext } from 'react-hook-form';

const TextareaStl = styled.textarea`
    width: 100%;
    height: 160px;
    resize: none;
    vertical-align: top;
`;

const TextareaField = ({ name, placeholder, validation, label }) => {
    const { control, trigger } = useFormContext();

    const defaultValidation = {
        required: { value: false, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 1000,
            message: 'Максимальная длина: 1000 символов',
        },
        validate: (str) => {
            if (str.length && !str.trim()) {
                return 'Некорретно заполненное поле';
            }
        },
        ...validation,
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules: defaultValidation });

    const handleBlur = () => {
        let inputValue = field.value;

        if (typeof inputValue === 'string') {
            // Удаление пробелов в начале и в конце строки
            inputValue = inputValue?.trim();
        }
        trigger(field.name);
        field.onChange(inputValue);
    };

    const handleClearInput = () => {
        field.onChange(''); // Очищаем
    };

    return (
        <FieldWrapper error={error} label={label} clearCondition={!!field.value} onClear={handleClearInput}>
            <TextareaStl
                {...field}
                onBlur={handleBlur}
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                id={name}
            />
        </FieldWrapper>
    );
};

export default TextareaField;
