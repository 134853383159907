import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ageCalculator } from '../../../share/helpers';
import { CardWrapper } from './patientCard.styled';
import { removeConsultations } from '../../api/doctorPatientsSlice';

export default function PatientCard({ patient }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const navigatePatintPage = () => {
        dispatch(removeConsultations());
        navigate(`${patient.user.uid}`);
    };
    return (
        <CardWrapper onClick={navigatePatintPage}>
            <img className="card__img" src={patient.user.avatar} alt={patient.user.last_name} />
            <div className="card__content">
                <p className="card__name">
                    {patient.user.last_name} {patient.user.first_name} {patient.user.patronymic}
                </p>
                <p className="card__genderAge">
                    {patient.user.gender_label}, {ageCalculator(patient.user.birthday)}
                </p>
            </div>
        </CardWrapper>
    );
}
