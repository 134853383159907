import React, { useState } from 'react';
import UserDate from './components/UserData';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
    PostDoctorAdvancedTraining,
    PostDoctorCreateDisease,
    PostDoctorHigherEducation,
    fetchDoctor_create,
    fetchLoadAdvancedTraining,
    postDoctorExpiriens,
    postDoctorSpecialization,
} from '../../api/doctorUserProfileSlice';
import { dateToUnix, dateToUnixMY } from '../../../share/helpers';
import HigherEducationForm from './components/HigherEducationForm';
import AdvancedTraining from './components/AdvancedTraining';
import WorkExperience from './components/WorkExperience';
import Specialization from './components/Specialization';
import Diagnostic from './components/Diagnostic';
import { NewFormSTL } from './components/newFormSTL.styled';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Price from './components/Price';

const FormDoctor = ({ methods }) => {
    const doctorPersonalData = useSelector((state) => state.doctorProfile?.doctorProfile);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    // проверяем первый раз входит пользователь на сайт или нет, и если да, то редиректим на view
    const { last_name } = doctorPersonalData?.user;
    useEffect(() => {
        if (last_name) {
            navigate('/lk/doctor-profile/profile/view');
        }
    }, [last_name]);

    const onSubmit = (data) => {
        setLoader(true);
        const birthday = data.user.birthday.split('/');
        const promises = [];
        console.log(data);

        promises.push(
            dispatch(
                fetchDoctor_create({
                    user: {
                        ...data.user,
                        birthday: dateToUnix(birthday[2], birthday[1], birthday[0]),
                        city: data.user.city.value,
                    },
                    category: data.specialization?.category,
                    scientific_degree: data.specialization?.scienceDegree,
                    video_chat_price: data.price.price,
                })
            )
        );
        //Образование
        if (data.higher_education) {
            data.higher_education.forEach((el) => {
                promises.push(
                    dispatch(
                        PostDoctorHigherEducation({
                            ...el,
                            end_date: Date.UTC(el.end_date, 0, 1) / 1000,
                            qualification: 'заглушка',
                        })
                    )
                );
            });
        }

        // Квалификация
        if (data.advanced_training) {
            data.advanced_training.forEach(({ end_date, organization, position, file }) => {
                const formData = new FormData();
                formData.append('organization', organization);
                formData.append('position', position);
                formData.append('end_date', new Date(end_date, 1, 2).getTime() / 1000);
                formData.append('file', file[0]);
                promises.push(dispatch(fetchLoadAdvancedTraining(formData)));
            });
        }

        // Опыт работы
        if (data.work) {
            data.work.forEach((el) => {
                const start_date = dateToUnixMY(el.start_date);
                const end_date = dateToUnixMY(el.end_date);
                const newWork = {
                    ...el,
                    start_date: start_date,
                    end_date: end_date ? end_date : start_date,
                    until_now: el.until_now ? el.until_now : false,
                };
                promises.push(dispatch(postDoctorExpiriens(newWork)));
            });
        }

        // Специализация
        data.specialization?.specialization?.forEach((el) => {
            promises.push(dispatch(postDoctorSpecialization(el.value)));
        });
        promises.push(dispatch(PostDoctorCreateDisease(data.diagnostic.text)));

        Promise.all(promises)
            .then(() => {
                navigate('/lk/doctor-profile/profile/view');
                setLoader(false);
            })
            .catch((error) => {
                console.error(error.message);
                setLoader(false);
            });
    };
    const { formState: { isValid } } = useFormContext();

    return (
        <NewFormSTL>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <UserDate />
                <HigherEducationForm />
                <AdvancedTraining />
                <WorkExperience />
                <Specialization />
                <Diagnostic />
                <Price />
                <button disabled={!isValid} className="btn__recive">
                    {loader ? 'Загрузка...' : 'Отправить данные'}
                </button>
            </form>
        </NewFormSTL>
    );
};

export default FormDoctor;
