import React, { useEffect, useRef, useState } from 'react';
import { StyledSearchContainer, StyledSearchInput } from './searchInput.styled';

import SearchResult from './searchResult';
import { fetchMainSearch } from '../mainSearchSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useOutsideClick from '../../../hooks/useOutsideClick';

function SearchInput({ isSearchActive, setSearchActive = () => {} }) {
    const dispatch = useDispatch();
    const [iconVisible, setIconVisible] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const handleChange = (event) => {
        const newValue = event.target.value.replace(/[^А-Яа-яЁёa-zA-Z-\s]/g, '');
        setSearchValue(newValue);
    };
    const handleBlur = (event) => {
        setSearchValue(searchValue.trim());
    };
    const navigate = useNavigate();
    const goSearch = (e) => {
        if (e.key === 'Enter') {
            navigate('/searchDoctors');
            setSearchActive(!isSearchActive);
        } else return;
    };
    const input = useRef(null);

    useEffect(() => {
        dispatch(fetchMainSearch(searchValue === '' || searchValue === '  ' ? null : searchValue));
    }, [dispatch, searchValue]);

    function handleDropSearch(e) {
        if (e.target.name !== 'search_btn') {
            setSearchValue('');
            setIconVisible(true);
            setSearchActive(!isSearchActive);
        }
    }

    useOutsideClick(input, handleDropSearch, isSearchActive);

    return (
        <StyledSearchContainer>
            <StyledSearchInput>
                <img className="input__icon" src="/img/header/search.svg" />

                <input
                    ref={input}
                    className="search__input"
                    type="text"
                    required
                    minLength="2"
                    maxLength="152"
                    title=""
                    placeholder="Поиск врача по Ф.И.О"
                    value={searchValue}
                    autoFocus
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyUp={(e) => goSearch(e)}
                />
            </StyledSearchInput>

            {searchValue !== '' && <SearchResult setSearchActive={setSearchActive} setSearchValue={setSearchValue} />}
        </StyledSearchContainer>
    );
}
export default SearchInput;
