import React, { useEffect, useState } from 'react';
import { StyledProblemForm } from './problemForm.styled';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyConsultationUpdate, getPatientConsultation } from '../../api/PatientConsultationsSlice';
import { Spinner } from '../../../share/Spinner';
import { ConsultationFiles } from '../consultationList/patientConsultationPage/consultationFiles/ConsultationFiles';

export const ProblemForm = ({ id, problem, consultationId, files, feedbackId }) => {
    const dispatch = useDispatch();
    const [textProblem, setTextProblem] = useState(problem);
    const [isTextChange, setIsTextChange] = useState(false);
    const [textProblemError, setTextProblemError] = useState(' ');

    const isLoading = useSelector((state) => state.patientConsultations.isLoading);
    const onSubmit = (e) => {
        e.preventDefault();
        const data = {
            id: id,
            request: textProblem,
        };
        dispatch(fetchMyConsultationUpdate(data)).then(() => {
            dispatch(getPatientConsultation(id));
        });
    };
    const handleChange = (e) => {
        setIsTextChange(true)
        setTextProblem(e.target.value);
    }
    const validateInput = () => {
        textProblem.length < 2 && setTextProblemError('Минимальная длина: 2 символа');
        textProblem.length > 1000 && setTextProblemError('Максимальная длина: 1000 символов');
        (textProblem.length >= 2 && textProblem.length <= 1000) && setTextProblemError('');
    }
    useEffect(()=>{
        isTextChange && validateInput();
    },[textProblem]);

    return (
        <StyledProblemForm>
            <form className="consultationForm" onSubmit={onSubmit}>
                <p className="consultationFormHeader">Комментарий для врача</p>
                {problem ?
                    <p className="consultationProblemText">{textProblem}</p> :
                    <>
                        <textarea
                            className="consultationFormInput"
                            name="consultationFormInput"
                            id="consultationFormInput"
                            cols="30"
                            rows="10"
                            minLength={2}
                            maxLength={1000}
                            value={textProblem}
                            placeholder="По желанию оставьте комментарий"
                            onChange={handleChange}
                        />
                        <span className="consultationFormTextError">{textProblemError}</span>
                    </>
                }
                <ConsultationFiles
                    consultationId={consultationId}
                    files={files}
                    feedbackId={feedbackId}
                    problem={problem}
                />
                {!problem &&
                    <>
                        <p className="consultationFormButtonComment">Комментарий после отправки врачу нельзя будет изменить</p>
                        <button
                            className="consultationFormButtonSign"
                            onSubmit={(e) => {
                                onSubmit(e);
                            }}
                            disabled={textProblemError.length > 0}
                        >
                            {isLoading ? (
                                <Spinner color="2px solid white" width="18px" height="18px" marginTop="0" />
                            ) : (
                                'Отправить >'
                            )}
                        </button>
                    </>
                }
            </form>
        </StyledProblemForm>
    );
};
