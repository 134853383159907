import styled from 'styled-components';

export const StyledLkDoctor = styled.section`
    .content {
        width: 100%;
        display: flex;
        gap: 60px;
        
    }

    .rightBlock {
        width: calc((100% - 200px - 60px));

    }

    @media (max-width: 980px) {
        .content {
            gap: 30px;
        }
        .rightBlock {
            width: calc((100% - 200px - 30px));
        }
    }
    
    
    
    @media (max-width: 768px) {
        .content {
            position: relative;
            flex-direction: column;
            justify-content: center;
        }
        .rightBlock {
            width: 100%
        }
    }
`;
