import styled from 'styled-components';

export const StyledModalSuccessfulConsultation = styled.div`
    .modalSuccessful {
        max-width: 414px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        padding: 52px 73px 40px;
        border-radius: 24px;
        gap: 27px;

        &__logo {
            width: 109px;
            object-fit: contain;
        }
        &__time {
            margin: 0;
            padding: 8px 12px;
            background-color: #F4F4F4;
            border-radius: 15px;
            font-family: Rubik;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #212121;
        }
        &__heading {
            margin: 0;
            font-family: Rubik;
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
            text-align: center;
            color: #212121;
        }
        &__text {
            margin: 0;
            font-family: Rubik;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            text-align: center;
            color: #666666;
        }
        &__buttons {
            border: none;
            background-color: transparent;
            display: flex;
            gap: 35px;
            font-family: Rubik;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
        &__close {
            border: none;
            background-color: transparent;
            color: #666666;
        }
        &__feedback {
            border: none;
            background-color: transparent;
            color: #19BE6F;
        }

    }


    @media (max-width: 500px) {
        .modalSuccessful {
            max-width: 288px;
            padding: 52px 33px 40px;
            gap: 23px;

            &__logo {
                width: 70px;
            }
            &__heading {
                font-size: 18px;
                line-height: 23.4px;
            }
            &__text {
                font-size: 16px;
                line-height: 20.8px;
            }
        }
    }
`;
