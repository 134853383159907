import React, { useEffect, useRef } from 'react';
import { ArticlesNewsStyled } from './articlesNews.styled';
import Container from '../../../share/Container';
import ArticlesNewsCard from '../articlesNewsComponents/articlesNewsCard/ArticlesNewsCard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchPostsByTheme } from '../../../blog/api/blogSlice';
import { Spinner } from '../../../share/Spinner';
import useObserver from '../../../../hooks/useObserver';

const ArticlesNews = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading } = useSelector((state) => state.blog);
    const posts = useSelector((state) => state.blog.posts?.postsOnHomePage?.postsByTheme);
    const newsSectionRef = useRef();
    const selector = 'news';
    const { show, setWatch } = useObserver(selector);

    useEffect(() => {
        if (newsSectionRef.current && !show) {
            setWatch(true);
        }
        if (!posts && show) {
            dispatch(fetchPostsByTheme({ themeId: '', getMorePosts: false }));
        }
    }, [show, newsSectionRef.current]);

    function showMorePosts() {
        navigate('/news');
    }

    // if (isLoading && !posts) {
    //     debugger
    //     return (
    //         <div className="centerSpinner">
    //             <Spinner />
    //         </div>
    //     );
    // }

    return (
        <Container>
            <ArticlesNewsStyled className={selector} ref={newsSectionRef}>
                <h2 className="articlesNews__title">Что у нас нового</h2>
                {isLoading && !posts ? (
                    <div className="centerSpinner">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <div className="articlesNews__grid">
                            {posts?.map((article) => (
                                <ArticlesNewsCard
                                    key={article.id}
                                    postId={article.id}
                                    img={article.cover_img}
                                    date={article.created_at}
                                    title={article.title}
                                    theme={article.theme}
                                />
                            ))}
                        </div>
                        <p className="blogLink" onClick={showMorePosts}>
                            Смотреть больше статей в нашем блоге
                        </p>
                    </>
                )}
            </ArticlesNewsStyled>
        </Container>
    );
};

export default ArticlesNews;
