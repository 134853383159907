import styled from 'styled-components';

export const LoadFileStl = styled.div`
    input {
        display: none;
    }
    .btn {
        display: flex;
        width: 24px;
        height: 24px;
        color: #19be6f;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        top: -52px;
        margin: 0 15px -40px auto;
    }
    .files {
        width: calc(100% - 10px);
        display: flex;
        gap: 20px;
        margin-top: 16px;
        @media (max-width: 500px) {
            flex-direction: column;
        }
        .file {
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;
            cursor: pointer;
            max-width: 31%;
            @media (max-width: 500px) {
                max-width: 100%;
            }
            > a {
                gap: 5px;
                color: #212121;
                font-size: 16px;
                text-decoration: none;
                display: flex;
                align-items: center;
                overflow:hidden; 
                white-space:nowrap;
                text-overflow:ellipsis;
            }
            .file_name {
                width: 100%;
                overflow:hidden; 
                white-space:nowrap;
                text-overflow:ellipsis;
            }
            > span {
                color: red;
                cursor: pointer;
            }
        }
    }
`;
