import React from 'react';
import { StyledDoctorCard } from './doctorCard.styled';
import { DoctorCardProfile } from './doctorCardProfile/doctorCardProfile';
import { DoctorSchedule } from './doctorSchedule/doctorSchedule';
import { useNavigate } from 'react-router-dom';

export const DoctorCard = ({ doctor }) => {
  const navigate = useNavigate()

  return (
    <StyledDoctorCard  onClick={() => navigate(`${doctor.id}/${doctor.slug}`)}>
      <DoctorCardProfile doctor={doctor} />
      <DoctorSchedule doctor={doctor} />
    </StyledDoctorCard>
  )
}