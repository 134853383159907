import React from 'react';
import emptyStar from '../../../img/star-icon-empty.svg';
import fullStar from '../../../img/star-icon-full.svg';
import { FeedbackValueStl } from './feedbackValue.styled';

export const FeedbackValue = ({ value, setValue }) => {
    return (
        <FeedbackValueStl>
            <h3>Оцените</h3>
            <div className="star_container">
                {[...Array(5)].map((_, index) => (
                    <button
                        onClick={() => setValue(index + 1)}
                        key={index}
                        style={
                            index < value
                                ? { backgroundImage: `url(${fullStar})` }
                                : { backgroundImage: `url(${emptyStar})` }
                        }
                        type='button'
                    />
                ))}
            </div>
        </FeedbackValueStl>
    );
};
