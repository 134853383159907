import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NewFormSTL } from './components/newFormSTL.styled';
import PatientAllergyEdit from './components/PatientAllergyEdit';
import PatientData from './components/PatientDataEdit';
import PatientDiseasesEdit from './components/PatientDIseasesEdit';
import PatientMedicationEdit from './components/PatientMedicationEdit';
import PatientOperationsEdit from './components/PatientOperationsEdit';
import PatientParametrsEdit from './components/PatientParametrsEdit';
import { fetchPatientDataUpdate } from '../../api/PatientProfileSlice';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const FormPatient = ({ methods }) => {
    const patientProfile = useSelector((state) => state.patientProfile?.patientProfile);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(false);

    // проверяем первый раз входит пользователь на сайт или нет, и если да, то редиректим на view
    const { last_name } = patientProfile?.user;
    useEffect(() => {
        if (last_name) {
            navigate('/lk/patient-profile/profile/view');
        }
    }, [last_name]);

    const onSubmit = (data) => {
        setIsLoader(true);
        const promises = [];

        let birthdayInSeconds = 0;
        const birthdayDate = new Date(
            Date.UTC(data.user.birthday.slice(6), data.user.birthday.slice(3, 5) - 1, data.user.birthday.slice(0, 2))
        );
        birthdayInSeconds = Math.floor(birthdayDate.getTime() / 1000);

        promises.push(
            dispatch(
                fetchPatientDataUpdate({
                    user: {
                        ...data.user,
                        birthday: birthdayInSeconds,
                        city: data.user.city.value,
                    },
                    height: Number(data.patientProfile?.height),
                    weight: Number(data.patientProfile?.weight),
                    blood_type:
                        typeof data.patientProfile.blood_type === 'string'
                            ? 'не указано'
                            : data.patientProfile.blood_type.value,
                })
            )
        );

        Promise.all(promises)
            .then(() => {
                navigate('/lk/patient-profile/profile/view');
                setIsLoader(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoader(false);
            });
    };

    const {
        formState: { isValid },
    } = useFormContext();

    return (
        <NewFormSTL>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PatientData />
                <button disabled={!isValid || isLoader} className="btn__recive">
                    {isLoader ? 'Сохранение...' : 'Отправить данные'}
                </button>
            </form>
        </NewFormSTL>
    );
};

export default FormPatient;
