import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCertificate } from './api';

export const fetchCertificates = createAsyncThunk('doctorCertificates/fetchCertificates', async (id) => {
    try {
        const response = getCertificate(id);
        return response;
    } catch (e) {
        console.log(e);
    }
});

const doctorCertificates = createSlice({
    name: 'doctorCertificates',
    initialState: {
        errors: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCertificates.pending, (state) => {
            state.doctorCertificatesLoading = 'start';
        });
        builder.addCase(fetchCertificates.rejected, (state) => {
            state.doctorCertificatesLoading = 'error';
        });
        builder.addCase(fetchCertificates.fulfilled, (state, action) => {
            state.doctorCertificatesLoading = 'done';
            state.certificates = action.payload.data;
        });
    },
});

export default doctorCertificates.reducer;
