import styled from 'styled-components';
import banner from '../../../img/doctors-shaking-hands-close-up.png';

export const ReviewsBannerStyled = styled.div`
    background-image: url(${banner});
    padding-top: calc( 50% - 2px );
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    @media screen and (min-width: 768px) {
        padding-top: calc( (100% + 37px) / 3 );
    }
    @media screen and (min-width: 1440px) {
        width: 1040px;
        padding-top: 365px;
    }
`;
