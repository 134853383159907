import React, { useState } from 'react';
import FlipSwitch from './flipSwitch';
import { Modal } from '../../share/Modal';
import { PatientSettingsDiv } from './lkPatientSettings.styled';
import PopUpConfirm from '../../share/PopUpConfirm';
import PopUpAlert from '../../share/PopUpAlert';
import { logoutPatient } from '../api/PatientProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchDeleteUser, fetchMeUser, fetchRegister, setId } from '../../authentification/api/authentificationSlice';


const LkPatientSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.authentification.user);
    const [popUpExitProfile, setPopUpExitProfile] = useState(false);
    const [popUpDeleteProfile, setPopUpDeleteProfile] = useState(false);
    const [popUpSuccessfulDeleteProfile, setPopUpSuccessfulDeleteProfile] = useState(false);

    const handleLogOut = () => {
        // dispatch(logoutPatient());
        // navigate('/');
    };

    const deleteProfile = () => {
        setPopUpDeleteProfile(false);
        setPopUpSuccessfulDeleteProfile(true);
        dispatch(fetchDeleteUser(user.id));
        console.log('deleted from component');
    };

    const successDeleteProfile = () => {
        navigate('/')
        console.log('Аккаунт успешно удален');
    };

    return (
        <PatientSettingsDiv>
            <p className="settings__item">
                Вы можете{' '}
                <label onClick={() => setPopUpDeleteProfile(true)} className="settings__item-label">
                    удалить
                </label>{' '}
                свой личный кабинет.
            </p>

            {popUpDeleteProfile && (
                <Modal toggle={setPopUpDeleteProfile}>
                    <PopUpConfirm
                        onConfirm={deleteProfile}
                        onClose={() => setPopUpDeleteProfile(false)}
                        contentHeader={
                            <span>
                                Вы уверены, что хотите удалить личный кабинет? <br />
                                <span style={{ color: '#666666', fontSize: '18px' }}>Все данные будут удалены</span>
                            </span>
                        }
                        isClarification={false}
                    />
                </Modal>
            )}

            {popUpSuccessfulDeleteProfile && (
                <Modal toggle={setPopUpDeleteProfile}>
                    <PopUpAlert
                        onConfirm={successDeleteProfile}
                        onClose={() => setPopUpSuccessfulDeleteProfile(false)}
                        contentHeader={
                            <span>
                                Ваш аккаунт успешно удален
                            </span>
                        }
                        isClarification={true}
                    />
                </Modal>
            )}
        </PatientSettingsDiv>
    );
};

export default LkPatientSettings;
