import React from 'react'
import { TotalStl } from './transaction.styled'

export const Total = ({ total }) => {
    return (
        <TotalStl>
            <span>ИТОГО</span>
            <span>{total?.toLocaleString()} ₽</span>
        </TotalStl>
    )
}
