/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import Flex from './Flex';

const RoundPhotoStyled = styled.div`
    width: ${(props) => props.size || '100%'};
    height: ${(props) => props.size || '100%'};
    clip-path: circle(50%);
    margin: ${(props) => props.margin || '0'};
    padding: ${(props) => props.padding || '0'};
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    img {
		border: ${(props) => props.border || 'none'};
		border-radius: 50%;
		height: fit-content;
        object-fit: cover;
		min-width: ${props => props.size || 'unset'};
		min-height: ${props => props.size || 'unset'};
		max-width: ${props => props.maxSize || 'unset'};
		max-height: ${props => props.maxSize || 'unset'};
		width: ${(props) => props.size || '100%'};
    }
`;
const RoundPhoto = (props) => {
    return (
        <RoundPhotoStyled {...props}>
            <Flex align="center" justify="center">
                <img src={props.addr} {...props} alt="" />
            </Flex>
        </RoundPhotoStyled>
    );
};

export default RoundPhoto;
