import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormPatient from './FormPatient';
import { useSelector } from 'react-redux';
import { Loader } from '../../../loader/loader';

const EditPatient = () => {
    const { user } = useSelector((state) => state.patientProfile?.patientProfile);
    const isLoaderProfile = useSelector((state) => state.patientProfile.isLoader);

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            user: {
                first_name: '',
                last_name: '',
                patronymic: '',
                birthday: '',
                gender: 0,
                phone: user.phone,
                email: user.email,
                city: '',
            },
            patientProfile: {
                height: '',
                weight: '',
                blood_type: '',
            },
        },
    });

    return (
        <>
            {isLoaderProfile ? (
                <Loader />
            ) : (
                <FormProvider {...methods}>
                    <FormPatient methods={methods} />
                </FormProvider>
            )}
        </>
    );
};

export default EditPatient;
