import { LkPatientProfileStl, StyledUserLogo } from './lkPatientProfile.styled';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { fetchDeleteMyAvatar, fetchLoadMyAvatar, fetchPatientProfile } from '../api/PatientProfileSlice';

import ImageHover from '../../../img/photo_hover.png';
import { Loader } from '../../loader/loader';
import PhotoAlt from '../../../img/photo_alt.png';
import RoundPhoto from '../../share/RoundPhoto';
import { uploadFile } from '../../share/fileUploader';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export const LkPatientProfile = () => {
    const [errorLoadAvatar, setErrorLoadAvatar] = useState('');
    const [localAvatarUrl, setLocalAvatarUrl] = useState(null);

    const dispatch = useDispatch();
    const path = useLocation();
    const redirect = useNavigate();

    const { patientProfile } = useSelector((state) => state.patientProfile);
    const refAvatar = useRef();
    useEffect(() => {
        // !patientProfile
        //     ? dispatch(fetchPatientProfile())
        //     : patientProfile.user.first_name === '' && dispatch(fetchPatientProfile());
        if (!patientProfile) dispatch(fetchPatientProfile());
    }, []);

    useEffect(() => {
        if (errorLoadAvatar) {
            const delayAvatarId = setTimeout(() => setErrorLoadAvatar(''), 5000);
            return () => clearTimeout(delayAvatarId);
        }
    }, [errorLoadAvatar]);

    const addPhoto = () => {
        const sendUpResult = async (file) => {
            if (file.size > 2097152) {
                setErrorLoadAvatar('Размер файла должен быть не более 2 МБ');
            } else {
                try {
                    await dispatch(fetchLoadMyAvatar(file)).unwrap();
                    setLocalAvatarUrl(URL.createObjectURL(file));
                } catch (error) {
                    setErrorLoadAvatar('Ошибка при загрузке изображения');
                }
            }
        };

        uploadFile(
            refAvatar,
            {
                multiple: false,
                accept: ['.jpg', '.png', '.jpeg', '.tiff'],
            },
            sendUpResult
        );
    };

    const handleDeleteAvatar = async () => {
        try {
            await dispatch(fetchDeleteMyAvatar()).unwrap();
            setLocalAvatarUrl(null);
        } catch (error) {
            setErrorLoadAvatar('Ошибка при удалении изображения');
        }
    };

    useEffect(() => {
        if (path.pathname === '/lk/patient-profile/profile') {
            redirect('/lk/patient-profile/profile/view', { replace: true });
        }
    }, [path]);

    if (!patientProfile) {
        return <Loader />;
    }

    const avatarUrl = localAvatarUrl || patientProfile?.user?.avatar || PhotoAlt;

    return (
        <LkPatientProfileStl>
            <div className="avatar">
                <StyledUserLogo onClick={addPhoto}>
                    <img src={ImageHover} />
                    <RoundPhoto size="156px" addr={avatarUrl} />
                    <input type="file" name="" id="loadPhoto" ref={refAvatar} />
                </StyledUserLogo>
                {!errorLoadAvatar && (patientProfile.user?.avatar || localAvatarUrl) ? (
                    <button className="deleteAvatarButton" onClick={handleDeleteAvatar}>
                        Удалить фото
                    </button>
                ) : null}
                {errorLoadAvatar && <span className="errorLoadAvatar">{errorLoadAvatar}</span>}
            </div>
            <div className="data">
                <Outlet />
            </div>
        </LkPatientProfileStl>
    );
};
