import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import Flex from './Flex';
import close_icon from '../../img/close-icon.svg';

const PopUpConfirmSettingStyled = styled.section`
    display: flex;
    flex-direction: column;
	box-sizing: border-box;
	padding: 34px;
    width: 510px;
    background: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .close {
		display: inline-flex;
        cursor: pointer;
    }
	 .headerExiteProfile {
		margin: 0 19px;
		font-size: 24px;
		line-height: 130%;
		text-align: center;
		color: #06A7B3;
		width: 100%;
	}
	.textClarification {
		margin: 0 0 24px;
		font-size: 18px;
		line-height: 130%;
		text-align: center;
		color: #A2A3A8;
	}
	.buttonsExiteProfile {
		margin: 0 35px 25px;
		justify-content: space-between;
	}
	@media (max-width: 600px) {
		width: 280px;
		padding: 17px;
		.headerExiteProfile {
			margin: 0 10px;
			font-size:18px;
		}
		.buttonsExiteProfile {
			flex-direction: column;
			gap: 8px;
		}
	}
`;

const PopUpConfirm = ({
	onClose,
	onConfirm,
	contentHeader,
	isClarification = false,
	contentClarification = '' }) => {
	return (
		<PopUpConfirmSettingStyled>
			<Flex justify="flex-end" margin='0 0 16px'>
				<img className="close" src={close_icon} alt="" onClick={onClose} />
			</Flex>
			<Flex margin={isClarification ? '0 0 12px' : '0 0 24px'}>
				<h3 className='headerExiteProfile'>
					{contentHeader}
				</h3>
			</Flex>
			{
				isClarification && (
					<p className='textClarification'>
						{contentClarification}
					</p>
				)
			}
			<Flex className='buttonsExiteProfile'>
				<Button
					margin='0'
					width='174px'
					height='50px'
					font_size='18px'
					display='inline-flex'
					align='center'
					justify='center'
					style={{ color: '#19BE6F' }}
					onClick={onConfirm}>
					Да
				</Button>
				<Button
					margin='0'
					width='174px'
					height='50px'
					font_size='18px'
					display='inline-flex'
					align='center'
					justify='center'
					style={{ color: '#19BE6F' }}
					onClick={onClose}>
					Нет
				</Button>
			</Flex>
		</PopUpConfirmSettingStyled>
	);
};

export default PopUpConfirm;