import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledDocrotConsultationCard } from './doctorConsultationCard.styled';
import { consultationStatus } from '../lkDoctorConsultationHelpers';
import { ConsultationButtons } from '../../consultationButtons/consultationButtons';
import { ConsultationTimeAndPrice } from '../../../../share/consultationTime/consultationTime';

export const DocrotConsultationCard = ({ consultation }) => {
    const navigate = useNavigate();
    const { status, date, id, registration_log, price } = consultation;
    const { last_name, first_name, patronymic, avatar, uid } = consultation?.patient?.user;
    let fullName = `${last_name} ${first_name} ${patronymic}`;

    const isDoctorCancelled = uid !== registration_log?.cancelled_by?.uid;

    const showMoreConsultation = () => {
        navigate(`${id}`);
    };

    return (
        <StyledDocrotConsultationCard onClick={showMoreConsultation}>
            <div className="photoBlock">
                <img src={avatar} alt="photo" />
            </div>
            {consultationStatus(status, isDoctorCancelled)}
            <div className="patientName">{fullName}</div>
            <ConsultationTimeAndPrice card={true} date={date} price={price}/>
            <ConsultationButtons consultation={consultation}/>
        </StyledDocrotConsultationCard>
    );
};