import styled from 'styled-components';

export const ArticlesNewsStyled = styled.section`
    margin: 120px 0 80px;
    .articlesNews__title {
        color: var(--primary-text, #212121);
        font-size: 42px;
        font-weight: 500;
        line-height: 130%;
        margin-bottom: 60px;
    }

    .blogLink {
        cursor: pointer;
        color: var(--primary-accent, #19be6f);
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        &:hover {
            text-decoration: underline;
        }
    }

    .articlesNews__grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(229px, 1fr));
        gap: 20px;
        margin-bottom: 35px;
    }
    @media (max-width: 768px) {
        .articlesNews__title {
            font-size: 36px;
            line-height: 120%;
        }
        .articlesNews__grid {
            grid-template-columns: repeat(auto-fill, minmax(229px, 1fr));
        }
    }
    @media (max-width: 500px) {
        .articlesNews__title {
            font-size: 28px;
            line-height: 110%;
            margin-bottom: 40px;
        }
        .articlesNews__grid {
            margin-bottom: 30px;
        }
    }
`;
