import React, { useEffect, useState } from 'react';
import { fetchRemoveFavoriteDoctorUid, fetchSetFavoriteDoctor } from '../../../../lkPatient/api/favoriteDoctorsSlice';

import { ConsultationButtons } from '../../consultationButtons/consultationButtons';
import { ConsultationTimeAndPrice } from '../../../../share/consultationTime/consultationTime';
import { ReactComponent as LikeEmpty } from '../../../../../img/icons/doctorProfilIcons/like_profile_empty.svg';
import { ReactComponent as LikeFilled } from '../../../../../img/icons/doctorProfilIcons/like_profile.svg';
import { Spinner } from '../../../../share/Spinner';
import { StyledPatientConsultationCard } from './patientConsultationCard.styled';
import { consultationStatus } from '../lkPatientConsultationHelpers';
import { fetchGetDoctor } from '../../../../ourDoctors/api/doctorsSlise';
import { setDoctor } from '../../../api/PatientConsultationsSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PatientConsultationCard = ({ consultation }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    // когда будет понятно откуда брать переменную бсделать и брать ее из стора
    const [isDoctorConfirmation, setIsDoctorConfirmation] = useState(false);

    const newDoctor = useSelector((state) => state.doctors.doctor);
    const { status, date, doctor, id, price, registration_log, request } = consultation;
    const { last_name, first_name, patronymic, avatar, specialization, id: doctorId } = doctor;
    let fullName = `${last_name} ${first_name} ${patronymic ? patronymic : ''}`;

    const isPatientCancelled = doctorId !== registration_log?.cancelled_by?.uid;

    const showMoreConsultation = () => {
        navigate(`${id}`);
    };

    const removeConsultation = () => {
        // нет в свагере
    };

    const spec = specialization?.map((s) => {
        return <span key={s.id}>{s.name}</span>;
    });

    useEffect(() => {
        if (newDoctor) {
            dispatch(setDoctor(newDoctor));
        }
    }, [newDoctor]);

    return (
        <StyledPatientConsultationCard onClick={showMoreConsultation}>
            <div className="photoBlock">
                <img src={avatar} alt="photo" />
            </div>
            {/* {consultationStatus(status, isPatientCancelled)} */}
            <div className={`confirmation ${!isDoctorConfirmation && 'noConfirmation'}`}>
                {/* {!isDoctorConfirmation
                    ? 'Врач еще не подтвердил консультацию. Дождитесь подтверждения.'
                    : 'Онлайн-консультация'} */}
                {consultationStatus(status, isPatientCancelled)}
            </div>
            <div className="patientName">{fullName}</div>
            <div className="specialization">{spec}</div>
            <ConsultationTimeAndPrice priceDisplay={true} card={true} date={date} price={price} />
            <p className={`comment ${request === '' && 'noComment'}`}>
                {request !== '' ? request : 'Комментарии не оставлены'}
            </p>
            <ConsultationButtons consultation={consultation} isDoctorConfirmation={isDoctorConfirmation} />
        </StyledPatientConsultationCard>
    );
};
