import React from 'react';
import styled from 'styled-components';
import Container from '../componets/share/Container';
import {TitleHeader} from '../componets/share/TitleHeader';
import {TitleSection} from '../componets/share/TitleSection';
import {MainHeader} from '../componets/mainHeader/mainHeader';
import { useEffect } from 'react';

const PoliticsStyled = styled.div`
  h2 {
    margin: 63px 0 auto;
    font-weight: 500;
    font-size: 30px;
    line-height: 120%;
    color: #06A7B3;
    text-align: center;
  }

  p {
    text-align: justify;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin: 3px;
  }

  ol {
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    counter-reset: item;
    // padding-left: 10px;
    .li_body {
      font-weight: 400;
    }
  }

  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

  }

  li:before {
    content: counters(item, ".") ".";
    counter-increment: item;

  }

  .li_name {
    font-weight: 600;
    margin: 25px 0;

    P {
      font-weight: 600;
    }
  }

  .li_body {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;

    p {
      font-weight: 400;
    }
  }
`
const Politics = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  return (
      <PoliticsStyled>
        <Container>
          <MainHeader title={'Политика конфиденциальности и соглашение на обработку персональных данных'}/>
          <ol>
            <li className="li_name"><p>Общие положения</p>
              <ol>
                <li className="li_body"><p>Настоящая Политика конфиденциальности и соглашение на обработку персональных
                  данных (далее – Пользовательское соглашение) относится к Информационному порталу «Doct24», который
                  расположен по адресу https://doct24.ru и представлен под товарным знаком «Doct24» (свидетельство о
                  регистрации № ... от ... г.).</p></li>
                <li className="li_body"><p>Сайт Doct24.ru – является собственностью ООО «Стоматология Онлайн».</p></li>
                <li className="li_body"><p>Цель сайта – предоставление Пользователям Сайта актуальной информации о
                  современных методах лечения по стоматологии и косметологии.</p></li>
                <li className="li_body"><p>Настоящее Пользовательское соглашение регулирует отношения между
                  Администрацией сайта и Пользователем сайта.</p></li>
                <li className="li_body"><p>Администрация сайта оставляет за собой право в любое время: изменять,
                  добавлять или удалять пункты настоящего Пользовательского соглашения без уведомления Пользователя.</p>
                </li>
                <li className="li_body"><p>Продолжение использования Сайта Пользователем означает – принятие
                  Пользовательского соглашения и изменений, внесенных в настоящее Пользовательское соглашение.</p></li>
                <li className="li_body"><p>Пользователь несет персональную ответственность за проверку настоящего
                  Пользовательского соглашения на наличие изменений в нем.</p></li>
              </ol>
            </li>
            <li className="li_name"><p>Определение терминов</p>
              <ol>
                <li className="li_body"><p>Сайт – совокупность информационно-графических материалов, а также программ
                  для ЭВМ, баз данных и других программных средств, обеспечивающих их доступность в сети интернет по
                  адресу https://doct24.ru и представляющий собой объект интеллектуальной собственности.</p></li>
                <li className="li_body"><p>Пользователь сайта – любой посетитель сайта doct24.ru.</p></li>
                <li className="li_body"><p>Администрация сайта – сотрудники по управлению сайтом, действующие от имени
                  ООО «Стоматология Онлайн».</p></li>
                <li className="li_body"><p>Контент сайта – охраняемые результаты интеллектуальной деятельности, а именно
                  тексты литературных произведений, названия произведений, предисловия, аннотации, статьи, иллюстрации,
                  обложки, графические, текстовые, видео, фотографические, производные, составные и иные произведения,
                  пользовательские интерфейсы, визуальные интерфейсы, название товарного знака, логотип, программы для
                  ЭВМ, базы данных. А также дизайн, структура, выбор, общий стиль и расположение данного Контента,
                  входящего в состав Сайта и другие объекты интеллектуальной собственности все вместе и/или по
                  отдельности, содержащиеся на сайте.</p></li>

              </ol>
            </li>
            <li className="li_name"><p>Персональные данные Пользователей</p>
              <ol>
                <li className="li_body"><p>Персональные данные Пользователей включают в себя:</p>
                </li>
                <p className="li_body">- Данные о технических устройствах (в том числе IP адрес Пользователя, его
                  Операционная система и тип браузера, интернет-провайдер);</p>
                <p className="li_body">- Информация, автоматически получаемая при доступе к Сайту с использованием
                  cookies;</p>
                <p className="li_body">- Информация, полученная в результате действий Пользователя на Сайте.</p>

              </ol>
            </li>
            <li className="li_name"><p>Цели сбора и обработки персональных данных, а также перечень действий с
              персональными данными, на совершение которых дается согласие</p>
              <ol>
                <li className="li_body"><p>Сайт Doct24.ru в лице ООО «Стоматология Онлайн» собирает и хранит только те
                  персональные данные, которые необходимы для:</p></li>
                <p>— Предоставления онлайн-сервисов;</p>
                <p>— Улучшения работы онлайн-сервисов;</p>
                <p>— Внедрения новых онлайн-сервисов;</p>
                <p>— Улучшения качества работы Сайта, а также удобства пользования им.</p>
              </ol>
            </li>
            <li className="li_name"><p>Предмет Пользовательского соглашения</p>
              <ol>
                <li className="li_body"><p>Использование материалов и онлайн-сервисов Сайта регулируется нормами
                  действующего законодательства Российской Федерации.</p></li>
                <li className="li_body"><p>Использование Сайта, в том числе его просмотр, поиск информации,
                  использование онлайн-сервисов возможно только при условии полного и безоговорочного принятия условий
                  настоящего Пользовательского соглашения.</p></li>
                <li className="li_body"><p>При несогласии с условиями настоящего Пользовательского соглашения
                  Пользователь обязан немедленно прекратить пользование Сайтом.</p></li>
              </ol>
            </li>
            <li className="li_name"><p>Конфиденциальность информации</p>
              <ol>
                <li className="li_body"><p>Пользователь дает свое согласие на обработку своих персональных данных,
                  предоставляемых при использовании Сайта, в том числе сбор, запись, систематизацию, накопление,
                  хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование,
                  удаление и уничтожение.</p></li>
                <li className="li_body"><p>Пользователь соглашается с тем, что Администрация, в целях реализации своих
                  обязательств, производит обработку персональных данных без средств автоматизации.</p></li>
                <li className="li_body"><p>Пользователь осознаёт и принимает, что персональные данные передаются в
                  электронной форме по открытым каналам связи сети Интернет.</p></li>
                <li className="li_body"><p>Предоставленное Пользователем согласие на обработку его персональных данных
                  является бессрочным и может быть отозвано Пользователем или его законным представителем подачей
                  заявления. В целях скорейшего рассмотрения — заявление необходимо отправить на e-mail Администрации
                  сайта: info@doct24.ru
                  Адрес Администрации: ул. Варшавская 1, Москва.</p></li>
                <li className="li_body"><p>В соответствии с п.3 ст. 14 Федерального закона «О персональных данных»
                  информация, касающаяся обработки персональных данных субъекта персональных данных может быть
                  предоставлена субъекту персональных данных или его законному представителю Администрацией при
                  обращении, либо при получении письменного запроса субъекта персональных данных или его законного
                  представителя, соответствующего всем законным требованиям к содержанию такого обращения.</p></li>
                <li className="li_body"><p>Пользователь не обязан предоставлять Администрации при использовании Сайта
                  информацию о состоянии своего здоровья.</p></li>
                <li className="li_body"><p>Администрация обязуется принимать меры по охране конфиденциальности
                  информации, полученной от Пользователей.</p></li>
              </ol>
            </li>
            <li className="li_name"><p>Использование сайта</p>
              <ol>
                <li className="li_body"><p>Сайт и Контент, входящий в состав Сайта, принадлежит и управляется
                  Администрацией сайта.</p></li>
                <li className="li_body"><p>Информация о медицинских услугах, болезнях, методах диагностики и способах
                  лечения носит справочный характер. Администрация не гарантирует ее полнейшую актуальность.
                  Администрация настоятельно рекомендует обращаться за квалифицированной медицинской помощью
                  непосредственно к врачам требуемой специализации.</p></li>
                <li className="li_body"><p>Контент сайта защищен авторским правом, законодательством о товарных знаках,
                  а также другими правами, связанными с интеллектуальной собственностью, и законодательством о
                  недобросовестной конкуренции.</p></li>
                <li className="li_body"><p>Контент сайта не может быть скопирован, опубликован, воспроизведен, передан
                  или распространен любым способом, а также размещен в глобальной сети «Интернет» без согласия
                  Администрации сайта.</p></li>
                <li className="li_body"><p>При цитировании материалов Сайта, включая охраняемые авторские произведения,
                  ссылка на Сайт https://doct24.ru обязательна.</p></li>
                <li className="li_body"><p>Информация, размещаемая на Сайте не должна истолковываться как изменение
                  настоящего Пользовательского соглашения.</p></li>
              </ol>
            </li>
            <li className="li_name"><p>Права и обязанности сторон</p>
              <ol>
                <li className="li_body"><p>Администрация сайта вправе:</p></li>
                <p>— Изменять правила пользования Сайтом, а также изменять содержание данного Сайта;</p>
                <p>— Изменения вступают в силу с момента публикации новой редакции Пользовательского соглашения на
                  Сайте;</p>
                <p>— Ограничить доступ к Сайту в случае нарушения Пользователем условий настоящего Пользовательского
                  соглашения;</p>
                <li className="li_body"><p>Пользователь вправе:</p></li>
                <p>— Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Пользовательским соглашением и
                  не запрещенных законодательством Российской Федерации.</p>
                <li className="li_body"><p>Пользователь Сайта имеет возможность удалять свои сообщения.</p></li>
                <li className="li_body"><p>Пользователь Сайта обязуется:</p></li>
                <p>— Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании
                  Сайта;</p>
                <p>— Не распространять с использованием Сайта любую конфиденциальную и охраняемую законодательством
                  Российской Федерации информацию о физических либо юридических лицах;</p>
                <p>— Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу
                  Сайта;</p>
                <p>— Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой
                  законодательством Российской Федерации информации, а также действий, которые приводят либо могут
                  привести к нарушению отдельных положений законодательства Российской Федерации.</p>
                <li className="li_body"><p>Не использовать онлайн-сервисы сайта с целью:</p></li>
                <p>— Загрузки контента, который является незаконным, нарушает любые права третьих лиц;</p>
                <p>— Пропаганды насилия, жестокости, ненависти и (или) дискриминации по расовому, национальному,
                  половому, религиозному, социальному признакам;</p>
                <p>— Представления себя за другого человека или представителя организации без достаточных на то
                  прав;</p>
                <p>— Оскорбления в адрес конкретных лиц, организаций, органов власти;</p>
                <p>— Нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме;</p>
                <p>— Побуждения к совершению противоправных действий, а также содействия лицам, действия которых
                  направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации;</p>
                <p>— Ущемления прав меньшинств.</p>
                <li className="li_body"><p>Пользователю запрещается:</p></li>
                <p>— Все действия, перечисленные в пункте 8.4.;</p>
                <p>— Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства
                  или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания
                  Сайта;</p>
                <p>— Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой
                  информации, документов или материалов любыми средствами, которые специально не представлены
                  онлайн-сервисами данного Сайта;</p>
                <p>— Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к
                  Сайту;</p>
                <p>— Нарушать функционирование Сайта, созданное Администрацией сайта;</p>
                <p>— Несанкционированный доступ к функциям Сайта, любым другим системам или сетям, относящимся к данному
                  Сайту, а также к любым услугам, предлагаемым на Сайте;</p>
                <p>— Отслеживать любую информацию о любом другом Пользователе Сайта;</p>
                <p>— Использовать Сайт и его Контент в любых целях, запрещенных законодательством Российской Федерации,
                  а также подстрекать к любой незаконной деятельности, нарушающих права Администрации Сайта или других
                  лиц.</p>

              </ol>
            </li>
            <li className="li_name"><p>Ответственность</p>
              <ol>
                <li className="li_body"><p>Любые убытки, которые Пользователь может понести в случае умышленного или
                  неосторожного нарушения любого положения настоящего Пользовательского соглашения, а также вследствие
                  несанкционированного доступа к коммуникациям другого Пользователя, Администрацией сайта не
                  возмещаются.</p></li>
                <li className="li_body"><p>Пользователь соглашается, что Контент сайта и онлайн-сервисы могут
                  сопровождаться рекламой. Пользователь согласен с тем, что Администрация Сайта не несет какой-либо
                  ответственности и не имеет каких-либо обязательств вследствие такой рекламы.</p></li>
                <li className="li_body"><p>Отказ от ответственности за фотографии до и после, которые отображаются на
                  Сайте. Мы гарантируем, что все фотографии пациентов (до и после процедур) соответствуют следующим
                  критериям:
                  1) пациент на фотографиях до и после является одним и тем же лицом;
                  2) фотографии не были изменены в цифровом виде и никоим образом не улучшены;
                  3) вы должны знать, что одна и та же процедура может иметь разные результаты у разных пациентов, и
                  поэтому мы не можем гарантировать, что вы получите тот же результат, как на фотографиях до и после на
                  нашем Сайте;
                  4) все снимки были деланы с одобрения пациентов, и опубликованы на нашем Сайте.</p></li>
                <li className="li_body"><p>Пользователь предупрежден о том, что Администрация Сайта не несет
                  ответственности за посещение и использование им внешних ресурсов, ссылки на которые могут содержаться
                  на сайте.</p></li>
                <li className="li_body"><p>Администрация сайта не несет ответственности за задержки или сбои в процессе
                  совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в
                  телекоммуникационных, компьютерных, электрических и иных смежных системах.</p></li>
                <li className="li_body"><p>Администрация Сайта не несет ответственность за надлежащее функционирование
                  Сайта, в случае, если Пользователь не имеет необходимых технических средств для его использования, а
                  также не несет никаких обязательств по обеспечению пользователей такими средствами.</p></li>
              </ol>
            </li>
            <li className="li_name"><p>Нарушение условий Пользовательского соглашения</p>
              <ol>
                <li className="li_body"><p>Администрация сайта вправе раскрыть любую собранную о Пользователе данного
                  Сайта информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении
                  неправомерного использования Сайта либо для установления (идентификации) Пользователя, который может
                  нарушать или вмешиваться в права Администрации сайта или в права других Пользователей Сайта.</p></li>
                <li className="li_body"><p>Администрация сайта имеет право раскрыть любую информацию о Пользователе,
                  которую посчитает необходимой для выполнения положений действующего законодательства или судебных
                  решений, обеспечения выполнения условий настоящего Пользовательского соглашения, защиты прав или
                  безопасности ООО «Стоматология Онлайн», Пользователей.</p></li>
                <li className="li_body"><p>Администрация сайта имеет право раскрыть информацию о Пользователе, если
                  действующее законодательство Российской Федерации требует или разрешает такое раскрытие.</p></li>
                <li className="li_body"><p>Администрация сайта вправе без предварительного уведомления Пользователя
                  прекратить и (или) заблокировать доступ к Сайту, если Пользователь нарушил настоящее Пользовательское
                  соглашение или содержащиеся в иных документах условия пользования Сайтом, а также в случае прекращения
                  действия Сайта либо по причине технической неполадки или проблемы.</p></li>
                <li className="li_body"><p>Администрация сайта не несет ответственности перед Пользователем или третьими
                  лицами за прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего
                  Пользовательского соглашения или иного документа, содержащего условия пользования Сайтом.</p></li>
              </ol>
            </li>
            <li className="li_name"><p>Разрешение споров</p>
              <ol>
                <li className="li_body"><p>В случае возникновения любых разногласий или споров между Сторонами
                  настоящего Пользовательского соглашения обязательным условием до обращения в суд является предъявление
                  претензии (письменного предложения о добровольном урегулировании спора).</p></li>
                <li className="li_body"><p>Получатель претензии в течение 30 календарных дней со дня ее получения,
                  письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p></li>
                <li className="li_body"><p>При невозможности разрешить спор в добровольном порядке любая из Сторон
                  вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством
                  Российской Федерации.</p></li>
                <li className="li_body"><p>Любой иск в отношении условий использования Сайта должен быть предъявлен в
                  течение 5 дней после возникновения оснований для иска, за исключением защиты авторских прав на
                  охраняемые в соответствии с законодательством материалы Сайта. При нарушении условий данного пункта
                  любой иск или основания для иска погашаются исковой давностью.</p></li>

              </ol>
            </li>
            <li className="li_name"><p>Дополнительные условия</p>
              <ol>
                <li className="li_body"><p>Администрация сайта не принимает встречные предложения от Пользователя
                  относительно изменений настоящего Пользовательского соглашения.</p></li>
                <li className="li_body"><p>Все предложения или вопросы по поводу настоящего Пользовательского соглашения
                  следует сообщать в техническую службу поддержки Пользователей на e-mail: info@doct24.ru</p></li>
                {/* <li className='li_body'><p></p></li>
                            <li className='li_body'><p></p></li>
                            <li className='li_body'><p></p></li>
                            <li className='li_body'><p></p></li>
                            <li className='li_body'><p></p></li>
                            <li className='li_body'><p></p></li> */}
              </ol>
            </li>
            {/* <li className='li_name'><p></p></li>
                            <li className='li_name'><p></p></li> */}
          </ol>
        </Container>
      </PoliticsStyled>
  );
}

export default Politics;
