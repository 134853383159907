import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import emptyStar from '../../img/star-icon-empty.svg';
import fullStar from '../../img/star-icon-full.svg';

const RatingStarsStyled = styled.div`
display: flex;
img {
        width: 14px;
        height: 14px;
    }
`;

const RatingStars = ({score}) => {
    const [rating, setrating] = useState(null);
    // console.log(rating)
    useEffect(() => {
        setrating(score)
    }, [score]);

    const ratingOut = (val) => {
        if (val >= 5) {
            return  <>
                        < img src={fullStar} alt="" />
                        < img src={fullStar} alt="" />
                        < img src={fullStar} alt="" />
                        < img src={fullStar} alt="" />
                        < img src={fullStar} alt="" />
                    </>
        } else if (val >= 4) {
            return  <>
                        < img src={fullStar} alt="" />
                        < img src={fullStar} alt="" />
                        < img src={fullStar} alt="" />
                        < img src={fullStar} alt="" />
                        < img src={emptyStar} alt="" />
                    </>
        } else if (val >= 3) {
            return  <>
                        < img src={fullStar} alt="" />
                        < img src={fullStar} alt="" />
                        < img src={fullStar} alt="" />
                        < img src={emptyStar} alt="" />
                        < img src={emptyStar} alt="" />
                    </>
        } else if (val >= 2) {
            return  <>
                        < img src={fullStar} alt="" />
                        < img src={fullStar} alt="" />
                        < img src={emptyStar} alt="" />
                        < img src={emptyStar} alt="" />
                        < img src={emptyStar} alt="" />
                    </>
        } else if (val >= 1) {
            return  <>
                        < img src={fullStar} alt="" />
                        < img src={emptyStar} alt="" />
                        < img src={emptyStar} alt="" />
                        < img src={emptyStar} alt="" />
                        < img src={emptyStar} alt="" />
                    </>
        } else if (val >= 0) {
            return  <>
                        < img src={emptyStar} alt="" />
                        < img src={emptyStar} alt="" />
                        < img src={emptyStar} alt="" />
                        < img src={emptyStar} alt="" />
                        < img src={emptyStar} alt="" />
                    </>
        }
    }
    
    return (
        <RatingStarsStyled>
            {ratingOut(rating)}
        </RatingStarsStyled>
    );
}

export default RatingStars;
