import React from 'react';
import Button from '../../share/Button';
import { DescrCard } from '../../about/share/Titles';
import star from '../../../img/icons/star.png';
import emptyStar from '../../../img/icons/emptyStar.svg';
import Like from '../like/Like';
import cardHeartOutlineFilled from '../../../img/icons/icon_heartFilled.svg';
import cardHeartOutlineEmpty from '../../../img/icons/icon_heartEmpty.svg';
import { Basic_grey } from '../../../vendor/styles/baseColors';
import { NavLink, useNavigate } from 'react-router-dom';
import Flex from '../../share/Flex';
import { CardDoctorText, CardImage, CardOptionsNote, CardWrapper, Rating, StarStl } from './styled.doctorsCard';
import { useSelector } from "react-redux";

const DoctorsCard = (props) => {
    const whoAreYou = useSelector(state => state.authentification.whoAreYou);
    const navigate = useNavigate()

    return (
        <>
            <CardWrapper>
                <CardImage image={props.avatar}>
                    {whoAreYou === 'patient' &&
                        <>
                            {props.isFavorite?.id ? (
                                <Like
                                    imgSrc={cardHeartOutlineFilled}
                                    setFavorite={() => props.delFavorite(props.isFavorite.id)}
                                    altText="like"
                                />
                            ) : (
                                <Like imgSrc={cardHeartOutlineEmpty} setFavorite={() => props.setFavorite()} altText="like" />
                            )}
                        </>
                    }
                </CardImage>
                <div className="doctorsCard_top">
                    <CardDoctorText>
                        <div className="doctor_name">
                            {props.lastname} {props.name} {props.patronymic}
                        </div>
                        {props.rating ? (
                            <Flex minWidth="60px" align="baseline" justContent="flex-end">
                                <StarStl src={props.rating !== 0 ? star : emptyStar} alt="star" />
                                <Rating>{props.rating}</Rating>
                            </Flex>
                        ) : (
                            ''
                        )}
                    </CardDoctorText>

                    <CardOptionsNote
                        padding="0"
                        justContent="space-between"
                        style={{ flexDirection: 'column', gap: 10 }}
                    >
                        <DescrCard fontSize="18px" lineHeight="130%" color={Basic_grey}>
                            {props.specialization.map((item, i, arr) => {
                                return (
                                    <span key={i}>
                                        <span>{item.name}</span>
                                        <br />
                                    </span>
                                );
                            })}
                            {props.category === 0 ? (
                                <span>высшая категория</span>
                            ) : props.category === 1 ? (
                                <span>первая категория</span>
                            ) : props.category === 2 ? (
                                <span>вторая категория</span>
                            ) : (
                                ''
                            )} {props.scientific_degree === 1 ? 'к.м.н' : props.scientific_degree === 2 ? 'д.м.н' : ''} 
                        </DescrCard>
                        {props.price ? (
                            <DescrCard flex="flex" alignItems="flex-end" width="fit-content">
                                <p className="price">{`${props.price} руб.`}</p>
                            </DescrCard>
                        ) : (
                            ''
                        )}
                    </CardOptionsNote>

                    <CardOptionsNote padding="0" bottom="0px" style={{ marginTop: '10px' }}>
                        <div onClick={() => navigate(`/doctors/${props.slug}`, {state: {id: props.id}})} style={{width: '100%'}}>
                            <Button margin="0" green>
                                Записаться
                            </Button>
                        </div>
                    </CardOptionsNote>
                </div>
            </CardWrapper>
        </>
    );
};

export default DoctorsCard;

