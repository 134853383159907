import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDoctorBankDetails, getPayments, setDoctorBankDetails } from './api';

export const fetchWalletDetails = createAsyncThunk('doctorWallet/fetchWalletDetails', async () => {
    try {
        const response = await getDoctorBankDetails();
        return response.data[0];
    } catch (e) {
        console.log(e);
    }
});
export const fetchSetWalletDetails = createAsyncThunk('doctorWallet/fetchSetWalletDetails', async (data) => {
    try {
        const response = await setDoctorBankDetails(data);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const fetchGetPayments = createAsyncThunk('doctorWallet/fetchGetPayments', async (data, { getState }) => {
    try {
        const next = getState().doctorWallet.transactions.next;
        const response = await getPayments(data, next);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

const initialState = {
    walletDetails: {
        doctor_id: '',
        name: '',
        beneficiary_account: '',
        bank_name: '',
        correspondent_account: '',
        bic: '',
        kpp: '',
        inn: '',
    },
    transactions: {
        count: '',
        next: '',
        results: [],
        total: 0,
    },
};

const doctorWallet = createSlice({
    name: 'doctorWallet',
    initialState,
    reducers: {
        clearTransactions: (state) => {
            state.transactions = {
                count: '',
                next: '',
                results: [],
                total: 0,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWalletDetails.fulfilled, (state, action) => {
            if (action.payload) {
                state.walletDetails = action.payload;
            }
        });
        builder.addCase(fetchSetWalletDetails.fulfilled, (state, action) => {
            if (action.payload) {
                state.walletDetails = action.payload;
            }
        });
        builder.addCase(fetchGetPayments.fulfilled, (state, action) => {
            const { results, next, count, total } = action.payload;
            (state.transactions.next !== next || next === '') && (state.transactions.results.push(...results));
            state.transactions.next = next;
            state.transactions.count = count;
            state.transactions.total = total;
        });
    },
});

export const { clearTransactions } = doctorWallet.actions;

export default doctorWallet.reducer;
