import { initializeApp } from 'firebase/app';
import { useEffect, useState } from 'react';

const useFirebase = (config) => {
    const [app, setApp] = useState(null);

    useEffect(() => {
        const app = initializeApp(config);
        setApp(app);
        return () => {
            // Вы можете добавить очистку ресурсов при размонтировании компонента
        };
    }, [config]);

    return app;
};

export default useFirebase;
