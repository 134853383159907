import React from 'react';
import styled from 'styled-components';

/**
 * A Spacer component that adds vertical spacing by applying a bottom margin.
 *
 * @param {object} props - The props for the Spacer component.
 * @param {string} props.marginBottom - The bottom margin to be applied to the spacer.
 *                                       This determines the vertical space created.
 *
 * @returns {React.ReactElement} A styled div element that acts as a spacer.
 */

export const SpacerStyle = styled.div`
    margin-bottom: ${(props) => props.marginBottom};
`;

const Spacer = (props) => {
    return <SpacerStyle {...props} />;
};
export default Spacer;
