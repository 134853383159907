import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '../../../share/Modal';
import Switcher from '../../../share/formFields/Switcher';
import { ScheduleSettings } from './schedule/ScheduleSettings';
import { fetchDoctorReceptions, fetchUpdateDoctorReception, setLoading } from '../../api/lkDoctorCalendarSlice';
import { Calendar } from '../calendarComponents/calendar/Calendar';
import { DateHint, DateHintAppointment, TimeHint, TimeHintAppointment } from '../calendarComponents/CalendarHint';
import { CalendarModal } from '../calendarComponents/CalendarModal';

export const CalendarSettings = () => {
    // выбраный день, по умолчанию сегодняшний
    const [choosenDate, setChoosenDate] = useState(new Date());
    // выбор одного или нескольких дней
    const [isDays, setIsDays] = useState(false);
    // выбор интервала времени или по отдельности
    const [isInterval, setIsInterval] = useState(false);
    // модальное окно-уведомление о том, что данные сохранены
    const [toShowPopUp, setToShowPopUp] = useState(false);
    // массив выбранных дат
    const [choosenDates, setChoosenDates] = useState([]);
    // массив выбранных временных меток
    const [selectedTime, setSelectedTime] = useState([]);
    const dispatch = useDispatch();

    const handleSend = () => {
        setToShowPopUp(true);
        const now = Date.now();
        const timeStamps = selectedTime
            .filter((el) => el * 1000 > now)
            .map((el) => ({ time: new Date(el * 1000).getTime() / 1000 }));
        // в таком виде нужно на бэк
        let promises = [];
        choosenDates.forEach((el) => {
            promises.push(
                dispatch(
                    fetchUpdateDoctorReception({
                        begin: el.timeStamp,
                        finish: el.timeStamp + 86400,
                        timeStamps,
                    })
                )
            );
        });
        Promise.all(promises).then(() => {
            dispatch(fetchDoctorReceptions());
            dispatch(setLoading()); // сброс состояние лоудера
            setChoosenDates([]);
            setSelectedTime([]);
            setIsInterval(false);
        });
    };

    return (
        <>
            <h2 className="title">Создайте расписание</h2>
            <p className="title-hint">Вы можете выбрать день отдельно либо интервалом на 14 дней вперед</p>
            <div className="calendar">
                <div className="calendar__wrapper">
                    <Switcher
                        text="Выбрать интервал"
                        checked={isDays}
                        onChange={(e) => {
                            setIsDays(e.target.checked);
                        }}
                    />
                    <div className="hints">
                        <DateHint />
                        <DateHintAppointment />
                    </div>
                    <Calendar
                        choosenDate={choosenDate}
                        setChoosenDate={setChoosenDate}
                        isDays={isDays}
                        setChoosenDates={setChoosenDates}
                        choosenDates={choosenDates}
                    />
                    <span
                        className={`reset-btn ${choosenDates.length > 0 ? 'reset-btn_active' : ''}`}
                        onClick={() => {
                            setChoosenDates([]);
                            setSelectedTime([]);
                        }}
                    >
                        Сбросить
                    </span>
                </div>
                {choosenDates.length ? (
                    <div className="calendar__wrapper">
                        <Switcher
                            text="Выбрать интервал"
                            checked={isInterval}
                            onChange={(e) => {
                                setIsInterval(e.target.checked);
                            }}
                        />
                        <div className="hints">
                            <TimeHint />
                            <TimeHintAppointment />
                        </div>
                        <ScheduleSettings
                            choosenDates={choosenDates}
                            setSelectedTime={setSelectedTime}
                            isInterval={isInterval}
                        />
                        <span
                            className={`reset-btn ${selectedTime.length > 0 ? 'reset-btn_active' : ''}`}
                            onClick={() => {
                                setChoosenDates([...choosenDates]); // сбрасываем расписание до выбраных дат
                            }}
                        >
                            Сбросить
                        </span>
                    </div>
                ) : (
                    <div className="calendar__wrapper">
                        <div className="calendar__select--date">
                            <p className="calendar__select--date__placeholder">Пожалуйста, выберете день, чтобы создать расписание</p>
                        </div>
                    </div>
                )}
            </div>
            <button className="save-btn" type="button" onClick={handleSend}>
                Сохранить изменения
            </button>
            {toShowPopUp && (
                <Modal toggle={setToShowPopUp}>
                    <CalendarModal toggle={setToShowPopUp} />
                </Modal>
            )}
        </>
    );
};
