import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FieldWrapper } from '../FieldWrapper';

const InputText = ({
    name,
    placeholder,
    validation = {},
    label,
    onChange: outSideOnChange = () => {},
    deleteSpaces = null,
    isFullName = false,
    isRequired = false,
}) => {
    const { control, trigger } = useFormContext();
    const defaultValidation = {
        required: { value: false, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^[\p{L}-]*$/u,
            message: 'Имя может содержать только символы алфавита и дефис',
        },
        maxLength: {
            value: 30,
            message: 'Максимальная длина: 30 символов',
        },
        minLength: {
            value: 3,
            message: 'Минимальная длина: 3 символа',
        },
        ...validation,
        validate: {
            ...validation.validate,
        },
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules: defaultValidation });

    const handleBlur = () => {
        let inputValue = field.value;
        if (typeof inputValue === 'string') {
            // Удаление пробелов в начале и в конце строки
            inputValue = inputValue?.trim();
            // Сокращение всех последовательностей пробелов внутри строки до одного
            inputValue = inputValue?.replace(/ +/g, ' ');
            // Сокращение всех последовательностей дефисов внутри строки до одного
            inputValue = inputValue?.replace(/( -+)|(-+ )/g, '-').replace(/-+/g, '-');
            // Удаление дефисов по краям строки
            inputValue = inputValue?.replace(/^-+/, '').replace(/-+$/, '');
            // Изменение регистра первой буквы в полях ФИО
            isFullName && (inputValue = inputValue?.replace(inputValue[0], inputValue[0]?.toUpperCase()));
            if (deleteSpaces) {
                inputValue = inputValue?.replace(deleteSpaces, '');
            }
        }
        trigger(field.name);
        field.onChange(inputValue);
    };
    const clearedView = '';
    return (
        <FieldWrapper
            error={error}
            label={label}
            isRequired={isRequired}
            clearCondition={!!field.value}
            onClear={() => {
                field.onChange(clearedView);
                outSideOnChange(clearedView);
            }}
        >
            <input
                {...field}
                onBlur={handleBlur}
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                type="text"
                onChange={(e) => {
                    field.onChange(e.target.value);
                    trigger(field.name);
                    outSideOnChange(e.target.value);
                }}
            />
        </FieldWrapper>
    );
};

export default InputText;
