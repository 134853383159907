import styled from 'styled-components';
import closeIcon from '../../../../../img/icons/icon-close.svg';

export const StyledDocrotConsultationCard = styled.div`
    max-width: 600px;
    width: 100%;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    display: grid;
    column-gap: 60px;
    row-gap: 8px;
    grid-template-columns: auto 1fr;
    padding: 20px;
    position: relative;
    font-weight: 400;
    line-height: 150%;
    transition: all 0.3s;

    & > div {
        grid-column: 2;
    }

    .photoBlock {
        grid-column: 1;
        grid-row: span 4;
        width: 107px;
        height: 107px;
        border-radius: 50%;
        overflow: hidden;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .patientData {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .statusConsultation {
        font-size: 14px;
        color: #666666;
    }
    .red {
        color: #ff3636;
    }
    .patientName {
        font-size: 18px;
        color: #212121;
        word-break: break-word;
    }
    .closeBtnX {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 21px;
        right: 21px;
        cursor: pointer;
        background-image: url(${closeIcon});
        background-size: contain;
        background-repeat: no-repeat;
        color: #b6b7ba;
    }
    &:hover {
        background-color: #f4f4f4;
        cursor: pointer;
    }
    @media (max-width: 768px) {
        padding: 16px;
        grid-column-gap: 20px;
        .photoBlock {
            width: 65px;
            height: 65px;
        }
    }
    @media (max-width: 500px) {
        .statusConsultation {
            order: -1;
            grid-column: span 2;
        }
        .photoBlock {
            grid-row: span 3;
        }
        .patientName {
            font-size: 16px;
            line-height: 19px;
        }
    }
`;

export const ConsultationButtons = styled.div`
    font-size: 14px;
    margin-top: 12px;
    display: flex;
    gap: 12px;
    button {
        padding: 8px 16px;
        cursor: pointer;
        min-width: 113px;
    }
    .startBtn {
        border: 1px solid #19be6f;
        border-radius: 10px;
        background: #19be6f;
        color: #ffffff;
        font-weight: 500;
        line-height: 20px;
        background: #19be6f;
        border-radius: 6px;
    }
    .closeBtn {
        color: #19be6f;
        background: #ffffff;
        font-weight: 500;
        line-height: 20px;
        border: 2px solid #19be6f;
        border-radius: 6px;
    }
    .more {
        align-items: center;
        display: flex;
        color: #19be6f;
        background: #ffffff;
        border: none;
        text-decoration: none;
        align-self: center;
        text-align: center;
        @media (min-width: 768px) {
            display: none;
        }
    }
    @media (max-width: 500px) {
        grid-column: span 2 !important;
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
        .more{
            order: -1;
            width: 100%;
            padding-left: 0;

        }
        button{
            flex: 1;
        }
    }
    @media (max-width: 370px) {
        flex-direction: column;
    }
`;
