// принимает массив дней и день на который кликнули, меняет состоянее выбранный или нет
export function chooseDays(days, day) {
    const updatedDays = days
        .map((d) => {
            if (d === day) {
                d.choosen = !d.choosen;
            } else {
                d.choosen = false;
            }
            return d;
        })
        .filter((day) => day.choosen);

    return updatedDays;
}