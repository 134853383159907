import React from 'react';
import { useSelector } from 'react-redux';
import { NewFormSTL } from './newFormSTL.styled';
import { DoctorDataList } from '../../shared/DoctorDataList';
import { EditDataList } from '../../../../lkPatient/lkPatientProfile/shared/EditDataList';

const UserData = () => {
    const { user } = useSelector((state) => state.doctorProfile?.doctorProfile);

    return (
        <NewFormSTL>
            <h2 className="title">Личные данные</h2>
            <DoctorDataList name={'user'} user={user}/>
        </NewFormSTL>
    );
};

export default UserData;
