import React from 'react';
import { StyledStartConsultationModal } from './startConsultationModal.styled';
import { Link } from 'react-router-dom';
import { ConsultationTimeAndPrice } from '../../../share/consultationTime/consultationTime';
import { ReactComponent as LogoModal } from '../closeConsultationModal/logo_modal.svg';

export const StartConsultationModal = ({ consultation, setIsOpen, patient, date }) => {
    const minutes = Math.round((new Date(date) - new Date() / 1000) / 60);
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <StyledStartConsultationModal>
            <LogoModal />
            <div>
                <h3 className="modalStart__heading">Онлайн-консультация</h3>
                <p className="modalStart__nameDoctor">{patient}</p>
                <ConsultationTimeAndPrice date={date} isModal />
            </div>

            <p className="modalStart__timeBeforeConsultation">
                {minutes >= 0
                    ? `Осталось ${minutes > 60 ? 'более 60' : minutes} мин`
                    : `Началось ${Math.abs(minutes)} мин назад`}
            </p>

            {minutes <= 5 ? (
                <Link className="modalStart__delayBtn" to={`/videochat/${consultation}`}>
                    {minutes >= 0 ? 'Начать' : 'Присоединиться'}
                </Link>
            ) : (
                <button onClick={closeModal} className="modalStart__delayBtn">
                    Напомнить за 5 минут
                </button>
            )}
        </StyledStartConsultationModal>
    );
};
