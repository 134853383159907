import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { patientDataUpdate } from '../api/patientApi';
import { deleteMyAvatar, getCity, loadMyAvatar } from '../../share/api';
import { fetchLogout, fetchMeUser } from '../../authentification/api/authentificationSlice';
import { meUser } from '../../authentification/api/authentificationApi';

// НА ЗАМЕНУ ПОЙДЁТ fetchMeUser
/**
 * Асинхронное действие для получения профиля пациента.
 * @function fetchPatientProfile
 * @async
 * @returns {Promise<Object>} Данные профиля пациента.
 */
export const fetchPatientProfile = createAsyncThunk('userPatient/patientProfile', async (_, { dispatch }) => {
    const response = await meUser();
    return response;
});

// НА ЗАМЕНУ ПОЙДЁТ fetchDataUpdate
/**
 * Асинхронное действие для создания и редактирования профиля пациента.
 * @function fetchPatientDataUpdate
 * @async
 * @param {Object} data - Данные для обновления профиля пациента.
 * @returns {Promise<Object>} Обновлённые данные профиля пациента.
 */
export const fetchPatientDataUpdate = createAsyncThunk('userPatient/patientCreate', async (data) => {
    const response = await patientDataUpdate(data);
    return response;
});

// НА ЗАМЕНУ ПОЙДЁТ fetchLogout
/**
 * Асинхронное действие для выхода пользователя из системы.
 * @function logoutPatient
 * @async
 * @returns {Promise<null>} Всегда возвращает null.
 */
export const logoutPatient = createAsyncThunk('userPatient/logout', async (_, { dispatch }) => {
    dispatch(fetchLogout());
    return null;
});

//------------------------------------------------------------
/**
 * Асинхронное действие для загрузки аватара пользователя.
 * Пока не реализовано на бэке.
 * @function fetchLoadMyAvatar
 * @async
 * @param {File} file - Файл аватара.
 * @returns {Promise<Object|undefined>} Результат загрузки аватара.
 */
export const fetchLoadMyAvatar = createAsyncThunk('userPatient/loadMyAvatar', async (file, { dispatch }) => {
    const response = await loadMyAvatar(file);
    dispatch(fetchMeUser());
    return response.data;
});

/**
 * Асинхронное действие для удаления аватара пользователя.
 * Пока не реализовано на бэке.
 * @function fetchDeleteMyAvatar
 * @async
 * @returns {Promise<Object|undefined>} Результат удаления аватара.
 */
export const fetchDeleteMyAvatar = createAsyncThunk(
    'userPatient/fetchDeleteMyAvatar',
    async (_, { dispatch, rejectWithValue }) => {
        const response = await deleteMyAvatar();
        dispatch(fetchMeUser());
        return response.data;
    }
);

/**
 * Асинхронное действие для получения списка городов по строке поиска.
 * Пока не реализовано на бэке.
 * @function fetchCity
 * @async
 * @param {string} searchString - Строка поиска для фильтрации городов.
 * @returns {Promise<Array>} Список найденных городов.
 */
export const fetchCity = createAsyncThunk('UserDoctor/fetchCity', async (searchString) => {
    const response = await getCity(searchString);
    return response.data.results;
});

//--------------------------------------------------------------
/**
 * Начальное состояние слайса patientProfile.
 * @constant
 * @type {Object}
 * @property {Object|null} patientProfile - Профиль пациента.
 * @property {string|null} patientProfile.id - Идентификатор пациента.
 * @property {string|null} patientProfile.first_name - Имя пациента.
 * @property {string|null} patientProfile.last_name - Фамилия пациента.
 * @property {string|null} patientProfile.middle_name - Отчество пациента.
 * @property {string|null} patientProfile.date_birth - Дата рождения пациента.
 * @property {string|null} patientProfile.sex - Пол пациента.
 * @property {string|null} patientProfile.email - Электронная почта пациента.
 * @property {string|null} patientProfile.city - Город пациента.
 * @property {File|null} patientProfile.avatar - Файл аватара пациента.
 * @property {boolean} isLoader - Флаг загрузки данных профиля пациента.
 * @property {boolean} isPreloaderCity - Флаг загрузки данных городов.
 * @property {Array<Object>} city - Список городов.
 * @property {boolean} isAvatarLoader - Флаг загрузки аватара.
 */
const initialState = {
    patientProfile: {
        id: null,
        username: null,
        role: null,
        first_name: null,
        last_name: null,
        middle_name: null,
        date_birth: null,
        sex: null,
        email: null,
        phone: null,
        city: null,
        avatar: null,
    },
    isLoader: false,
    isPreloaderCity: false,
    city: [],
    isAvatarLoader: false,
};

/**
 * Слайс состояния для управления профилем пациента.
 * @constant
 * @type {Slice}
 */
const patientProfile = createSlice({
    name: 'patientProfile',
    initialState,
    reducers: {
        /**
         * Сбрасывает список городов в начальное состояние.
         * @function resetCity
         * @param {Object} state - Текущее состояние.
         */
        resetCity: (state) => {
            state.city = [];
        },
        /**
         * Устанавливает флаг загрузки аватара.
         * @function setAvatarLoader
         * @param {Object} state - Текущее состояние.
         * @param {Object} action - Действие Redux.
         * @param {boolean} action.payload - Флаг загрузки аватара.
         */
        setAvatarLoader: (state, action) => {
            state.isAvatarLoader = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPatientProfile.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchPatientProfile.fulfilled, (state, action) => {
            state.patientProfile = action.payload;
            state.isLoader = false;
        });
        builder.addCase(fetchPatientProfile.rejected, (state) => {
            state.isLoader = false;
        });
        // эти меоды пока не реализованы на бэке
        // builder.addCase(fetchLoadMyAvatar.fulfilled, (state, action) => {
        //     state.patientProfile.avatar = action.payload.file;
        // });

        // builder.addCase(fetchDeleteMyAvatar.fulfilled, (state) => {
        //     state.patientProfile.avatar = null;
        // });
        // builder.addCase(fetchCity.pending, (state) => {
        //     state.isPreloaderCity = true;
        // });
        // builder.addCase(fetchCity.fulfilled, (state, action) => {
        //     state.city = action.payload;
        //     state.isPreloaderCity = false;
        // });
        // builder.addCase(fetchCity.rejected, (state) => {
        //     state.isPreloaderCity = false;
        // });
        builder.addCase(logoutPatient.fulfilled, (state) => {
            return {
                ...state,
                patientProfile: {
                    id: null,
                    username: null,
                    role: null,
                    first_name: null,
                    last_name: null,
                    middle_name: null,
                    date_birth: null,
                    sex: null,
                    email: null,
                    phone: null,
                    city: null,
                    avatar: null,
                },
                isLoader: false,
                isPreloaderCity: false,
                city: [],
                isAvatarLoader: false,
            };
        });
    },
});

export const { resetCity } = patientProfile.actions;
export default patientProfile.reducer;
