import styled from 'styled-components';

export const StyledCardWrapper = styled.div`
    overflow: hidden;
    width: 334px;
    height: 572px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: #ffffff;
`;

export const StyledCardImage = styled.div`
    width: 302px;
    height: 312px;
    grid-area: image;
    background-image: url(${(props) => props.image || '/img/aboutCardsImages/centralCard.png'});
`;

export const StyledCardBody = styled.div`
    width: 100%;    
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .card__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        height: 260px;
    }

    .card__content-title {
        font-weight: 400;
        font-size: 24px;
        line-height: 130%;
        color: #212121;
        display: block;
    }

    .card__content-description {
        display: block;
        height: 100%;
        padding: 0 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #212121;
    }
`;

