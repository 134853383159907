import React from 'react';
import { DoctorReviewsStl } from './doctorReviews.styled';
import { useSelector } from 'react-redux';
import { ReviewCard } from './reviewCard/ReviewCard';
import { ReactComponent as ArrowUp } from '../../../img/icons/doctorProfilIcons/arrow-up.svg';
import { ScrollToTopArrowUp } from '../../share/ScrollToTopArrowUp';

export const DoctorReviews = ({ doctor, showMoreReviews }) => {
    const reviews = useSelector((state) => state.doctors.doctorReviews.results);
    const reviewsCount = useSelector((state) => state.doctors.doctorReviews.count);

    return (
        <DoctorReviewsStl>
            <div className="reviews__cards">
                {reviews?.map((review) => (
                    <ReviewCard review={review} key={review.id} />
                ))}
            </div>

            <div className="reviews__buttons-container">
                {reviews?.length < reviewsCount && (
                    <button className="reviews__btn" onClick={showMoreReviews}>
                        Показать больше
                    </button>
                )}
                <ScrollToTopArrowUp />
            </div>
        </DoctorReviewsStl>
    );
};
