import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { StyledPaymentPopUp, StyledLink, StyledLoader } from './paymentPopUp.styled';

const PaymentPopUp = () => {
    const { paymentConfirmationStatus, isPaid } = useSelector((state) => state.payments);

    return (
        <StyledPaymentPopUp>
            {paymentConfirmationStatus === 'loading' && (
                <>
                    <StyledLoader />
                    <p className="textRetry"> Вернуться в Мои консультации </p>
                </>
            )}
            {paymentConfirmationStatus === 'idle' && (
                <>
                    <p className="textAttention">
                        {isPaid ? 'Обращение успешно создано и оплачено!' : 'Что-то пошло не так'}
                    </p>
                    {isPaid ? null : <p className="textRetry">Пожалуйста, попробуйте еще раз</p>}
                </>
            )}
            <StyledLink>
                <Link to={'/lk/patient-profile/consultation/future-consultation'}>Вернуться к консультациям</Link>
            </StyledLink>
        </StyledPaymentPopUp>
    );
};

export default PaymentPopUp;
