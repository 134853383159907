import styled from 'styled-components';

export const ReviewsListStyled = styled.div`
    width: 100%;
    max-width: 774px;
    margin: 0 auto;

    .list {
        display: flex;
        flex-direction: column;
        row-gap: 35px;
        position: relative;
        @media (max-width: 350px) {
            row-gap: 24px;
        }
    }

    .reviews__buttons-container {
        display: flex;
        justify-content: flex-end;
        gap: 42px;
    }
    .reviews__btn {
        color: #19be6f;
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        background-color: #ffffff;
        border: none;
        transition: opacity 0.5s linear;
        cursor: pointer;
        padding: 12px;
        &:hover {
            opacity: 0.7;
        }
        @media (max-width: 350px) {
            font-size: 14px;
        }
    }
    .buttonUp {
        font-size: 16px;
        line-height: 150%;
        @media (max-width: 350px) {
            font-size: 14px;
        }
    }
`;
