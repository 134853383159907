import styled, { css } from 'styled-components';

export const StyledAboutPage = styled.div `
    font-weight: 400;
    font-size: 26px;
    line-height: 150%;
    color: #212121;

    @media (max-width: 925px) {
        font-size: 18px;
        line-height: 150%;
    }

    @media (max-width: 350px) {
        & > div:first-child {
            text-align: center;
        }
    }

    .about__description-upper {
        font-size: 24px;
        line-height: 130%;
        @media (max-width: 925px) {
            text-align: center;
        }
    }

    .about__cards-display {
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin: 40px auto;
        @media (max-width: 1100px) {
            flex-direction: column;
            align-items: center;
            gap: 24px;
        }
    }

    .about__advantages-image {
        width: 100%;
        height: 762px;
        position: relative;
        @media (max-width: 925px) {
            height: 100%;
        }   
    }
    
`;

export const StyledAboutContent = styled.div `
    display: flex;
    flex-direction: column;
    gap: 180px;
    @media (max-width: 780px) {
        gap: 64px;
    }
`;