import { NavLink, useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

import ArrowIcon from '../../share/ArrowIcon';
import { StyledNavbar } from './navbar.styled';
import { useMediaQuery } from 'react-responsive';
import useOutsideClick from '../../../hooks/useOutsideClick';

function Navbar() {
    // выделение активного пункта "Полезное"
    const { pathname } = useLocation();
    const [activeUseful, setActiveUseful] = useState(false);

    useEffect(() => {
        if (
            pathname.slice(0, 5) === '/news' ||
            pathname === '/vacancies' ||
            pathname === '/reviews' ||
            pathname === '/politics'
        ) {
            setActiveUseful(true);
        } else {
            setActiveUseful(false);
        }
    }, [pathname]);
    //

    const isTablet = useMediaQuery({ query: '(max-width: 700px)' });

    const linksArray = [
        {
            id: 1,
            link: '',
            text: <>О&nbsp;наc</>,
        },
        {
            id: 2,
            link: 'doctors',
            text: <>Наши&nbsp;врачи</>,
        },
        {
            id: 3,
            link: 'contacts',
            text: 'Контакты',
        },
    ];
    const menuArray = [
        {
            id: 1,
            link: 'news',
            text: 'Блог',
        },
        {
            id: 2,
            link: 'vacancies',
            text: 'Вакансии',
        },
        {
            id: 3,
            link: 'reviews',
            text: 'Отзывы',
        },
        {
            id: 4,
            link: 'politics',
            text: 'Юридическая информация',
        },
    ];

    const menu = useRef(null);
    const [openMenu, setOpenMenu] = useState(false);

    function toggleMenu() {
        setOpenMenu(!openMenu);
    }

    function closeMenu() {
        setOpenMenu(false);
    }

    useOutsideClick(menu, closeMenu, openMenu);

    function renderNavlinks(linksArray) {
        return linksArray.map((link) => (
            <li key={link.id} className="navlink__item">
                <NavLink to={link.link} className="navlink__link">
                    {link.text}
                </NavLink>
            </li>
        ));
    }

    function renderMenu(menuArray) {
        return menuArray.map((link) => (
            <li key={link.id} className="menu__item">
                <NavLink to={link.link} className="menu__link">
                    {link.text}
                </NavLink>
            </li>
        ));
    }

    return (
        <StyledNavbar>
            {renderNavlinks(linksArray)}
            <div className="last">
                <>
                    <li className="navlink__item last" onClick={toggleMenu} ref={menu}>
                        <span className={`navlink__link ${activeUseful ? 'active' : ''}`}>
                            {' '}
                            Полезное {isTablet && <ArrowIcon isOpen={openMenu} />}
                        </span>
                    </li>
                    {openMenu && <menu className="navbar__menu">{renderMenu(menuArray)}</menu>}
                </>
            </div>
        </StyledNavbar>
    );
}

export default Navbar;
