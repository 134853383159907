import React, { useEffect } from 'react';
import { FiltersStl } from './filters.styled';
import { useSelector } from 'react-redux';
import { fetchSpecializations } from '../../lkDoctor/api/doctorUserProfileSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as SearchSvg } from '../../../img/icons/newDoctorIcons/search.svg';
import Select from '../../share/formFields/Select';
import InputText from '../../share/formFields/InputText';
import Form from '../../share/formFields/Form';
import SelectSpecializaton from '../../share/formFields/newElements/selectSpecialization/SelectSpecialization';

export const Filters = ({ setFilters }) => {
    const dispatch = useDispatch();
    const { allSpecializations } = useSelector((state) => state.doctorProfile);
    useEffect(() => {
        dispatch(fetchSpecializations());
    }, []);

    const sortType = [
        { value: 'video_chat_price', label: 'По возрастанию стоимости' },
        { value: '-video_chat_price', label: 'По убыванию стоимости' },
        { value: '-ratings_rating', label: 'По рейтингу' },
        { value: '-seniority', label: 'Большой стаж' },
    ];

    return (
        <FiltersStl>
            <Form>
                <Select
                    name="sort"
                    options={sortType}
                    placeholder="Сортировка"
                    validation={{ required: false }}
                    onChange={(sort) =>
                        setFilters((prev) => {
                            if (sort == null) {
                                return { ...prev, sort: '' };
                            }
                            return { ...prev, sort: String(sort.value) };
                        })
                    }
                />
                <SelectSpecializaton
                    name="spec"
                    options={allSpecializations?.map((spec) => ({ value: spec.id, label: spec.name }))}
                    defaultValue="9999"
                    placeholder="Специализация"
                    validation={{ required: false }}
                    isSearchable={true}
                    onChange={(spec) =>
                        setFilters((prev) => {
                            if (spec == null) {
                                return { ...prev, spec: '9999' };
                            }
                            return { ...prev, spec: String(spec.value) };
                        })
                    }
                />
                <label className="inputSearch">
                    <InputText
                        name="search"
                        placeholder={'Поиск по Ф.И.О'}
                        validation={{
                            required: false,
                            pattern: {
                                value: /^[\p{L} ]*$/u,
                                message: 'Имя может содержать только символы алфавита',
                            },
                        }}
                        onChange={(search) => setFilters((prev) => ({ ...prev, search: String(search) }))}
                    />
                    <SearchSvg />
                </label>
            </Form>
        </FiltersStl>
    );
};
