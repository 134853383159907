import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

import Container from '../../share/Container';
import { LeftColumn } from './lkPatient.styled';
import { LkPatientNavigation } from '../lkPatientNavigation/lkPatientNavigation';
import { MainHeader } from '../../mainHeader/mainHeader';
import { NavLink } from 'react-router-dom';
import { StyledLkPatient } from './lkPatient.styled';
import { useMediaQuery } from 'react-responsive';

// убрано всвязи с оплатой без юкассы, вернуть при возвращении оплаты с юкассой
// import { fetchPatientProfile } from '../api/PatientProfileSlice';
// import { useDispatch } from 'react-redux';

export const LkPatient = () => {
    const isTablet = useMediaQuery({ query: '(max-width: 700px)' });
    const { pathname } = useLocation();
    const navigate = useNavigate();

    // убрано всвязи с оплатой без юкассы, вернуть при возвращении оплаты с юкассой
    // const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(fetchPatientProfile());
    // }, []);

    useEffect(() => {
        if (pathname === '/lk/patient-profile') {
            navigate('/lk/patient-profile/profile', { replace: true });
        }
    }, [pathname]);

    return (
        <StyledLkPatient>
            <Container>
                <MainHeader title={'Личный кабинет'} />
                <div className="content">
                    {!isTablet && (
                        <LeftColumn>
                            <LkPatientNavigation />
                            {!pathname.startsWith('/lk/patient-profile/consultation') ? (
                                <NavLink className="nav-link nav-button" to="/doctors">
                                    Записаться&nbsp;к&nbsp;врачу
                                </NavLink>
                            ) : null}
                        </LeftColumn>
                    )}
                    <div className="rightBlock">
                        <Outlet />
                    </div>
                </div>
            </Container>
        </StyledLkPatient>
    );
};
