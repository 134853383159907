
import styled from 'styled-components';

export const StyledCard = styled.div`
    min-height: 450px;
    width: 245px;
    box-sizing: content-box;
    border: 1px solid #F4F4F4;
    //box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.05));
    border-radius: 10px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: stretch;
    position: relative;
    margin-bottom: 35px;

    .img {
        height: fit-content;
        width: fit-content;

        img {
            height: 272px;
            width: 245px;
            border-radius: 10px;
            object-fit: cover;
        }
    }
`;

export const StyledCardContent = styled.div`
    height: 204px;
    width: 245px;
    border-radius: 0px 0px 10px 10px;
    background: #ffffff;
    position: absolute;
    top: 246px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    
    h4 {
        display: block;
        max-width: 196px;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        align-content: center;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        color: #212121;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #666666;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;