import React from 'react';
import { RadioInput, RadioLabel, RadioText } from './RadioButton.styled';

/**
 * Компонент RadioButton — элемент радио-кнопки с лэйблом.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.id - Уникальный идентификатор радио-кнопки.
 * @param {string} props.name - Имя группы радио-кнопок.
 * @param {boolean} props.isChecked - Флаг, указывающий, выбрана ли радио-кнопка.
 * @param {Function} props.onChange - Функция, вызываемая при изменении значения радио-кнопки.
 * @param {string} props.label - Текст лэйбла для радио-кнопки.
 *
 * @returns {JSX.Element} Возвращает компонент радио-кнопки.
 */
const RadioButton = ({ id, name, isChecked, onChange, label }) => {
    return (
        <RadioLabel htmlFor={id}>
            <RadioInput id={id} name={name} checked={isChecked} onChange={onChange} value={''} />
            <div className={`image ${isChecked ? 'checked' : ''}`} />
            <RadioText>{label}</RadioText>
        </RadioLabel>
    );
};

export default RadioButton;
