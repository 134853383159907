import styled from 'styled-components';

export const LabelStl = styled.label`
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-bottom: 28px;
    max-width: 334px;
    a {
        text-decoration: none;
        color: #19be6f;
    }
    input {
        margin-right: 14px;
        align-self: flex-start;
    }

    @media (max-width: 768px) {
        p {
            font-size: 14px;
        }
    }
`;
