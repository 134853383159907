import styled from 'styled-components';

export const StyledContactsCard = styled.a`
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    padding: 22px;
    border: 2px solid #f4f4f4;
    border-radius: 10px;
    transition: box-shadow 0.3s linear;
    transition: 0.3s background-color;
    cursor: pointer;
    &:hover {
        background-color: #f4f4f4;
    }
    .icon {
        display: flex;
        align-items: flex-start;
        margin-right: 24px;
    }
    .content {
        &__title {
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 9px;
        }
        &__content {
            font-size: 16px;
            line-height: 150%;
            max-width: 200px;
        }
    }

    @media (max-width: 879px) {
        padding: 14px;
        border-radius: 7px;
        .icon {
            margin-right: 16px;
            & svg {
                height: 28px;
                width: 28px;
            }
        }
        .content {
            &__title {
                font-size: 18px;
            }
            &__content {
                line-height: 130%;
            }
        }
    }
    @media(max-width: 768px) {
        margin-bottom: 16px;
        .content {
            &__title {
                font-size: 16px;
            }
            &__content {
                font-size: 14px;
            }
        }
    }
`;
