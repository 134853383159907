import styled from 'styled-components';

export const VisitNoticeStl = styled.div`
    padding: 52px 73px 40px 73px;
    max-width: 414px;
    background: rgb(255, 255, 255);
    border-radius: 24px;
    .application {
        text-align: center;
        &__icon {
        }
        &__title {
            font-weight: 500;
            font-size: 20px;
            color: rgb(102, 102, 102);
            margin-top: 45px;
        }
        &__text {
            line-height: 150%;
            font-size: 18px;
            color: rgb(102, 102, 102);
            margin-top: 25px;
        }
        &__button {
            color: #19be6f;
            margin-top: 50px;
            font-size: 16px;
            border: none;
            background-color: transparent;
        }
        &__button:hover {
            cursor: pointer;
        }
    }
    @media (max-width: 415px) {
        padding: 82px 43px 70px 43px;
    }
`;
