import styled from 'styled-components';

export const PatientSettingsDiv = styled.div`
    font-weight: 400;
    line-height: 150%;
    width: 100%;
    max-width: 510px;
    height: 280px;

    .settings__item {
        font-size: 18px;
    }
    .settings__item-label {
        color: #19be6f;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            color: red;
        }
    }
`;
