import { FieldWrapper } from './FieldWrapper';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { clearMessage } from '../../authentification/api/authentificationSlice';

/**
 * Компонент InputEmail — текстовое поле для ввода email с валидацией.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля для формы.
 * @param {string} props.label - Лэйбл поля (текст, который отображается рядом с полем).
 * @param {string} props.placeholder - Текст подсказки, который отображается в поле до ввода данных.
 * @param {string} [props.defaultValue=''] - Значение поля по умолчанию.
 * @param {Object} [props.validation={}] - Дополнительные правила валидации.
 * @param {Function} [props.onChange=() => {}] - Функция, которая вызывается при изменении значения поля.
 * @param {Function} props.trigger - Функция для принудительной проверки валидации формы.
 * @param {Object} props.control - Контроллер react-hook-form для управления полем.
 * @param {Object} props.messagesError - Сообщения об ошибках, которые могут быть переданы для отображения.
 *
 * @returns {JSX.Element} Возвращает компонент текстового поля для ввода email с валидацией.
 */
const InputEmail = ({
    name,
    label,
    placeholder,
    isRequired,
    defaultValue = '',
    validation = {},
    onChange = () => {},
    messagesError,
}) => {
    const dispatch = useDispatch();
    const { control, trigger, setValue } = useFormContext();

    const validateEmail = (value) => {
        const allowedChars = /^[a-zA-Z0-9._\-@]+$/;

        // Проверка на разрешенные символы
        if (!allowedChars.test(value)) {
            return 'Используйте буквы латинского алфавита или . - _ @';
        }

        // Проверка на обязательность символа @
        if (!value.includes('@')) {
            return 'Email должен содержать символ @';
        }

        // Проверка, что @ встречается только один раз
        const atCountInEmail = (value.match(/@/g) || []).length;
        if (atCountInEmail > 1) {
            return 'Email должен содержать только один символ @';
        }

        // Проверка на наличие части после @
        const atIndex = value.indexOf('@');
        const domain = value.slice(atIndex + 1);
        if (domain.length === 0) {
            return 'После символа @ должен быть хотя бы один символ';
        }

        // Проверка на наличие точки после @
        if (!domain.includes('.')) {
            return 'После символа @ должна быть хотя бы одна точка';
        }

        // Проверка на наличие части после @
        if (value.includes('@')) {
            if (domain.includes('.')) {
                const domainParts = domain.split('.');
                const tld = domainParts.length > 1 ? domainParts[domainParts.length - 1] : '';
                if (tld.length < 2) {
                    return 'Домен должен содержать не менее 2 символов';
                }
            }
        }

        if (value.length < 6) {
            return 'Минимальная длина: 6 символов';
        }

        if (value.length > 70) {
            return 'Максимальная длина: 70 символов';
        }

        return true;
    };

    const defaultValidation = {
        required: { value: true, message: 'Поле обязательно для заполнения' },
        validate: validateEmail,
        ...validation,
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, defaultValue, rules: defaultValidation });

    const clearedView = '';

    const handleKeyDown = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;

        if (messagesError) {
            dispatch(clearMessage(e.target.name));
        }

        setValue(e.target.name, value);
        trigger(e.target.name);
    };

    const handleClear = () => {
        field.onChange(clearedView);
        onChange(clearedView);
        trigger(field.name);
        dispatch(clearMessage(messagesError));
    };

    return (
        <FieldWrapper
            error={error}
            messagesError={messagesError}
            label={label}
            clearCondition={field.value !== clearedView}
            isRequired={isRequired}
            onClear={handleClear}
        >
            <input
                {...field}
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                id={name}
                type="email"
                autoComplete="email"
                onChange={(e) => handleInputChange(e)}
                onKeyDown={handleKeyDown}
            />
        </FieldWrapper>
    );
};

export default InputEmail;
