import React from 'react';
import { CalendarBodyStl } from './calendarBody.styled';
import { WeekDays } from './weekDays/WeekDays';
import { Days } from './days/Days';

export const CalendarBody = ({ choosenDate, setChoosenDate, isDays, setChoosenDates, choosenDates }) => {
    return (
        <CalendarBodyStl>
            <WeekDays />
            <Days
                choosenDate={choosenDate}
                setChoosenDate={setChoosenDate}
                isDays={isDays}
                setChoosenDates={setChoosenDates}
                choosenDates={choosenDates}
            />
        </CalendarBodyStl>
    );
};
