import { Outlet, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { selectFutureConsultations, selectPastConsultations } from '../../api/PatientConsultationsSlice';
import { useDispatch, useSelector } from 'react-redux';

import { ConsultationList } from './ConsultationList';
import { ConsultationListStl } from './consultationList.styled';
import { Modal } from '../../../share/Modal.jsx';
import { ModalSuccessfulConsultation } from '../../../lkDoctor/lkDoctorModals/modalSuccessfulConsultation/ModalSuccessfulConsultation.jsx';
import { setIsModalSuccessfulConsultationPatient } from '../../api/PatientConsultationsSlice';
import { useAppSelector } from '../../../../store/index.ts';

export const PatientConsultationList = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const regex = /\/\d+$/;
    const { patientConsultations, isLoading, isModalSuccessfulConsultation, timeConsultation } = useSelector(
        (state) => state.patientConsultations
    );
    const [consultationsList, setConsultationsList] = useState({});

    useEffect(() => {
        !pathname.includes('future')
            ? setConsultationsList(patientConsultations.past)
            : setConsultationsList(patientConsultations.future);
    }, [pathname, patientConsultations]);

    const closePopupSuccessfulConsultation = () => {
        dispatch(setIsModalSuccessfulConsultationPatient());
    };

    return (
        <ConsultationListStl>
            {regex.test(pathname) ? (
                <Outlet />
            ) : (
                <ConsultationList
                    list={consultationsList}
                    pathname={pathname}
                    // next={next}
                    isLoading={isLoading}
                />
            )}
            {isModalSuccessfulConsultation && (
                <Modal toggle={closePopupSuccessfulConsultation}>
                    <ModalSuccessfulConsultation
                        closePopupSuccessfulConsultation={closePopupSuccessfulConsultation}
                        timeConsultation={timeConsultation}
                    />
                </Modal>
            )}
        </ConsultationListStl>
    );
};
