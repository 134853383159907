import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { StyledLkDoctor } from './styled.lkDoctor';
import LkDoctorNavigation from '../lkDoctorNavigation/lkDoctorNavigation';
import { MainHeader } from '../../mainHeader/mainHeader';
import Container from '../../share/Container';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { fetchMeDoctor } from '../api/doctorUserProfileSlice';

const LkDoctor = () => {
    const isTablet = useMediaQuery({ query: '(max-width: 768px)' });
    const path = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchMeDoctor());
    }, []);

    useEffect(() => {
        if (path.pathname === '/lk/doctor-profile') {
            navigate('/lk/doctor-profile/profile', { replace: true });
        }
    }, [path.pathname]);

    return (
        <StyledLkDoctor>
            <Container>
                <MainHeader title={'Личный кабинет'} />
                <div className="content">
                    {!isTablet && <LkDoctorNavigation />}
                    <div className="rightBlock">
                        <Outlet />
                    </div>
                </div>
            </Container>
        </StyledLkDoctor>
    );
};

export default LkDoctor;
