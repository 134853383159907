import styled from 'styled-components';
import doctorsHome from '../../../../img/doctorHouse.jpg';
import iconLike from '../../../../img/icons/icon-like.svg';
import iconLikeActive from '../../../../img/icons/icon-like-active.svg';

export const StyledCard = styled.li`
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;

    .card__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 277px);
        margin: 16px;
    }

    .card__img {
        width: 100%;
        height: 245px;
        background-image: url(${(props) => props.avatar || doctorsHome});
        background-size: cover;
    }

    .card__nameLike {
        display: flex;
        justify-content: space-between;
    }

    .card__like {
        min-width: 20px;
        height: 18px;
        margin: 7px 3px 0 0;
        background-image: url(${(props) => (props.isFavorite ? iconLikeActive : iconLike)});
        background-size: cover;
        border: none;
        background-color: transparent;
        cursor: pointer;
        :hover {
            opacity: 0.8;
        }
    }

    .card__name {
        margin: 0 16px 16px 0;
        overflow: hidden;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: #212121;
    }

    .card__specialization {
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #666666;
    }

    .card__experience {
        margin: 16px 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #666666;
    }

    .card__rating {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #212121;
    }

    .card__ratingImg {
        width: 16px;
        margin: 0 4px 0 8px;
        // object-fit: cover;
    }

    .card__price {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        color: #212121;
    }

    .card__button {
        justify-self: end;
        width: 100%;
        min-height: 44px;
        // border: none;
        background: #19be6f;
        border: 2px solid #19be6f;
        border-radius: 10px;
        cursor: pointer;
        padding: 5px;
        text-decoration: none;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        :hover {
            opacity: 0.8;
        }
        & a {
        }
    }
`;
