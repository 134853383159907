import React from 'react';
import { StyledAdvantages, StyledRectangle } from './advantages.styled';

const Advantages = () => {
    return (
        <StyledAdvantages>
            <StyledRectangle>
                 <p className='about__advantages_text'>Квалифицированная помощь <br/> от иностранных врачей</p>
            </StyledRectangle>

            <StyledRectangle left='441' top='181' background='rgba(6, 167, 179, 0.3)'>
                 <p className='about__advantages_text'>Удобное время консультации</p>
            </StyledRectangle>

            <StyledRectangle left='88' top='353' background='rgba(25, 190, 111, 0.3)'>
                 <p className='about__advantages_text'>Возможность обратиться к <br/> консилиуму врачей</p>
            </StyledRectangle>

            <StyledRectangle left='526' top='529' background='rgba(220, 230, 255, 0.3)'>
                 <p className='about__advantages_text'>Врачи высшей категории</p>
            </StyledRectangle>
        </StyledAdvantages>
    );
};

export default Advantages;

// TODO Убрать это