import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { identifyWhoAreYou } from '../../header/helpers/identifyWhoAreYou';

function FooterMain() {
    const onScroll = () => {
        window.scrollTo(0, 0);
    };

    const whoAreYou = useSelector((state) => state.authentification.whoAreYou);

    const path = identifyWhoAreYou(whoAreYou);

    return (
        <div className='footer__block'>
            <h2 className="footer__title">Главное</h2>
            <NavLink to="" onClick={onScroll} className="footer__link">
                О нас
            </NavLink>
            <NavLink to="doctors" onClick={onScroll} className="footer__link">
                Наши врачи
            </NavLink>
            <NavLink to="contacts" onClick={onScroll} className="footer__link">
                Контакты
            </NavLink>
            <NavLink to={path} onClick={onScroll} className="footer__link">
                Личный кабинет
            </NavLink>
        </div>
    );
}

export default FooterMain;