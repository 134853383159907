import React, { useState } from 'react';
import { StyledConsultationButtons } from './consultationButtons.styled';
import { Modal } from '../../../share/Modal';
import { CloseConsultationModal } from '../../lkDoctorModals/closeConsultationModal/closeConsultationModal';
import { StartConsultationModal } from '../../lkDoctorModals/startConsultationModal/startConsultationModal';
import { useDispatch } from 'react-redux';
import { setKeyConsultationDoctor } from '../../api/lkDoctorConsultationsSlice';
import { useLocation } from 'react-router-dom';

export const ConsultationButtons = ({ consultation }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const [startConsultation, setStartConsultation] = useState(false);
    const [firstCloseConfirm, setFirstCloseConfirm] = useState(false);
    const { status, patient, id, date, security_key } = consultation;
    const { last_name, first_name, patronymic } = patient.user;
    let fullName = `${last_name} ${first_name} ${patronymic}`;
    let buttons;

    const closeConsultation = (e) => {
        e.stopPropagation();
        setFirstCloseConfirm(true);
    };

    const openStartModal = (e) => {
        e.stopPropagation();
        setStartConsultation(true);
        dispatch(setKeyConsultationDoctor(security_key))
    };

    switch (status) {
        case 0:
            buttons = (
                <>
                    <button onClick={openStartModal} className="startBtn">
                        Начать
                    </button>

                    <button onClick={closeConsultation} className="closeBtn">
                        Отменить
                    </button>
                </>
            );
            break;
        case 1:
            if (consultation.recommendation.status !== 'sent') {
                buttons = (
                    <>
                        <button className="startBtn">Оставить рекоммендации</button>
                    </>
                );
            } else {
                buttons = null;
            }
            break;
        case 2:
        case 9:
        default:
            buttons = null;
    }

    return (
        <StyledConsultationButtons 
            widthCard={
                '/lk/doctor-profile/consultations/future' === pathname || 
                '/lk/doctor-profile/consultations/past' === pathname
        }
        >
            {buttons}
            {startConsultation && (
                <Modal toggle={setStartConsultation}>
                    <StartConsultationModal
                        consultation={id}
                        isOpen={startConsultation}
                        setIsOpen={setStartConsultation}
                        patient={fullName}
                        date={date}
                    />
                </Modal>
            )}
            {firstCloseConfirm && (
                <Modal toggle={setFirstCloseConfirm}>
                    <CloseConsultationModal consultation={consultation} setFirstConfirm={setFirstCloseConfirm}/>
                </Modal>
            )}
        </StyledConsultationButtons>
    );
};
