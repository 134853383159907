import { apiRequest } from '../../../share/api';

export const getTopDoctors = async () => {
    // return await apiRequest.get(`/doctors/top`);
    console.log('no endpoint yet for getTopDoctors');
    return {};
};

export const getTopDoctorsAuth = async () => {
    // return await apiRequest.get(`/doctors/top`);
    console.log('no endpoint yet for getTopDoctorsAuth');
    return {};
};
