import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchPaymentConfirmation } from '../componets/lkPatient/api/paymentsSlice';
import PaymentPopUp from '../componets/payment/paymentPopUp';
import Container from '../componets/share/Container';
import { StyledPayment } from './payment.styled';

const Payment = () => {
    let { id } = useParams();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchPaymentConfirmation(id));
    }, []);

    return <StyledPayment>
        <Container>
            <PaymentPopUp />
        </Container>
    </StyledPayment>
}

export default Payment;