import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchFeedback } from '../../../../api/PatientConsultationsSlice';
import { FeedbackForm } from './feedbackForm/FeedbackForm';
import { ReviewCard } from '../../../../../doctorProfile/doctorReviews/reviewCard/ReviewCard';

export const Feedback = ({ consultationId, feedback, feedbackId }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (feedbackId) {
            dispatch(fetchFeedback(feedbackId));
        }
    }, [feedbackId]);

    return <>{feedback ? <ReviewCard review={feedback} /> : <FeedbackForm consultationId={consultationId} />}</>;
};
