import styled from 'styled-components';

export const StyledLkPatientNavigation = styled.nav`
    display: flex;
    flex-direction: column;
    width: 220px;

    @media (max-width: 767px) {
        width: 100%;
    }
`;
