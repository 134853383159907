import React from 'react';
import styled from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import { FieldWrapper } from '../FieldWrapper';


const InputPrice = ({ name, label, placeholder, defaultValue, validation = {}, isRequired, isPrice=false }) => {
    // outSideOnChange можем передать внешний коллбек, если будет нужно
    const { control, trigger } = useFormContext();
    const defaultValidation = {
        required: { value: true, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^[0-9]{1,5}$/,
            message: ' не число ',
        },
        min: {
            value: 1,
            message: '',
        },
        maxLength: {
            value: 5,
            message: 'Максимальная длина: 5 символов',
        },
        validate: {},
        ...validation,
    };
    const {
        field,
        fieldState: { error },
    } = useController({ name, control, defaultValue, rules: defaultValidation });
    
    const clearedView = '';

    const handleBlur = () => {
        let inputValue = field.value;

        // убираем ноли вначале
        // if (inputValue) {
        //     while(inputValue.charAt(0) === 0) {
        //         inputValue = inputValue.substring(1);
        //     }    
        // }

        trigger(field.name);
        field.onChange(inputValue);
    };

    return (
        <FieldWrapper
            error={error}
            label={label}
            isRequired={isRequired}
            clearCondition={!!field.value}
            onClear={() => {
                field.onChange(clearedView);
            }}
            isPrice={isPrice}
        >
            <input
                {...field}
                inputMode="numeric"
                className={`input-price ${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                type="number"
                onBlur={handleBlur}
                onChange={(e) => {
                    field.onChange(e.target.value);
                    trigger(field.name);
                }}
            />
        </FieldWrapper>
    );
};

export default InputPrice;
