import styled from 'styled-components';

export const DoctorProfileStl = styled.div``;

export const DoctorProfilePageStl = styled.div`
    .doctor-info {
        display: flex;
        > div {
            gap: 34px;
            width: 50%;
        }

        .right {
            > div:nth-child(2) {
                width: 60%;
            }
            .calendar {
                margin-left: 10px;
            }
        }
    }
    .back {
        display: flex;
        gap: 6px;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #19be6f;
        cursor: pointer;
        > svg {
            transform: rotate(-90deg);
        }
        margin-top: 25px;
    }
    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 1024px) {
        .doctor-info {
            .right {
                > div:nth-child(2) {
                    width: 75%;
                }
            }
        }
    }
    @media (max-width: 790px) {
        .doctor-info {
            .right {
                > div:nth-child(2) {
                    width: 85%;
                }
            }
        }
    }
    @media (max-width: 680px) {
        .doctor-info {
            .right {
                > div:nth-child(2) {
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 575px) {
        .doctor-info {
            flex-direction: column;
            > div {
                width: 100%;
            }
    
        }
    }
`;
