import React from 'react';
import { useEffect } from 'react';
import Container from '../componets/share/Container';
import { MainHeader } from '../componets/mainHeader/mainHeader';
import { VacanciesContent } from '../componets/vacancies/vacanciesContent/vacanciesContent';

const Vacancies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <MainHeader title={'Вакансии'} />
      <VacanciesContent />
    </Container>
  );
};
export default Vacancies;
