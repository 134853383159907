import React from 'react'
import { ReactComponent as Arrow } from '../../../../../../../img/icons/doctorProfilIcons/arrow.svg';

export const StepButton = ({ direction, className, targetDate, setTargetDate }) => {
    const handleClick = () =>
        setTargetDate(new Date(Date.UTC(targetDate.getUTCFullYear(), targetDate.getUTCMonth() + direction)));

    return (
        <button onClick={handleClick} type="button" className={className}>
            <Arrow />
        </button>
    );
};