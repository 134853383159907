import styled from 'styled-components';

export const WhoAreYouStl = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;

    .required {
        color: #ff3636;
    }

    > h5 {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
    }
    .error-message {
        color: red;
        font-size: 0.875rem;
        margin-top: 0.5rem;
    }
`;
