import styled from 'styled-components';

export const ArticleFormStyled = styled.div`
    form textarea {
        max-width: none;
    }
    .commentSubmitButton {
        cursor: pointer;
        align-self: flex-end;
        width: 245px;
        padding: 14px 24px;
        border-radius: 8px;
        border: none;
        background: var(--primary-accent, #19be6f);
        color: var(--primary-background, #fff);
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-top: 20px;
    }
    @media (max-width: 600px) {
        .commentSubmitButton {
            width: 100%;
            margin-top: 16px;
        }
    }
`;
