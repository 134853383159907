import styled from 'styled-components';

export const StyledSearchButton = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: opacity 0.5s linear;

    :hover {
        opacity: 0.6;
    }
    @media (max-width: 400px) {
        display: none;
    }
`;
