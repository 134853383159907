import { VisitNoticeStl } from './visitNotice.styled';
import { React } from 'react';
import { Modal } from '../../../share/Modal';
import { Appointment } from '../appointment/Appointment';
import { useSelector } from 'react-redux';
import { OnlineConsultation } from '../onlineConsultation/OnlineConsultation';

export const VisitNoticeModal = ({ handleCancelClick, typeOfNotice, isAuth, closeModal, doctor }) => {
    const consultation = useSelector((state) => state.doctors.consultation);
    const { whoAreYou } = useSelector((state) => state.authentification);
    if (!isAuth || whoAreYou === 'doctor') {
        return (
            <Modal toggle={closeModal}>
                <Appointment />
            </Modal>
        );
    } else if (typeOfNotice === 'online') {
        return (
            <Modal toggle={closeModal}>
                <OnlineConsultation consultation={consultation} doctor={doctor} closeModal={closeModal}/>
            </Modal>
        );
    } else if (typeOfNotice === 'clinic' || typeOfNotice === 'home') {
        const getNoticeText = () => {
            if (typeOfNotice === 'clinic') {
                return (
                    <p className="application__text">
                        Оператор свяжется с вами в ближайшее время для уточнения времени посещения клиники
                    </p>
                );
            } else if (typeOfNotice === 'home') {
                return (
                    <p className="application__text">
                        Оператор свяжется с вами в ближайшее время для уточнения деталей вызова врача на дом
                    </p>
                );
            }
        };
        return (
            <Modal toggle={closeModal}>
                <VisitNoticeStl>
                    <div className="application">
                        <div className="application__icon">
                            <img src="/img/V_telemedicine_patient.svg" alt="header logo" />
                        </div>
                        <h2 className="application__title">Заявка отправлена</h2>
                        {getNoticeText()}
                        <button onClick={handleCancelClick} className="application__button">
                            Отменить
                        </button>
                    </div>
                </VisitNoticeStl>
            </Modal>
        );
    }
};
