import styled from "styled-components";

export const StyledProblemForm = styled.div`
    .consultationForm {
        display: flex;
        flex-direction: column;
    }

    .consultationFormHeader {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #212121;
    }

    .consultationFormInput {
        /* width: 90%; */
        height: 140px;
        margin: 20px 0 0;
        padding: 10px 10px 30px 10px;
        resize: none;
        border: 1px solid #b6b7ba;
        border-radius: 10px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .consultationFormTextError {
        min-height: 16px;
         color: #FF3636;
        font-family: Rubik;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
    }
    
    .consultationFormButtonComment {
        margin: 8px 0 16px;
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #666666;
    }
    button {
        min-width: 206px;
        max-height: 42px;
        padding: 10px 20px;
        background: #ffffff;
        border: 2px solid #19be6f;
        border-radius: 6px;
        color: #19be6f;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
    
    .consultationFormButtonSign {
        width: min-content;
        text-align: left;
        margin: 0;
        padding: 0;
        border: none;
        background: #ffffff;
        color: #19be6f;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        &:hover {
            opacity: 0.7;
        }
        &:disabled {
            cursor: default;
            opacity: 1;
            color: #B6B7BA;
        }
    }

    .consultationProblemText {
        margin: 20px 0;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        color: #666666;
    }

`;