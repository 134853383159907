import { AuthMe, checkEmail, checkEmailCode, registerUser, loginUser, meUser, deleteUser } from './authentificationApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetDoctorConsultations } from '../../lkDoctor/api/lkDoctorConsultationsSlice';
import { resetPatientConsultations } from '../../lkPatient/api/PatientConsultationsSlice';

const formatErrorMessage = (error) => {
    if (typeof error === 'object' && error !== null) {
        return Object.entries(error).reduce((acc, [key, value]) => {
            if (Array.isArray(value) && value.length > 0) {
                acc[key] = value[0];
            }
            if (typeof value === 'string' && value.length > 0) {
                acc[key] = value;
            }
            return acc;
        }, {});
    }
    return {};
};

// актуальные экшены
/**
 * Асинхронное действие для выполнения логина пользователя с последующим получением данных пользователя
 * После успешного логина сохраняет токен доступа в `sessionStorage` и токен обновления в `localStorage`.
 * @function fetchLogin
 * @async
 * @param {Object} data Данные для входа пользователя.
 * @param {string} data.email Email пользователя.
 * @param {string} data.password Пароль пользователя.
 * @returns {Promise<Object>} Ответ от сервера. В случае успеха возвращает объект с токеном, в случае ошибки - ошибку.
 */
export const fetchLogin = createAsyncThunk(
    'authentification/fetchLogin',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const response = await loginUser(data);
            if (response.data.access) {
                sessionStorage.setItem('token', response.data.access);
                localStorage.setItem('refresh', response.data.refresh);
            }

            if (response.status === 200) {
                // После успешного логина загружаем данные пользователя
                dispatch(fetchMeUser());
                return response;
            }
        } catch (error) {
            console.log('error in login thunk', error);
            return rejectWithValue(error);
        }
    }
);

/**
 * Асинхронное действие для регистрации нового пользователя.
 * После успешной регистрации автоматически вызывает логин для нового пользователя.
 * @function fetchRegister
 * @async
 * @param {Object} data Данные пользователя для регистрации.
 * @param {string} data.username Имя пользователя.
 * @param {string} data.email Email пользователя.
 * @param {string} data.role Роль пользователя: пациент или доктор
 * @param {string} data.password Пароль пользователя.
 * @param {function} dispatch Функция диспетчера для вызова других действий.
 * @returns {Promise<Object>} Ответ от сервера. В случае успеха возвращает данные о зарегистрированном пользователе, в случае ошибки - ошибку.
 */
export const fetchRegister = createAsyncThunk(
    'authentification/fetchRegister',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const response = await registerUser(data);
            if (response.status === 201) {
                // После успешной регистрации вызываем логин
                const loginData = {
                    email: data.email,
                    password: data.password,
                };
                dispatch(fetchLogin(loginData));
            }
            return response;
        } catch (error) {
            console.error('Registration error', error);
            return rejectWithValue(error);
        }
    }
);

/**
 * Асинхронное действие для выполнения логаута пользователя.
 * Очищает данные в `localStorage` и `sessionStorage`, а также сбрасывает состояние консультаций для доктора и пациента.
 * @function fetchLogout
 * @async
 * @param {function} dispatch Функция диспетчера для вызова других действий.
 * @returns {Promise<void>} Ничего не возвращает, просто выполняет сброс состояния.
 */
export const fetchLogout = createAsyncThunk('authentification/fetchLogout', async (_, { dispatch }) => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(resetDoctorConsultations());
    dispatch(resetPatientConsultations());
});

/**
 * Асинхронное действие для получения информации о текущем пользователе.
 * Выполняет запрос к серверу для получения данных о пользователе, который уже прошёл аутентификацию.
 * @function fetchMeUser
 * @async
 * @returns {Promise<Object>} Ответ от сервера с информацией о текущем пользователе. В случае успеха возвращает данные пользователя, в случае ошибки - ошибку.
 */
export const fetchMeUser = createAsyncThunk('authentification/fetchMeUser', async () => {
    const response = await meUser();
    return response;
});

/**
 * Асинхронное действие для создания и редактирования профиля пользователя.
 * @function fetchDataUpdate
 * @async
 * @param {Object} data - Данные для обновления профиля пользователя.
 * @returns {Promise<Object>} Обновлённые данные профиля пользователя.
 */
export const fetchDataUpdate = createAsyncThunk('authentification/userUpdate', async (data) => {
    const response = await dataUpdate(data);
    return response;
});

/**
 * Асинхронное действие для удаления пользователя и выхода из системы.
 * Удаляет пользователя по ID и выходит из системы.
 * @function fetchDeleteUser
 * @async
 * @param {string|number} id - Идентификатор пользователя, которого нужно удалить.
 * @param {Object} thunkAPI - Объект, предоставляемый `createAsyncThunk`, содержащий методы для управления действиями.
 * @param {Function} thunkAPI.dispatch - Функция для отправки действий Redux.
 * @returns {Promise<Object>} Ответ от API после удаления пользователя.
 */
export const fetchDeleteUser = createAsyncThunk('authentification/deleteUser', async (id, { dispatch }) => {
    const response = await deleteUser(id);
    dispatch(fetchLogout());
    return response;
});

//----------------------------------------------------------
// старое, не будет использоваться
export const fetchAuthentification = createAsyncThunk('authentification/fetchAuthentification', async (phone) => {
    localStorage.clear();
    sessionStorage.clear();
    return await AuthMe(phone);
});

export const fetchCheckPhone = createAsyncThunk('authentification/fetchCheckPhone', async (data) => {
    try {
        const response = await loginUser(data);
        if (response.data.access) {
            sessionStorage.setItem('token', response.data.access);
            localStorage.setItem('refresh', response.data.refresh);
        }
        return response;
    } catch (e) {
        console.log('ошибка checkPhone', e);
        return e;
    }
});

export const fetchMCheckEmail = createAsyncThunk('authentification/fetchMCheckinEmail', async (email) => {
    try {
        const response = await checkEmail(email);
        return response;
    } catch (e) {
        console.log('ошибка checkEmail', e);
        return e;
    }
});

export const fetchMCheckEmailCode = createAsyncThunk('authentification/fetchMCheckEmailCode', async (data) => {
    try {
        const response = await checkEmailCode(data);
        return response;
    } catch (e) {
        console.log('ошибка checkEmailCode', e);
        return e;
    }
});

//--------------------------------------------------

/**
 * Изначальное состояние слайса аутентификации.
 * @typedef {Object} InitialState
 * @property {Object} user Данные о текущем пользователе.
 * @property {number|null} user.id Идентификатор пользователя.
 * @property {string|null} user.username Имя пользователя.
 * @property {string|null} user.role Роль пользователя (пациент или доктор).
 * @property {string|null} user.first_name Имя пользователя.
 * @property {string|null} user.last_name Фамилия пользователя.
 * @property {string|null} user.middle_name Отчество пользователя.
 * @property {string|null} user.date_birth Дата рождения пользователя.
 * @property {string|null} user.sex Пол пользователя.
 * @property {string|null} user.email Email пользователя.
 * @property {string|null} user.phone Телефон пользователя.
 * @property {string|null} user.city Город пользователя (пока данные не приходят).
 * @property {string|null} user.avatar Аватар пользователя (пока данные не приходят).
 * @property {boolean} isEmail Флаг, указывающий, что email будет использоваться для аутентификации.
 * @property {boolean} isEmailCheck Флаг проверки email (например, для верификации email).
 * @property {boolean} isPreloader Флаг для отображения прелоадера (загрузки).
 * @property {boolean} isAuth Флаг, указывающий на авторизованного пользователя.
 * @property {string} whoAreYou Роль пользователя (пациент или доктор), выбранная на этапе регистрации.
 * @property {Object} messagesError Сообщения об ошибках (например, при аутентификации или регистрации).
 */
const initialState = {
    user: {
        id: null,
        username: null,
        role: null,
        first_name: null,
        last_name: null,
        middle_name: null,
        date_birth: null,
        sex: null,
        email: null,
        phone: null,
        city: null, // пока не приходит ниоткуда
        avatar: null, // пока не приходит ниоткуда
    },
    // id: null,
    // phone_number: '',
    // email: '',
    isEmail: true, // используем в хэдере см header.jsx
    isEmailCheck: false, // используем в хэдере см header.jsx
    isPreloader: false,
    isAuth: false, // используем в хэдере см header.jsx и в Authentication.jsx
    whoAreYou: '', //используется в компоненте whoAreYou.jsx при выборе радио-buttons
    messagesError: {},
};

/**
 * Слайс состояния для управления аутентификацией.
 * @constant
 * @type {Slice}
 */
const authentification = createSlice({
    name: 'authentification',
    initialState,
    reducers: {
        /**
         * Устанавливает флаг авторизации.
         * @function setAuth
         * @param {boolean} bool Статус авторизации.
         */
        setAuth: (state, bool) => {
            // используем для проверки авторизации и токена (см header.jsx)
            state.isAuth = bool.payload;
        },
        /**
         * Устанавливает роль пользователя.
         * @function setWhoAreYou
         * @param {string} role Роль пользователя (пациент или доктор).
         */
        setWhoAreYou(state, role) {
            // используется при регистрации
            state.whoAreYou = role.payload;
        },
        /**
         * Устанавливает email пользователя.
         * @function setEmailSlice
         * @param {string} email Email пользователя.
         */
        setEmailSlice(state, action) {
            // используется при логине
            state.user.email = action.payload;
        },
        /**
         * Очищает сообщения об ошибках для указанного поля.
         * @function clearMessages
         * @param {string} field Имя поля, ошибки которого нужно очистить.
         */
        clearMessage(state, action) {
            const field = action.payload;
            if (state.messagesError[field]) {
                delete state.messagesError[field];
            }
        },

        clearAllMessages(state) {
            state.messagesError = {};
        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetchRegister.pending, (state, action) => {
            state.isPreloader = true;
        });
        builder.addCase(fetchRegister.fulfilled, (state, action) => {
            if (action.payload.status !== 201) {
                state.messagesError = formatErrorMessage(action.payload.data);
            } else {
                state.user = action.payload;
            }
            // state.isPreloader = false;
        });
        builder.addCase(fetchRegister.rejected, (state, action) => {
            state.messagesError = formatErrorMessage(action.payload.data);
            state.isPreloader = false;
        });
        builder.addCase(fetchLogin.pending, (state) => {
            state.isPreloader = true;
        });
        builder.addCase(fetchLogin.fulfilled, (state) => {
            state.isAuth = true;
            state.isEmail = true;
            state.isPreloader = false;
        });
        builder.addCase(fetchLogin.rejected, (state, action) => {
            state.messagesError = formatErrorMessage(action.payload.data);
            state.isPreloader = false;
        });
        builder.addCase(fetchMeUser.pending, (state, action) => {
            // state.isPreloader = true;
        });
        builder.addCase(fetchMeUser.fulfilled, (state, action) => {
            state.user = action.payload;
        });
        // на случай если токен не валидный разлогиниваем юзера
        // сделано тк с бека для не активных пользователей токен приходит не валидный
        // бек починили, оствленно на всякий случай
        builder.addCase(fetchMeUser.rejected, (state, action) => {
            console.log(action);
            state.user.id = null;
            state.user.username = null;
            state.user.role = null;
            state.user.first_name = null;
            state.user.last_name = null;
            state.user.middle_name = null;
            state.user.date_birth = null;
            state.user.sex = null;
            state.user.email = null;
            state.user.phone = null;
            state.user.city = null; // пока не приходит ниоткуда
            state.user.avatar = null; // пока не приходит ниоткуда
        });

        builder.addCase(fetchDataUpdate.pending, (state, action) => {
            // state.isPreloader = true;
        });
        builder.addCase(fetchDataUpdate.fulfilled, (state, action) => {
            state.user = action.payload;
        });
        builder.addCase(fetchDataUpdate.rejected, (state, action) => {
            console.log(action);
        });

        builder.addCase(fetchDeleteUser.pending, (state, action) => {
            state.isPreloader = true;
        });
        builder.addCase(fetchDeleteUser.fulfilled, (state, action) => {
            state.user.id = null;
            state.user.username = null;
            state.user.role = null;
            state.user.first_name = null;
            state.user.last_name = null;
            state.user.middle_name = null;
            state.user.date_birth = null;
            state.user.sex = null;
            state.user.email = null;
            state.user.phone = null;
            state.user.city = null; // пока не приходит ниоткуда
            state.user.avatar = null; // пока не приходит ниоткуда
            state.isEmail = true;
            state.isEmailCheck = false;
            state.isPreloader = false;
            state.messagesError = {};
            state.isAuth = false;
            state.whoAreYou = '';
        });
        builder.addCase(fetchDeleteUser.rejected, (state, action) => {
            console.log('deletting rejected');
        });
        // использовалось раньше при логине через № телефона
        // builder.addCase(fetchAuthentification.fulfilled, (state, action) => {
        //     if (action.payload.status !== 201) {
        //         state.messagesError = action.payload.data.code;
        //         state.headingError = action.payload.data.heading;
        //     } else {
        //         state.phone_number = action.payload.data.phone_number;
        //         state.messagePageCode = action.payload.data['message '];
        //     }
        //     state.isPreloader = false;
        // });

        // builder.addCase(fetchAuthentification.rejected, (state, action) => {
        //     console.log(action);
        // });
        // builder.addCase(fetchCheckPhone.fulfilled, (state, action) => {
        //     if (action.payload.status !== 200) {
        //         state.messagesError = action.payload.data.code;
        //         state.headingError = action.payload.data.heading;
        //     } else {
        //         state.isEmail = false;
        //     }
        // });
        // builder.addCase(fetchCheckPhone.rejected, (state, action) => {
        //     console.log(action);
        // });
        // builder.addCase(fetchMCheckEmail.fulfilled, (state, action) => {
        //     if (action.payload.status !== 201) {
        //         state.messagesError = action.payload.data.email[0];
        //         state.headingError = action.payload.data.heading;
        //     } else {
        //         state.isEmail = true;
        //         state.isEmailCheck = true;
        //         state.messagePageCode = action.payload.data['detail'];
        //     }
        // });
        // builder.addCase(fetchMCheckEmail.rejected, (state, action) => {
        //     console.log(action);
        // });
        // builder.addCase(fetchMCheckEmailCode.fulfilled, (state, action) => {
        //     if (action.payload.status !== 200) {
        //         state.messagesError = action.payload.data?.code_email[0];
        //         state.headingError = action.payload.data.heading;
        //     } else {
        //         state.isAuth = true;
        //         state.isEmail = true;
        //     }
        // });
        // builder.addCase(fetchMCheckEmailCode.rejected, (state, action) => {
        //     console.log(action);
        // });
        builder.addCase(fetchLogout.fulfilled, (state) => {
            state.user.id = null;
            state.user.username = null;
            state.user.role = null;
            state.user.first_name = null;
            state.user.last_name = null;
            state.user.middle_name = null;
            state.user.date_birth = null;
            state.user.sex = null;
            state.user.email = null;
            state.user.phone = null;
            state.user.city = null; // пока не приходит ниоткуда
            state.user.avatar = null; // пока не приходит ниоткуда
            state.isEmail = true;
            state.isEmailCheck = false;
            state.isPreloader = false;
            state.messagesError = {};
            state.isAuth = false;
            state.whoAreYou = '';
        });
    },
});

export const { setAuth, clearError, setEmailSlice, setWhoAreYou, clearPhone, clearMessage, clearAllMessages } =
    authentification.actions;
export default authentification.reducer;
