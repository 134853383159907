import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flex from '../../../share/Flex';
import { initialFilterMonth, initialFilterYear, monthsOfYear, years } from '../../../share/helpers';
import { clearTransactions } from '../../api/doctorWalletSlice';
import { FiltersStl, SelectStl, SelectWrapperStl } from './transaction.styled';
import Select from '../../../share/formFields/newElements/Select';
import { FormProvider, useForm } from 'react-hook-form';

export const Filters = ({ year, month, setMonth, setYear }) => {
    const dispatch = useDispatch();
    const yearRef = useRef();
    const monthRef = useRef();
    const allDate = 'Все';
    const [isChoosenYear, setIsChoosenYear] = useState(false);
    const yearFilterOptions = years.map((year) => ({ label: year, value: year }));
    const monthFilterOptions = monthsOfYear.map((month) => ({ label: month.name, value: month.code }));
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            filterMonth: '',
            filterYear: ''
        }
    });
    const selectStyles = {
        showDropdownIndicator: true,
    }

    const { name: nameYearSelect } = methods.register('filterYear');
    const { name: nameMonthSelect } = methods.register('filterMonth');

    useEffect(() => {
        if (year !== initialFilterYear) {
            setIsChoosenYear(true);
        } else {
            setIsChoosenYear(false);
            setMonth(initialFilterMonth);
        }
    }, [year]);

    const handleButtonClick = () => {
        dispatch(clearTransactions());
        setYear(initialFilterYear);
        setMonth(allDate);
        yearRef.current.value = initialFilterYear;
        monthRef.current.value = initialFilterMonth;
    };

    const handleChangeMonth = (e) => {
        dispatch(clearTransactions());
        setMonth(e.target.value);
    };

    const handleChangeYear = (e) => {
        dispatch(clearTransactions());
        setYear(e.target.value);
    };
    const handleYearFilter = (e) => {
        if(e.value !== year) {
            dispatch(clearTransactions());
            setYear(e.value);
        }
    }
 
    const handleMonthFilter = (e) => {
        if(e.value !== month) {
            dispatch(clearTransactions());
            setMonth(e.value);
        }
    }

    const handleReset = () => {
        dispatch(clearTransactions());
        setYear(initialFilterYear);
        setMonth(initialFilterMonth);
        methods.setValue(nameYearSelect, initialFilterYear);
        methods.setValue(nameMonthSelect, initialFilterMonth);
        // yearRef.current.value = initialFilterYear;
        // monthRef.current.value = initialFilterMonth;
    }

    return (
        <FiltersStl selected={year !== allDate}>
            <div className="filters__top">
                <p className="select__title">Сортировать</p>
                <button
                    className="reset"
                    // onClick={handleButtonClick}
                    onClick={handleReset}
                    disabled={!isChoosenYear}
                    style={!isChoosenYear ? { color: '#B6B7BA' } : {}}
                >
                    Сбросить
                </button>
            </div>
            <Flex className="filter-container">
                {/* <SelectWrapperStl marginTop="37px">
                    <div className="select-wrapper">
                        <SelectStl disabled={!isChoosenYear} ref={monthRef} onChange={handleChangeMonth}>
                            <option className="select__items" key={allDate} value={initialFilterMonth}>
                                {initialFilterMonth}
                            </option>
                            <option className="select__items" key={allDate} value={initialFilterMonth}>
                                Все
                            </option>
                            {monthsOfYear.map((el) => (
                                <option className="select__items" key={el.code} value={el.code}>
                                    {el.name}
                                </option>
                            ))}
                        </SelectStl>
                    </div>
                </SelectWrapperStl> */}
                {/* <SelectWrapperStl>
                    <div className="select-wrapper">
                        <SelectStl ref={yearRef} onChange={handleChangeYear}>
                            <option className="select__items" key={allDate} value={initialFilterYear}>
                                {initialFilterYear}
                            </option>
                            {years.map((el) => (
                                <option className="select__items" key={el} value={el}>
                                    {el}
                                </option>
                            ))}
                        </SelectStl>
                    </div>
                </SelectWrapperStl> */}
                <FormProvider {...methods}>
                        <Select 
                            name="filterMonth"
                            placeholder="месяц"
                            options={monthFilterOptions}
                            validation={{ required: false, validate: false }}
                            style={selectStyles}
                            isClearable={false}
                            isDisabled={!isChoosenYear}
                            onChange={handleMonthFilter}
                        />
                        <Select
                            name="filterYear"
                            placeholder="год"
                            options={yearFilterOptions}
                            validation={{ required: false, validate: false }}
                            style={selectStyles}
                            isClearable={false}
                            onChange={handleYearFilter}
                        />
                </FormProvider>
            </Flex>
        </FiltersStl>
    );
};
