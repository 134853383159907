import styled from 'styled-components';

export const StyledVideoChatPage = styled.section`
    .videoChatPage__pagination {
        margin-top: 24px;
        margin-bottom: 41px;
    }
    .videoChatWindow__wrapper {
        max-width: 1040px;
        margin: 0 auto;
        padding: 0 15px;

        @media (max-width: 450px) {
            padding: 0;
        }
    }
`;
