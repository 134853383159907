import styled from 'styled-components';

export const DoctorReviewsStl = styled.div`
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .reviews__cards {
        display: flex;
        flex-direction: column;
        gap: 35px;
    }
    .reviews__buttons-container {
        display: flex;
        justify-content: flex-end;
        gap: 42px;
    }
    .reviews__btn {
        color: #19BE6F;
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        background-color: #ffffff;
        border: none;
        transition: opacity 0.5s linear;
        cursor: pointer;
        padding: 12px;
        &:hover {
            opacity: 0.7;
        }
        @media (max-width: 350px) {
            font-size: 14px;
        }
    }
`;
