import { MainMenu } from '../../share/mainMenu/mainMenu';
import React from 'react';
import { StyledLkPatientNavigation } from './lkPatientNavigation.styled';

const naviData = [
    {
        key: 1,
        title: 'Профиль',
        linkPathTo: '/lk/patient-profile/profile',
    },
    {
        key: 2,
        title: 'Мои консультации',
        linkPathTo: '/lk/patient-profile/consultation',
        children: [
            {
                key: 2.1,
                title: 'Запланированные',
                linkPathTo: '/lk/patient-profile/consultation/future-consultation',
            },
            {
                key: 2.2,
                title: 'Прошедшие',
                linkPathTo: '/lk/patient-profile/consultation/past-consultation',
            },
        ],
    },
    {
        key: 3,
        title: 'Настройки',
        linkPathTo: '/lk/patient-profile/settings',
    },
];

export const LkPatientNavigation = () => {
    return (
        <StyledLkPatientNavigation>
            <MainMenu naviData={naviData} />
        </StyledLkPatientNavigation>
    );
};
