import React from 'react';
import { ScheduleSettingsStl } from './scheduleSettings.styled';
import { useSchedule } from '../../calendarComponents/helpers/useSchedule';
import { setClassNameTime } from '../../calendarComponents/helpers/setClassName';

export const ScheduleSettings = ({ choosenDates, setSelectedTime, isInterval }) => {
    const { handleSelect, times } = useSchedule(choosenDates, setSelectedTime, isInterval);

    const timesShow = times.map((el, index) => {
        const { timeString } = el;
        const classes = setClassNameTime(el);
        return (
            <div key={timeString} className={classes} onClick={() => handleSelect(el, index)}>
                {timeString}
            </div>
        );
    });

    
    return <ScheduleSettingsStl>{timesShow}</ScheduleSettingsStl>;
};
