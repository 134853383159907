import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPosts, getThemes, postComments, getArticlePage, getQuotes } from './api';

export const fetchThemes = createAsyncThunk('blog/fetchThemes', async () => {
    try {
        const response = await getThemes();
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const fetchPostsByTheme = createAsyncThunk('blog/fetchPostsByTheme', async (data, { getState }) => {
    try {
        const next = getState().blog.posts[data?.themeId]?.next;
        const response = await getPosts(data, next);
        return { ...response.data, data };
    } catch (e) {
        console.log(e);
    }
});

export const fetchArticlePage = createAsyncThunk('blog/fetchArticlePage', async (articleId, { getState }) => {
    try {
        const next = getState().blog.articles.find((article) => article.id == articleId)?.next;
        const response = await getArticlePage(articleId, next);
        return { response, articleId };
    } catch (e) {
        console.log(e);
    }
});
export const postArticleComments = createAsyncThunk('blog/postComments', async (data) => {
    try {
        const response = await postComments(data);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const fetchQuotes = createAsyncThunk('blog/fetchQuotes', async () => {
    try {
        const response = await getQuotes();
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

const initialState = {
    themes: [],
    posts: {},
    articles: [],
    quotes: [],
    isLoading: null,
    isError: null,
};

const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        updatePosts(state, action) {
            state.posts[action.payload]?.postsByTheme?.forEach((post, index) => {
                if ((index + 1) % 2 === 0 && !post?.quote) {
                    const randomId = Math.floor(Math.random() * state.quotes.length);
                    post.quote = { ...state.quotes[randomId] };
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchThemes.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(fetchThemes.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
        builder.addCase(fetchThemes.fulfilled, (state, action) => {
            const { results } = action.payload;
            const themes = results?.sort((a, b) => a.id - b.id);
            state.themes = themes;

            state.isLoading = false;
        });
        builder.addCase(fetchPostsByTheme.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(fetchPostsByTheme.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
        builder.addCase(fetchPostsByTheme.fulfilled, (state, action) => {
            const { data, results, next } = action.payload;
            const themeOfPosts = data?.themeId;

            // рендер постов на главной странице (homepage)
            if (!themeOfPosts) {
                state.posts['postsOnHomePage'] = { next: next, postsByTheme: [...results.slice(0, 3)] };
            }
            // рендер постов при изменении темы постов
            if (!state.posts[themeOfPosts] && themeOfPosts) {
                state.posts[themeOfPosts] = { next: next, postsByTheme: [...results] };
            }
            // рендер постов пари нажатии на кнопку "показать больше"
            if (data.getMorePosts) {
                state.posts[themeOfPosts] = {
                    next: next,
                    postsByTheme: [...state.posts[themeOfPosts]['postsByTheme'], ...results],
                };
            }

            state.isLoading = false;
        });
        builder.addCase(fetchArticlePage.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(fetchArticlePage.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
        builder.addCase(fetchArticlePage.fulfilled, (state, action) => {
            const {
                response: { article, comments },
                articleId,
            } = action.payload;

            if (article) {
                // Инициализация доп. полей при первой загрузке
                article.data.comments = comments.data.results;
                article.data.next = comments.data.next;
                state.articles.push(article.data);
            } else {
                // Добавление комментариев к статье
                const currentArticle = state.articles.find((article) => article.id == articleId);
                currentArticle.next = comments.data.next;
                currentArticle.comments.push(...comments.data.results);
            }

            state.isLoading = false;
        });
        builder.addCase(fetchQuotes.fulfilled, (state, action) => {
            const regex = /^(.+)\s+\(([^)]+)\)$/;

            action.payload.value.split('!b').forEach((quote) => {
                const match = quote.match(regex);
                if (match) {
                    state.quotes.push({ text: match['1'], author: match['2'] });
                }
            });
        });
    },
});
export const { updatePosts } = blogSlice.actions;
export default blogSlice.reducer;
