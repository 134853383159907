import styled from 'styled-components';

export const PatientMedicalData = styled.ul`
    margin-top: 35px;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .patient__medicalDataPoint {
        display: grid;
        grid-template-columns: 150px 1fr;
        gap: 25px;
    }
    .patient__medicalDataHeading {
        color: #666;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .patient__medicalDataSublistFirst {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }
    .patient__medicalDataSublist {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .patient__medicalDataSubtitle {
        color: #666;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .patient__medicalDataText {
        overflow: hidden;
        color: #212121;
        text-overflow: ellipsis;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        p{
            word-break: break-word;
        }
    }

    @media (max-width: 500px) {
        .patient__medicalDataPoint {
            grid-template-columns: 120px 1fr;
        }
        .patient__medicalDataHeading {
            font-size: 16px;
            line-height: 130%;
        }
        .patient__medicalDataSubtitle {
            font-size: 12px;
            line-height: 130%;
        }
        .patient__medicalDataText {
            font-size: 16px;
            line-height: 130%;
        }
    }
`;
