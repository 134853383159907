import React from 'react';
import { StepButton } from './stepButton/StepButton';
import { CalendarHeaderStl } from './calendarHeader.styled';

export const CalendarHeader = ({ choosenDate, setChoosenDate }) => {
    return (
        <CalendarHeaderStl>
            <StepButton
                direction={-1}
                setTargetDate={setChoosenDate}
                className="sdh__button sdh__button_turn_left"
                targetDate={choosenDate}
            />
            <div className="sdh__short-date">
                <div className="sdh__month">{choosenDate.toLocaleDateString('ru-RU', { month: 'long' })}</div>
                <div className="sdh__year">{choosenDate.getUTCFullYear()}</div>
            </div>
            <StepButton direction={1} setTargetDate={setChoosenDate} className="sdh__button" targetDate={choosenDate} />
        </CalendarHeaderStl>
    );
};
