import React, { useEffect } from 'react';
import { ViewDoctorStl } from './viewDoctor.styled';
import { DoctorData } from './doctorData/DoctorData';
import { DoctorEducation } from './doctorEducation/DoctorEducation';
import { DoctorAdvancedTraining } from './doctorAdvancedTraining/DoctorAdvancedTraining';
import { DoctorPrice } from './doctorPrice/doctorPrice';
import { DoctorWork } from './doctorWork/DoctorWork';
import { DoctorSpecialization } from './doctorSpecialization/DoctorSpecialization';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMeDoctor } from '../../api/doctorUserProfileSlice';
import { DoctorDeseases } from './doctorDeseases/DoctorDeseases';
import { Loader } from '../../../loader/loader';

export const ViewDoctor = () => {
    const dispatch = useDispatch();
    const isLoaderProfile = useSelector((state) => state.doctorProfile.isPreloader);

    useEffect(() => {
        dispatch(fetchMeDoctor());
    }, []);

    if (isLoaderProfile) {
        return <Loader />;
    }

    return (
        <ViewDoctorStl>
            {/* <DoctorData /> */}
            {/* <DoctorEducation /> */}
            {/* <DoctorAdvancedTraining /> */}
            {/* <DoctorWork /> */}
            {/* <DoctorSpecialization /> */}
            {/* <DoctorDeseases /> */}
            {/* <DoctorPrice /> */}
        </ViewDoctorStl>
    );
};
