import React from 'react';
import { FieldWrapper } from './FieldWrapper';
import { useController, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { clearMessage } from '../../authentification/api/authentificationSlice';

/**
 * Компонент InputText — текстовое поле для ввода с валидацией.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля для формы.
 * @param {string} props.label - Лэйбл поля (текст, который отображается рядом с полем).
 * @param {string} props.placeholder - Текст подсказки, который отображается в поле до ввода данных.
 * @param {string} [props.defaultValue=''] - Значение поля по умолчанию.
 * @param {Object} [props.validation={}] - Дополнительные правила валидации.
 * @param {Function} [props.onChange=() => {}] - Функция, которая вызывается при изменении значения поля.
 * @param {Object} [props.messagesError] - Сообщения об ошибках, которые могут быть переданы для отображения.
 *
 * @returns {JSX.Element} Возвращает компонент текстового поля с валидацией.
 */
const InputText = ({
    name,
    isRequired,
    label,
    placeholder,
    defaultValue = '',
    validation = {},
    onChange = () => {},
    messagesError,
}) => {
    const dispatch = useDispatch();
    const { control, trigger, setValue } = useFormContext();

    const defaultValidation = {
        required: 'Поле обязательно для заполнения',
        pattern: {
            value: /^[\p{L}]*$/u,
            message: 'Имя может содержать только символы алфавита',
        },
        maxLength: {
            value: 30,
            message: 'Максимальная длина: 30 символов',
        },
        ...validation,
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, defaultValue, rules: defaultValidation });

    const clearedView = '';

    const handleKeyDown = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;

        if (messagesError) {
            dispatch(clearMessage(e.target.name));
        }

        setValue(e.target.name, value);
        trigger(e.target.name);
    };

    const handleClear = () => {
        field.onChange(clearedView);
        onChange(clearedView);
        trigger(field.name);
        dispatch(clearMessage(messagesError));
    };

    return (
        <FieldWrapper
            error={error}
            messagesError={messagesError}
            label={label}
            clearCondition={field.value !== clearedView}
            isRequired={isRequired}
            onClear={handleClear}
        >
            <input
                {...field}
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                id={name}
                type="text"
                onChange={(e) => handleInputChange(e)}
                onKeyDown={handleKeyDown}
            />
        </FieldWrapper>
    );
};

export default InputText;
