import { fetchCity, resetCity } from '../../api/PatientProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    validateBirthdayInput,
    validateInputEmail,
    validateInputTextName,
    validateRequired,
} from '../../../share/formFields/validateInput';

import InputDate from '../../../share/formFields/newElements/InputDate';
import InputEmail from '../../../share/formFields/newElements/InputEmail';
import InputTel from '../../../share/formFields/newElements/InputTel';
import InputText from '../../../share/formFields/newElements/InputText';
import RadioGroup from '../../../share/formFields/newElements/RadioGroup';
import React from 'react';
import SelectCity from '../../../share/formFields/newElements/selectCity/SelectCity';

export const EditDataList = ({ user }) => {
    const { city, isPreloaderCity } = useSelector((state) => state.patientProfile);
    // const cityPatient = useSelector((state) => state.patientProfile?.patientProfile.user.city);
    const dispatch = useDispatch();

    const handleOnChange = (searchString) => {
        {
            searchString?.length >= 2 ? dispatch(fetchCity(searchString)) : dispatch(resetCity());
        }
    };
    return (
        <>
            <InputText
                name="user.last_name"
                label="Фамилия"
                placeholder="Иванов"
                validation={validateInputTextName(true)}
                isRequired={true}
                isFullName={true}
            />
            <InputText
                name="user.first_name"
                label="Имя"
                placeholder="Иван"
                validation={validateInputTextName(true)}
                isRequired={true}
                isFullName={true}
            />
            <InputText
                name="user.patronymic"
                label="Отчество"
                placeholder="Иванович"
                validation={validateInputTextName(false)}
                isFullName={true}
            />
            <div className="work__date">
                <InputDate
                    name="user.birthday"
                    label="Дата рождения"
                    placeholder="ДД.ММ.ГГГГ"
                    defaultValue="99.99.9999"
                    validation={validateBirthdayInput(true)}
                    isRequired={true}
                />
            </div>
            <RadioGroup defaultChecked={user.gender} name="user.gender" label="Пол" options={['Мужской', 'Женский']} />
            <InputTel
                validation={{ pattern: false }}
                // defaultValue="+7 (___) ___-__-__"
                name="user.phone"
                label="Номер телефона"
                placeholder="+7 (999) 999-99-99"
                disabled
                isRequired={true}
            />
            <InputEmail
                defaultValue="Example@gmail.com"
                name="user.email"
                label="Email"
                placeholder="Example@gmail.com"
                validation={{ ...validateRequired(true), ...validateInputEmail }}
                isRequired={true}
                disabled
            />
            <SelectCity
                name="user.city"
                label="Город"
                isCity={city ? true : false}
                placeholder={city ? city : 'Москва'}
                onChange={handleOnChange}
                options={city.map((item) => ({ value: item.name, label: item.name }))}
                isPreloaderCity={isPreloaderCity}
                validation={{}}
                isRequired={false}
            />
        </>
    );
};
