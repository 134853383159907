import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { StyledLkDoctorProfile, StyledUserLogo } from './lkDoctorProfile.styled';
import {
    fetchDeleteMyAvatar,
    fetchLoadMyAvatar,
    fetchMeDoctor,
    fetchSpecializations,
    fetchUniversity,
} from '../api/doctorUserProfileSlice';

import ImageHover from '../../../img/photo_hover.png';
import { Loader } from '../../loader/loader';
import PhotoAlt from '../../../img/photo_alt.png';
import RoundPhoto from '../../share/RoundPhoto';
import { fetchMeUser } from '../../authentification/api/authentificationSlice';
import { uploadFile } from '../../share/fileUploader';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

const LkDoctorProfile = () => {
    const [errorLoadAvatar, setErrorLoadAvatar] = useState('');
    const [localAvatarUrl, setLocalAvatarUrl] = useState(null);

    const dispatch = useDispatch();
    const path = useLocation();
    const redirect = useNavigate();
    const { isPreloader: loader, doctorProfile } = useSelector((state) => state.doctorProfile);
    const refAvatar = useRef();

    useEffect(() => {
        if (errorLoadAvatar) {
            const delayAvatarId = setTimeout(() => setErrorLoadAvatar(''), 5000);
            return () => clearTimeout(delayAvatarId);
        }
    }, [errorLoadAvatar]);

    useEffect(() => {
        dispatch(fetchSpecializations());
        window.scrollTo(0, 0);
    }, [dispatch, path]);

    useEffect(() => {
        dispatch(fetchUniversity());
    }, [dispatch, path]);

    useEffect(() => {
        if (path.pathname === '/lk/doctor-profile/profile') {
            redirect('/lk/doctor-profile/profile/view', { replace: true });
        }
    }, [path]);

    const addPhoto = () => {
        const sendUpResult = async (file) => {
            if (file.size > 2097152) {
                setErrorLoadAvatar('Размер файла должен быть не более 2 МБ');
            } else {
                try {
                    await dispatch(fetchLoadMyAvatar(file)).unwrap();
                    setLocalAvatarUrl(URL.createObjectURL(file));
                } catch (error) {
                    setErrorLoadAvatar('Ошибка при загрузке изображения');
                }
            }
        };
        uploadFile(
            refAvatar,
            {
                multiple: false,
                accept: ['.jpg', '.png', '.jpeg', '.tiff'],
            },
            sendUpResult
        );
    };

    const handleDeleteAvatar = async () => {
        try {
            await dispatch(fetchDeleteMyAvatar()).unwrap();
            setLocalAvatarUrl(null);
        } catch (error) {
            setErrorLoadAvatar('Ошибка при удалении изображения');
        }
    };

    if (!doctorProfile) {
        return <Loader />;
    }

    const avatarUrl = localAvatarUrl || doctorProfile.user?.avatar || PhotoAlt;

    return (
        <StyledLkDoctorProfile>
            <div className="avatar">
                <StyledUserLogo onClick={addPhoto}>
                    <img src={ImageHover} />
                    <RoundPhoto size="156px" addr={avatarUrl} />
                    <input type="file" name="photo" id="loadPhoto" ref={refAvatar} />
                </StyledUserLogo>

                {!errorLoadAvatar && (doctorProfile.user?.avatar || localAvatarUrl) ? (
                    <button className="deleteAvatarButton" onClick={handleDeleteAvatar}>
                        Удалить фото
                    </button>
                ) : null}
                {errorLoadAvatar && <span className="errorLoadAvatar">{errorLoadAvatar}</span>}
            </div>
            <div className="data">
                <Outlet />
            </div>
        </StyledLkDoctorProfile>
    );
};

export default LkDoctorProfile;
