import styled from 'styled-components';

export const ScrollToTopArrowUpStl = styled.div`
    .buttonUp {
        color: #19BE6F;
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        background-color: transparent;
        border: none;
        transition: opacity 0.5s linear;
        cursor: pointer;
        padding: 12px;
        &:hover {
            opacity: 0.7;
        }
        @media (max-width: 350px) {
            font-size: 14px;
        }
    }
`;
