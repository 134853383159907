import styled from 'styled-components';

export const FeedbackValueStl = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 24px;

    > h3 {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        color: #212121;
    }

    > div > img {
        cursor: pointer;
        width: 24px;
        height: 24px;
    }

    > div > button {
        cursor: pointer;
        width: 24px;
        height: 24px;
        border: none;
        background: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .star_container {
        display: flex;
        flex-direction: row;
        column-gap: 15px;
    }

    @media screen and (min-width: 768px) {
        padding-bottom: 30px;
        .star_container {
            column-gap: 5px;
        }
    }
`;
