import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { NewFormSTL } from './newFormSTL.styled';
import ReactQuill from 'react-quill';

const Diagnostic = () => {
    const { register, watch, setValue } = useFormContext();
    useEffect(() => {
        register('diagnostic.text');
    }, [register]);

    const onEditorStateChange = (editorState) => {
        setValue('diagnostic.text', editorState);
    };

    const editorContent = watch('diagnostic.text');

    const modules = {
        toolbar: [[{ header: '2' }], ['bold'], [{ list: 'bullet' }]],
        clipboard: {
            matchVisual: false, // Отключить стандартную обработку вставки
        },
    };
    const formats = ['header', 'list', 'bold'];


    // const handleBlur = () => {
    //     // trigger(field.name);
    //     onEditorStateChange(editorContent)
    // };

    return (
        <NewFormSTL>
            <h2 className="title">Диагностика и лечение заболеваний</h2>
            <ReactQuill
                contentEditable
                value={editorContent}
                onChange={onEditorStateChange}
                // onBlur={handleBlur}
                modules={modules}
                formats={formats}
                style={{ height: '200px' }}
            />
        </NewFormSTL>
    );
};

export default Diagnostic;
