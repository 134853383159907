import styled from 'styled-components';

export const ReviewsFormStyled = styled.div`
    .form {
        display: flex;
        flex-direction: column;
        position: relative;
        label > span {
            font-size: 16px;
            line-height: 150%;
        }
        textarea {
            max-width: none;
        }
        label > p {
            padding-bottom: 0;
            min-height: 0;
        }
        @media (min-width: 768px) {
            width: 100%;
            max-width: 774px;
            margin: 0 auto;
            label > span {
                font-size: 18px;
            }
        }
    }

    .comment {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #666666;
        text-align: right;
        position: absolute;
        right: 0;
        top: 83px;
        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 130%;
            top: 90px;
        }
    }

    .formHeader__symbols_error {
        color: red;
    }

    .rules {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #666666;
        padding-bottom: 24px;
        padding-top: 4px;
        @media screen and (min-width: 768px) {
            font-size: 16px;
        }
    }

    .rules__link {
        color: #19be6f;
        text-decoration: none;
        transition: opacity 0.5s linear;
        cursor: pointer;
    }

    .rules__link:hover {
        opacity: 0.6;
    }

    .reviewBtn {
        padding: 16px 32px;
        cursor: pointer;
        width: 100%;
        border: none;
        border-radius: 8px;
        background: #19be6f;
        color: #ffffff;
        font-family: 'Rubik';
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        &:hover {
            opacity: 0.6;
        }
        &:disabled {
            opacity: 0.6;
            cursor: auto;
        }
        @media (min-width: 400px) {
            width: 245px;
        }
    }
`;
