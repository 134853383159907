import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../../consts';
import { useHttp } from '../../hooks/http.hook';
import { getAllDoctors } from '../lkPatient/api/patientApi';


const initialState = {
    doctors: [],
    doctorsForHomepage: [],
    doctorsFHPsLoadingStatus: 'start',
    doctorsLoadingStatus: 'start',
    doctorsForTopCarouselLoadingStatus: 'start',
    doctorSpecialization: [],
    pageNumberPaginate: 1,
    sortType: [],
    category: [],
    degree: [],
    gender: [],
    seniority: 0,
    offset: 0,
    doctorId: '',
    newDoctors: 0,
    doctorsCount: 0, //общее количество докторов в респонзе для расчета количества кнопок пагинации
    // numberOfPaginateButton: 1,
    doctorsForTopCarousel: [],
};

// const _transformDoctors = (doctors) => {
//     return {
//         id: doctors.id,
//         avatar: doctors.avatar,
//         name: doctors.first_name,
//         lastname: doctors.last_name,
//         patronymic: doctors.patronymic,
//         // rating: doctors.ratings[0].rating.toFixed(1),
//         // rating: doctors.ratings[0] ? doctors.ratings[0].rating: 'Нет оценок',
//         rating: doctors.ratings[0] ? doctors.ratings[0].value : 0,

//         specialization: doctors.specialization,

//         // category: doctors.volumeInfo.categories ? doctors.volumeInfo.categories[0] : null,
//         // authors: doctors.volumeInfo.authors ? doctors.volumeInfo.authors.toString() : null,
//         // thumbnail: doctors.volumeInfo.imageLinks ? doctors.volumeInfo.imageLinks.thumbnail : 'https://books.google.ru/googlebooks/images/no_cover_thumb.gif',
//         // description: doctors.volumeInfo.description,
//         // categories : doctors.volumeInfo.categories ? doctors.volumeInfo.categories : null,
//     };
// };

export const fetchDoctors = createAsyncThunk(
    'doctors/fetchDoctors',
    async ([
        pageNumberPaginate,
        doctorSpecialization,
        category = [],
        degree = [],
        gender = [],
        seniority = [],
        size = 6,
    ]) => {
        const { request } = useHttp();
        // return await request(`${_apiBase}${page}&page_size=${size}`);
        // return await request(`https://dj-front.doct24.com/api/v1/doctors/?search=${doctorSpecialization}&page=${page}&page_size=6`);
        return await request(
            `${BASE_URL}doctors/?spec_ids=${doctorSpecialization}&category_ids=${category}&scientific_degree_ids=${degree}&gender_ids=${gender}&seniority_min=${seniority}&page=${pageNumberPaginate}&page_size=${size}`
        );
    }
);

export const fetchGetDoctors = createAsyncThunk('doctors/fetchGetDoctors', async (data) => {
    // console.log(data);
    try {
        const res = await getAllDoctors({
            // spec_ids: '',
            // category_ids: '',
            // rank_ids: '',
            // search: data ? data : '',
            page_size: 38,
            search: data ? data : '',
            // scientific_degree_ids: '',
            // gender_ids: '',
            // seniority_min: '',
            // page: '',
        });
        // console.log(res);
        return res.data;
    } catch (err) {
        console.log(err);
    }
});

export const fetchDoctorsForHomePage = createAsyncThunk('doctors/fetchDoctorsForHomePage', async () => {
    const { request } = useHttp();
    // return await request(`${_apiBase}${page}&page_size=${size}`);
    // return await request(`https://dj-front.doct24.com/api/v1/doctors/?search=${doctorSpecialization}&page=${page}&page_size=6`);
    return await request(`${BASE_URL}doctors/?page_size=8`);
});


export const fetchDoctorsForTopCarousel = createAsyncThunk('doctors/fetchDoctorsForTopCarousel', async () => {
    const { request } = useHttp();
    return await request(`${BASE_URL}doctors/top`);
});


const doctorsSlice = createSlice({
    name: 'doctors',
    initialState,
    reducers: {
        changeOffset: (state, action) => {
            state.offset = state.offset + action.payload;
        },
        changeDoctorSpecialization: (state, action) => {
            state.doctorSpecialization = action.payload;
        },
        changeSortType: (state, action) => {
            state.sortType = action.payload;
        },
        changePageNumberPaginate: (state, action) => {
            state.pageNumberPaginate = action.payload;
        },
        // changeNumberOfPaginateButton: (state, action) => {
        //     state.numberOfPaginateButton = action.payload
        // },
        clearOffset: (state) => {
            state.offset = 0;
        },
        changeCategory: (state, action) => {
            state.category = action.payload;
        },
        changeSeniority: (state, action) => {
            state.seniority = action.payload;
        },
        changeDegree: (state, action) => {
            state.degree = action.payload;
        },
        changeGender: (state, action) => {
            state.gender = action.payload;
        },
        changeBookId: (state, action) => {
            state.bookId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDoctors.pending, (state) => {
                state.doctorsLoadingStatus = 'loading';
            })
            .addCase(fetchDoctorsForHomePage.pending, (state) => {
                state.doctorsLoadingStatus = 'loading';
            })
            .addCase(fetchDoctorsForTopCarousel.pending, (state) => {
                state.doctorsForTopCarouselLoadingStatus = 'loading';
            })

            .addCase(fetchDoctors.fulfilled, (state, action) => {
                state.doctorsLoadingStatus = 'idle';
                // const transformDoctors = action.payload.data.users
                //     ? action.payload.data.users.map(_transformDoctors)
                //     : null;
                // state.doctors = [...transformDoctors];
                state.doctors = action.payload.data.users;
                state.doctorsCount = action.payload.count;
            })
            .addCase(fetchDoctorsForHomePage.fulfilled, (state, action) => {
                state.doctorsLoadingStatus = 'idle';
                const transformDoctors = action.payload.data?.users;
                // ? action.payload.data.users.map(_transformDoctors)
                // : null;
                state.doctorsForHomepage = [...transformDoctors];
            })
            .addCase(fetchDoctorsForTopCarousel.fulfilled, (state, action) => {
                state.doctorsForTopCarouselLoadingStatus = 'idle';
                const listOfTopDoctors = action.payload.data?.top;
                state.doctorsForTopCarousel = [...listOfTopDoctors];
            })

            .addCase(fetchDoctors.rejected, (state) => {
                state.doctorsLoadingStatus = 'error';
            })
            .addCase(fetchDoctorsForHomePage.rejected, (state) => {
                state.doctorsLoadingStatus = 'error';
            })
            .addCase(fetchDoctorsForTopCarousel.rejected, (state) => {
                state.doctorsForTopCarouselLoadingStatus = 'error';
            })

            .addCase(fetchGetDoctors.fulfilled, (state, action) => {
                state.doctors = action.payload.data?.users;
                state.doctorsCount = action.payload.count;
                // console.log(action.payload);
            })
            .addDefaultCase(() => {});
    },
});

const { actions, reducer } = doctorsSlice;

export default reducer;
export const {
    changeDoctorSpecialization,
    changeCategory,
    changeDegree,
    changeGender,
    changeSeniority,
    changeSortType,
    changePageNumberPaginate,
    changeNumberOfPaginateButton,
    changeOffset,
    changeTitle,
    clearBooks,
    clearOffset,
    changeSorting,
    changeBookId,
} = actions;
