import React, { useEffect } from 'react';
import { ScheduleViewerStl } from './scheduleViewer.styled';
import { useDispatch } from 'react-redux';
import { fetchGetConsultationsForCalendar } from '../../../api/lkDoctorCalendarSlice';
import { useSelector } from 'react-redux';
import { getTimeFromDate } from '../../../../share/helpers';

export const ScheduleViewer = ({ choosenDates, toShowWhole }) => {
    const dispatch = useDispatch();
    const consultations = useSelector((state) => state.doctorCalendar.consultations);
    useEffect(() => {
        // получение всех консультаций с статусом 0
        dispatch(fetchGetConsultationsForCalendar());
    }, []);

    const renderConsultations = () => {
        if (choosenDates.length < 1) {
            return (
                <p className="schedule__placeholder">
                    Пожалуйста, выберите день, чтобы посмотреть {toShowWhole ? 'расписание' : 'записи'}
                </p>
            );
        }
        if (!choosenDates[0].isReceptions) {
            return <p className="schedule__placeholder">Расписание не создано</p>;
        }
        const filteredConsultations = consultations?.filter((consultation) => {
            const consultationDate = new Date(consultation.date * 1000).toDateString();

            const chosenDate = new Date(choosenDates[0]?.timeStamp * 1000).toDateString();

            return consultationDate === chosenDate;
        });

        if (toShowWhole) {
            return (
                <ul className="schedule__list">
                    {choosenDates[0]?.receptions?.map((el) => {
                        const { timeStamp, timeString, workTime, consultation } = el;
                        const currentConsultation =
                            consultation && consultations?.find((consult) => consult.id === consultation);
                        const namePatient = currentConsultation
                            ? `${currentConsultation.patient.user.last_name} ${currentConsultation.patient.user.first_name} ${currentConsultation.patient.user.patronymic}`
                            : '';

                        return (
                            <li key={timeStamp} className="schedule__item">
                                <p className={`${workTime ? 'work-time' : 'no-work'} ${consultation && 'black-color'}`}>
                                    {timeString}
                                </p>
                                {consultation ? (
                                    <p>{namePatient}</p>
                                ) : (
                                    <p className={workTime ? 'work-time' : 'no-work'}>
                                        {workTime ? 'Время для записи' : 'Не рабочее время'}
                                    </p>
                                )}
                            </li>
                        );
                    })}
                </ul>
            );
        }
        if (!filteredConsultations.length) {
            return <p className="schedule__placeholder">На эту дату нет записей</p>;
        }
        return (
            <ul className="schedule__list">
                {filteredConsultations?.map((el) => (
                    <li key={el.id} className="schedule__item">
                        <p>{getTimeFromDate(el.date)}</p>
                        <p>{`${el.patient.user.last_name} ${el.patient.user.first_name} ${el.patient.user.patronymic}`}</p>
                    </li>
                ))}
            </ul>
        );
    };
    return <ScheduleViewerStl>{renderConsultations()}</ScheduleViewerStl>;
};
