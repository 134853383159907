export function identifyWhoAreYou(role) {
    switch (role) {
        case 'patient':
            return '/lk/patient-profile';
        case 'doctor':
            return '/lk/doctor-profile';
        default:
            return '/login';
    }
}
