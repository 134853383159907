import styled from 'styled-components';

export const SocialMediaStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 35px;

    .shareTitle {
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
    }
    .shareTitle::before {
        content: 'Мы в социальных сетях:';
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        @media (max-width: 768px) {
            font-size: 20px;
            line-height: 26px;
        }
        @media (max-width: 500px) {
            content: 'Мы в соц.сетях:';
            font-size: 18px;
            line-height: 23.4px;
        }
    }

    .shareIconsContainer {
        display: flex;
        align-items: center;
        gap: 16px;
    }
`;
