import React from 'react';
import styled, { css } from 'styled-components';

const theme = {
  color: {
    green: {
      medium: '#19BE6F',
      light: '#6AD09F',
    },
    blue: {
      dark: '#062A3F',
      medium: '#06A7B3',
      light: '#062A3F',
    },
    white: '#FFFFFF',
  },
};

const ButtonStyle = styled.button`
  border-radius: 10px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: ${theme.color.green.medium};
  background-color: ${theme.color.white};
  border: 2px solid ${theme.color.green.medium};
  transition: opacity 0.5s linear;
  cursor: pointer;
  width: ${(props) => props.width + 'px' || '100%'};
  height: ${(props) => props.height + 'px' || '60px'};
  padding: ${(props) => props.padding || '17px'};
  margin:${(props)=>props.margin || '0 0 20px 0'};

  ${(props) =>
    props.green &&
    css`
      background-color: ${theme.color.green.medium};
      border: ${theme.color.green.medium};
      color: ${theme.color.white};
    `}

  ${(props) =>
    props.blueDark &&
    css`
      background-color: ${theme.color.blue.dark};
      border: ${theme.color.blue.dark};
      color: ${theme.color.white};
    `}

  ${(props) =>
    props.blueMedium &&
    css`
      background-color: ${theme.color.blue.medium};
      border: ${theme.color.blue.medium};
      color: ${theme.color.white};
    `}

  ${(props) =>
    props.white &&
    css`
      background-color: ${theme.color.white};
      border: ${theme.color.white};
      color: ${theme.color.blue.dark};
    `}

  ${(props) =>
    props.XXS &&
    css`
      width: 103px;
      padding: 9.5px;
      font-size: 16px;
    `}
  
  ${(props) =>
    props.XS &&
    css`
      width: 133px;
      padding: 3px;
      font-size: 16px;
    `}

  ${(props) =>
    props.S &&
    css`
      width: 180px;
      padding: 17px;
    `}

  ${(props) =>
    props.M &&
    css`
      width: 227px;
      padding: 9.5px;
    `}
  
  ${(props) =>
    props.L &&
    css`
      width: 245px;
      padding: 17px;
    `}
  
  ${(props) =>
    props.XL &&
    css`
      width: 271px;
      padding: 17px;
    `}

  ${(props) =>
    props.XL &&
    css`
      width: 301px;
      padding: 12px;
    `}
  
  ${(props) =>
    props.XXL &&
    css`
      width: 334px;
      padding: 17px;
    `}

  ${(props) =>
    props.XXXL &&
    css`
      width: 406px;
      padding: 14px;
    `}
  
  ${(props) =>
    props.XXXXL &&
    css`
      width: 470px;
      padding: 17px;
    `}

  ${(props) =>
    props.XXXXXL &&
    css`
      width: 510px;
      padding: 17px;
    `}
  &:hover,
  &:active {
    opacity: 0.7;
  }
  &:disabled {
    opacity: 0.4;
    cursor: auto;
  }
`;
const Button = (props) => {
  return <ButtonStyle {...props}>{props.children}</ButtonStyle>;
};


export default Button;