import styled from 'styled-components';

export const SimilarPostsStyled = styled.div`
    .similarPosts {
        display: grid;
        grid-auto-rows: minmax(360px, 1fr);
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-bottom: 35px;
        @media (max-width: 500px) {
            grid-template-columns: 1fr;
            margin-bottom: 20px;
        }
    }
`;
