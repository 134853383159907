import styled from 'styled-components';

export const StyledDoctorSchedule = styled.div`
    width: 32%;

    .navMenu {
        display: flex;
        align-items: center;
        gap: 11px;

        & p {
            justify-self: flex-start;
            font-weight: 400;
            font-size: 16px;
            padding: 4px 9px;
            cursor: pointer;
            outline: none;
            border-radius: 24px;
            border: 2px solid transparent;
            transition: all 0.3s linear;
            :hover {
                border-color: #19be6f;
            }
        }
    }

    .navMenu__input {
        display: none;
    }

    .calendar-container {
        margin-top: 18px;
        margin-left: 0;
        &__text {
            color: #666666;
            line-height: 23.5px;
            &_clinic {
                margin-top: 33px;
            }
            @media (max-width: 420px) {
                font-size: 14px;
                line-height: 130%;
            }
        }
    }

    .consultPrice {
        margin-top: 24px;
    }

    .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;

        & p {
            font-weight: 400;
            font-size: 16px;
        }

        > p:last-child {
            padding-right: 8px;
        }
    }

    .scheduleBtn {
        background: #19be6f;
        border-radius: 6px;
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        padding: 13px 20px;
        border: 0;
        cursor: pointer;
        margin-top: 24px;
        &-disabled {
            display: inline-block;
            opacity: 0.5;
            cursor: default;
        }
    }

    .active {
        color: #fff;
        background: #19be6f;
        border-radius: 24px;
        font-weight: 400;
        font-size: 16px;
    }
    @media (max-width: 1024px) {
        width: 35%;
        .price {
            & p {
                font-size: 16px;
            }
        }
    }
    @media (max-width: 769px) {
        width: 50%;
    }
    @media (max-width: 575px) {
        width: 100%;
        .scheduleBtn {
          width: 100%;
          margin-bottom: 4px;
        }
    }
`;
