import React from 'react';
import { FieldWrapper } from '../FieldWrapper';
import { useController, useFormContext } from 'react-hook-form';

const InputEmail = ({ name, label, placeholder, disabled, validation = {}, isRequired }) => {
    const { control, trigger } = useFormContext();
    const defaultValidation = {
        ...validation,
    };
    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules: defaultValidation });
    const clearedView = '';

    const handleBlur = () => {
        let inputValue = field.value;
        if (typeof inputValue === 'string') {
            // Удаление пробелов в строке
            inputValue = inputValue?.replace(/ /g, '');
        }
        trigger(field.name);
        field.onChange(inputValue);
    };

    return (
        <FieldWrapper
            error={error}
            label={label}
            isRequired={isRequired}
            clearCondition={field.value !== clearedView}
            onClear={() => {
                field.onChange(clearedView);
                // onChange(clearedView);
            }}
            disabled={disabled}
        >
            <input
                {...field}
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                id={name}
                type="text"
                onBlur={handleBlur}
                onChange={(e) => {
                    field.onChange(e);
                    trigger(field.name);
                }}
                disabled={disabled}
                style={{ color: disabled && 'gray' }}
            />
        </FieldWrapper>
    );
};

export default InputEmail;
