import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { StyledResults, StyledDoctors } from './searchResult.styled';


const ShowAllResults = ({ doctors, setSearchActive }) => {
    const show = doctors.length > 0;
    const AllResults = () => {
        return (
            <div
                onClick={
                    show
                        ? () => {
                            setSearchActive(false);
                        }
                        : null
                }
                className="showallresults__wrapper"
            >
                <span className="showallresults__text">
                    {show
                        ? 'Показать все результаты'
                        : `Ничего не найдено. Введите специальность или фамилию доктора. Проверьте корректность ввода.`}
                </span>
                {show ? <img src='/img/header/arrowToRight.svg' alt="show more" className="showallresults__arrow" /> : null}
            </div>
        );
    };
    return (
        <>
            {show ? (
                <Link to={`/searchDoctors`} style={{ textDecoration: 'none' }}>
                    <AllResults />
                </Link>
            ) : (
                <AllResults />
            )}
        </>
    );
};

const SearchResultsDoctors = ({ data, setSearchActive, setSearchValue }) => {
    const navigate = useNavigate()

    return (
        <div onClick={() => navigate(`/doctors/${data.id}/${data.slug}`)}>
            <StyledDoctors
                onClick={() => {
                    setSearchActive(false);
                    setSearchValue('');
                }}
            >
                <img className="searchresultdoctors__img" src={data.avatar || '/img/doctorHouse.jpg'} alt="doctor" />
                <div className="doctor_descr">
                    <div className="doctor_name">
                        {data.lastname} {data.name} {data.patronymic}
                        <div className="doctor_spec">
                            {data.specialization.map((item, i) => {
                                return (
                                    <span key={i}>
                                        <span>{item.name}</span>
                                        <br />
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className='doctor__rating'>
                    <img src={data.rating !== 0 ? '/img/header/star.png' : '/img/header/emptyStar.svg'} alt="star" />
                    <span className='search__rating'>{data.rating}</span>
                </div>
            </StyledDoctors>
        </div >
    );
};

const SearchResult = ({ setSearchActive, setSearchValue }) => {
    const { doctors } = useSelector((state) => state.mainSearch);

    return (
        <StyledResults>
            <ShowAllResults doctors={doctors} setSearchActive={setSearchActive} />
            {doctors.map((item, i) => {
                if (i < 3) {
                    return (
                        <SearchResultsDoctors
                            key={item.id}
                            data={item}
                            setSearchActive={setSearchActive}
                            setSearchValue={setSearchValue}
                        />
                    );
                }
                return null;
            })}
        </StyledResults>
    );
};

export default SearchResult;
