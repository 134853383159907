import styled from 'styled-components';

export const TitleWithPencilStl = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    .title {
        font-size: 24px;
        font-weight: 500;
        color: #212121;

        @media(max-width: 800px) {
            font-size: 22px;
        }

        @media(max-width: 600px) {
            font-size: 20px;
        }
    }

    svg {
        cursor: pointer;
        flex-shrink: 0;
        margin-left: 10px;
    }
`;
