import React from 'react';
import styled, { css } from 'styled-components';

/**
 * A customizable Button component styled with `styled-components`.
 *
 * @component
 * @example
 * // Example usage:
 * <Button green S>Click Me</Button>
 *
 * @param {object} props - The props for the button component.
 * @param {string} [props.display='inline-block'] - The CSS display property of the button.
 * @param {string} [props.align='unset'] - The alignment of the button content.
 * @param {string} [props.justify='unset'] - The justification of the button content.
 * @param {string} [props.font_size='20px'] - The font size of the button text.
 * @param {string} [props.color=theme.color.green.light] - The color of the button text.
 * @param {string} [props.background_color=theme.color.white] - The background color of the button.
 * @param {string} [props.border=theme.color.green.light] - The border color of the button.
 * @param {string} [props.width='100%'] - The width of the button.
 * @param {string} [props.height='60px'] - The height of the button.
 * @param {string} [props.margin='17px'] - The margin around the button.
 * @param {string} [props.padding='17px'] - The padding inside the button.
 * @param {boolean} [props.green=false] - If `true`, applies the green button style from the theme.
 * @param {boolean} [props.white=false] - If `true`, applies the white button style from the theme.
 * @param {boolean} [props.XXS=false] - If `true`, applies the XXS button size.
 * @param {boolean} [props.XS=false] - If `true`, applies the XS button size.
 * @param {boolean} [props.S=false] - If `true`, applies the S button size.
 * @param {boolean} [props.M=false] - If `true`, applies the M button size.
 * @param {boolean} [props.L=false] - If `true`, applies the L button size.
 * @param {boolean} [props.XL=false] - If `true`, applies the XL button size.
 * @param {boolean} [props.XXL=false] - If `true`, applies the XXL button size.
 * @param {boolean} [props.XXXL=false] - If `true`, applies the XXXL button size.
 * @param {boolean} [props.XXXXL=false] - If `true`, applies the XXXXL button size.
 * @param {boolean} [props.XXXXXL=false] - If `true`, applies the XXXXXL button size.
 * @param {string} [props.hoverBorder] - The border color when the button is hovered or focused.
 * @param {string} [props.hoverBGColor] - The background color when the button is hovered or focused.
 * @param {string} [props.hoverColor] - The text color when the button is hovered or focused.
 * @param {string} [props.activeBorder] - The border color when the button is active.
 * @param {string} [props.activeBGColor] - The background color when the button is active.
 * @param {string} [props.activeColor] - The text color when the button is active.
 * @param {boolean} [props.disabled=false] - If `true`, the button is disabled.
 *
 * @returns {React.ReactElement} A styled button element.
 */

const theme = {
    color: {
        green: {
            dark: '#126D42',
            medium: '#169056',
            light: '#19BE6F',
            ultralight: '#C9FCE3',
        },
        white: '#FFFFFF',
    },
};

const ButtonStyle = styled.button`
    display: ${(props) => props.display || 'inline-block'};
    align-items: ${(props) => props.align || 'unset'};
    justify-content: ${(props) => props.justify || 'unset'};
    border-radius: 10px;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: ${(props) => props.font_size || '20px'};
    line-height: 130%;
    color: ${(props) => props.color || theme.color.green.light};
    background-color: ${(props) => props.background_color || theme.color.white};
    border: 2px solid ${(props) => props.border || theme.color.green.light};
    padding: 17px;
    transition: opacity 0.5s linear;
    cursor: pointer;
    width: ${(props) => props?.width || '100%'};
    height: ${(props) => props.height || '60px'};
    margin: ${(props) => props.margin || '17px'};
    padding: ${(props) => props.padding || '17px'};
    ${(props) =>
        props.green &&
        css`
            background-color: ${theme.color.green.light};
            border: 2px solid ${theme.color.green.light};
            color: ${theme.color.white};
            &:hover,
            &:focus {
                background-color: ${theme.color.green.medium};
                border: 2px solid ${theme.color.green.medium};
            }
            &:active {
                background-color: ${theme.color.green.dark};
                border: 2px solid ${theme.color.green.dark};
            }
            &:disabled {
                cursor: auto;
                background-color: ${theme.color.green.ultralight};
                border: 2px solid ${theme.color.green.ultralight};
                color: ${theme.color.green.dark};
                &:hover {
                    border: 2px solid ${theme.color.green.ultralight};
                }
            }
        `}

    ${(props) =>
        props.white &&
        css`
            background-color: ${theme.color.white};
            border: 2px solid ${theme.color.green.light};
            color: ${theme.color.green.light};
            &:hover,
            &:focus {
                color: ${theme.color.green.medium};
                border: 2px solid ${theme.color.green.medium};
            }
            &:active {
                color: ${theme.color.green.dark};
                border: 2px solid ${theme.color.green.medium};
                background-color: ${theme.color.green.ultralight};
            }
            &:disabled {
                cursor: auto;
                background-color: ${theme.color.green.ultralight};
                border: 2px solid ${theme.color.green.ultralight};
                color: ${theme.color.green.dark};
                &:hover {
                    border: 2px solid ${theme.color.green.ultralight};
                }
            }
        `}

  ${(props) =>
        props.XXS &&
        css`
            width: 103px;
            padding: 9.5px;
            font-size: 16px;
        `}

  ${(props) =>
        props.XS &&
        css`
            width: 133px;
            padding: 3px;
            font-size: 16px;
        `}

  ${(props) =>
        props.S &&
        css`
            width: 180px;
            padding: 17px;
        `}

  ${(props) =>
        props.M &&
        css`
            width: 227px;
            padding: 9.5px;
        `}

  ${(props) =>
        props.L &&
        css`
            width: 245px;
            padding: 17px;
        `}

  ${(props) =>
        props.XL &&
        css`
            width: 271px;
            padding: 17px;
        `}

  ${(props) =>
        props.XL &&
        css`
            width: 301px;
            padding: 12px;
        `}

  ${(props) =>
        props.XXL &&
        css`
            width: 334px;
            padding: 17px;
        `}

  ${(props) =>
        props.XXXL &&
        css`
            width: 406px;
            padding: 14px;
        `}

  ${(props) =>
        props.XXXXL &&
        css`
            width: 470px;
            padding: 17px;
        `}

  ${(props) =>
        props.XXXXXL &&
        css`
            width: 510px;
            padding: 17px;
        `}

  &:hover, &:focus {
        border: 2px solid ${(props) => props.hoverBorder || ''};
        background-color: ${(props) => props.hoverBGColor || ''};
        color: ${(props) => props.hoverColor || ''};
    }
    &:active {
        border: 2px solid ${(props) => props.activeBorder || ''};
        background-color: ${(props) => props.activeBGColor || ''};
        color: ${(props) => props.activeColor || ''};
    }
    &:disabled {
        color: ${theme.color.green.dark};
        opacity: 0.4;
        cursor: auto;
    }
`;

const Button = (props) => {
    return <ButtonStyle {...props} />;
};
export default Button;
