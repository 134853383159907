import React from 'react'
import { WeekDaysStl } from './weekDays.styled';

export const WeekDays = () => {
    const weekDays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

    return (
        <WeekDaysStl>
            {weekDays.map((weekDay) => (
                <div key={weekDay}>{weekDay}</div>
            ))}
        </WeekDaysStl>
    );
};