import React from 'react';
import {StyledContactsCard} from './contactsCard.styled';

export const ContactsCard = ({title, content, hrefTo, children}) => {
  return (
      <StyledContactsCard href={hrefTo}>
        <div className="icon">
          {children}
        </div>
        <div className="content">
          <p className='content__title'>{title}</p>
          <p className='content__content'>{content}</p>
        </div>
      </StyledContactsCard>
  );
};