import styled from 'styled-components';

export const StyledDoctorCardProfile = styled.div`
    display: flex;
    width: 60%;
    justify-content: space-between;
    .mobileProfileView {
        display: none;
    }

    .doctorPhoto {
        position: relative;
    }

    .doctorMainPhoto {
        width: 143px;
        height: 143px;
        border-radius: 84px;
        object-fit: cover;
    }

    .like-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 100%;
        padding: 10px;
        background-color: #fff;
        cursor: pointer;
    }

    .like {
        width: 19px;
        height: 18px;
    }

    .doctorInfo {
        width: 65%;
    }

    .name {
        font-weight: 500;
        font-size: 20px;
    }

    .specialization {
        list-style: none;
        margin-top: 8px;
        > li {
            font-weight: 400;
            font-size: 16px;
            color: #666666;
        }
    }

    .mainFlex {
        display: flex;
        align-items: center;
        /* gap: 10px; */
        > svg {
            justify-self: flex-start;
            min-width: 10%;
        }
    }

    .infoText {
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: #666666;
        max-width: 80%;
        margin-left: 10px;
    }
    .infoText::first-letter {
        text-transform: uppercase;
    }

    .seniority .infoText {
        margin-right: 8px;
    }

    .imgWrap {
        width: 6%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .about {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .star {
        margin-left: 10px;
    }

    .mrImg {
        margin-right: 10px;
    }

    @media (max-width: 1027px) {
        .name {
            font-size: 1em;
        }
        .specialization {
            font-size: 1em;
        }
    }
    @media (max-width: 844px) {
        .doctorMainPhoto {
            width: 130px;
            height: 130px;
        }

        @media (max-width: 769px) {
            width: 55%;
            .mobileBlock {
                display: none;
            }
            .mobileProfileView {
                display: flex;
                & img {
                    margin-right: 30px;
                    width: 90px;
                    height: 90px;
                }
            }
            .doctorInfo {
                width: 90%;
            }
        }
    }
    @media (max-width: 575px) {
        width: 100%;
        margin-bottom: 24px;
        .about {
            gap: 4px;
        }
        .infoText {
            font-size: 14px;
            margin-left: 8px;
        }
    }
    @media (max-width: 545px) {
        .mobileProfileView {
            & img {
                margin-right: 15px;
            }
        }
    }
    @media (max-width: 330px) {
        .name {
            font-size: 14px;
        }
        .specialization {
            font-size: 14px;
        }
        .star {
            margin-right: 5px;
        }
    }
`;
