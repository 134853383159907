import React, { useRef, useState } from 'react';
import { uploadFile } from '../../../../../share/fileUploader';
import { useDispatch } from 'react-redux';
import { LoadFileStl } from './consultationFiles.styled';
import { consultationDeleteFile, consultationSendFile } from '../../../../../lkPatient/api/patientApi';
import { fetchConsultation, fetchFeedback } from '../../../../api/lkDoctorConsultationsSlice';

export const ConsultationFiles = ({ consultationId, files, feedbackId, readOnly }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState({ status: false, action: '' });
    const loadFile = useRef();

    const handleFileUpload = (e) => {
        e.preventDefault();
        uploadFile(
            loadFile,
            {
                multiple: false,
                accept: ['.jpg', '.png', '.jpeg', '.gif'],
            },
            sendUpResult
        );
    };

    const sendUpResult = async (file) => {
        setIsLoading({ status: true, action: 'Загрузка ...' });
        consultationSendFile({ consultationId, file }).then(() => {
            dispatch(fetchConsultation(consultationId)).then(() => {
                if (feedbackId) {
                    dispatch(fetchFeedback(feedbackId));
                }
            });
            setIsLoading({ status: false, action: '' });
        });
    };

    const handleDeleteFile = (id) => {
        setIsLoading({ status: true, action: 'Удаление ...' });
        consultationDeleteFile(id).then(() => {
            dispatch(fetchConsultation(consultationId)).then(() => {
                if (feedbackId) {
                    dispatch(fetchFeedback(feedbackId));
                }
            });
            setIsLoading({ status: false, action: '' });
        });
    };
    if (readOnly) {
        return (
            <LoadFileStl readOnly={readOnly}>
                <div className="files" >
                    {files.map((file) => (
                        <div className="file" key={file.id}>
                            <a href={file.file} target="_blank" rel="noreferrer">
                                <img src="/img/icons/file.png" alt="file" />
                                Документ
                            </a>
                        </div>
                    ))}
                </div>
            </LoadFileStl>
        );
    }
    return (
        <LoadFileStl>
            <input type="file" id="loadFile" ref={loadFile} />
            {isLoading.status && <span className="btn">{isLoading.action}</span>}
            {!isLoading.status && files.length < 3 && (
                <label htmlFor="loadFile" className="btn" onClick={(e) => handleFileUpload(e)}>
                    Загрузить файл
                </label>
            )}
            <div className="files">
                {files.map((file) => (
                    <div className="file" key={file.id}>
                        <a href={file.file} target="_blank" rel="noreferrer">
                            <img src="/img/icons/file.png" alt="file" />
                            Документ
                        </a>
                        <span onClick={() => handleDeleteFile(file.id)}>X</span>
                    </div>
                ))}
            </div>
        </LoadFileStl>
    );
};
