import styled from 'styled-components';

export const FreeTimeStl = styled.div`
    width: 100%;
    margin-top: 13px;
    margin-left: -8px;
    .schedule {
        &__body {
            display: grid;
            /* justify-content: space-evenly;
            align-content: space-evenly; */
            grid-template-columns: repeat(5, 1fr);
            /* gap: 5px; */
        }
        &__time {
            font-weight: 400;
            font-size: 14px;
            /* line-height: 150%; */
            /* justify-content: space-evenly; */
            /* align-content: space-evenly; */
            text-align: center;
            color: #212121;
            padding: 5px 0;
            border: 2px solid transparent;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.3s linear;
            &:hover {
                border-color: #19be6f;
            }
            &_active {
                border-color:#06a7b3;
                background-color: #19be6f;
            }
        }
    }

    @media (max-width: 1024px) {
        /* .schedule {
            &__time {
                padding: 0;
            }
        } */
    }

    @media (max-width: 768px) {
        .schedule {
            &__header {
                &-text {
                    font-size: 20px;
                }
            }
        }
    }
    @media (max-width: 575px) {
        .schedule {
            &__body {
                grid-template-columns: repeat(7, 1fr);
            }
        }
    }
    @media (max-width: 460px) {
        .schedule {
            &__body {
                grid-template-columns: repeat(5, 1fr);
            }
            &__time { 
                font-size: 12px;
            }
        }
    }
`;
