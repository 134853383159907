import React from 'react';
import { ReactComponent as ModerationSvg } from '../../img/icons/icon_attention.svg';
import styled from 'styled-components';

const ModerationLabelStl = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    .text {
        color: #ff3636;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 150%;
    }
`;

export const ModerationLabel = ({ moderation = false }) => {
    if (moderation) {
        return null;
    } else {
        return (
            <ModerationLabelStl>
                <span className="text">На модерации</span>
                <ModerationSvg />
            </ModerationLabelStl>
        );
    }
};
