import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchGetPayments } from '../../api/doctorWalletSlice';
import { Filters } from './Filters';
import { PayCard } from './PayCard';
import { Total } from './Total';
import { useSelector } from 'react-redux';
import { ReactComponent as ArrowUp } from "../../../../img/icons/newDoctorIcons/up-arrow.svg";
import { initialFilterMonth, initialFilterYear } from '../../../share/helpers';

export const TransactionsDoctor = () => {
    const dispatch = useDispatch();
    const [month, setMonth] = useState(initialFilterMonth);
    const [year, setYear] = useState(initialFilterYear);
    const { results, total, count } = useSelector((state) => state.doctorWallet.transactions);

    const request = () => {
        if (year !== initialFilterYear) {
            if (month !== initialFilterMonth) {
                dispatch(fetchGetPayments({ year, month }));
            } else {
                console.log(month, " month;", year, " year");
                dispatch(fetchGetPayments({ year }));
            }
        } else {
            dispatch(fetchGetPayments());
        }
    }
    
    useEffect(() => {
        request()
    }, [year, month]);

    return (
        <>
            <Filters year={year} month={month} setMonth={setMonth} setYear={setYear} />
            <Total total={total} />
            {results?.map((pay) => (
                <PayCard key={pay.id} pay={pay} />
            ))}
            <div className='btns'>
                {count > results?.length &&
                    <span onClick={() => request()}>
                        Показать еще
                    </span >
                }
                <span onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>
                    Наверх
                    <ArrowUp />
                </span>

            </div>
        </>
    );
}
