import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Button from '../../../share/Button';
// import Flex from '../../../share/Flex';
import { ageCalculator } from '../../../share/helpers';
import { PatientDiv } from './patientPage.styled';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatient, fetchConsultationPatient } from '../../api/doctorPatientsSlice';
import PatientPageConsultation from './patientPageConsultation/PatientPageConsultation';
import PatientPageMedicalData from './patientPageMedicalData/PatientPageMedicalData';
import { Spinner } from '../../../share/Spinner';
import { ReactComponent as IconArrowBack } from '../../../../img/icons/icon-arrow-back.svg';

export default function PatientPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isNavigatePatientPage, setIsNavigatePatientPage] = useState(false)
    // const [patientDocuments, setPatientDocuments] = useState([]);
    const isLoading = useSelector((state) => state.doctorPatients.isLoading);
    const patientFull = useSelector((state) => state.doctorPatients.patientFull);
    const {avatar, last_name, first_name, patronymic, gender_label, birthday} = patientFull?.user;

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(() => {
        dispatch(fetchConsultationPatient(id));
        dispatch(fetchPatient(id));
    }, []);

    if (isLoading) return <Spinner />;
    return (
        <PatientDiv>
            <button
                className="patient__pageBack"
                onClick={() => {
                    navigate(-1);
                }}
            >
                <IconArrowBack /> Назад к списку
            </button>
            <div className="patient__info">
                <img className="patient__avatar" src={avatar} alt={last_name} />
                <div className="patient__content">
                    <p className="patient__name">
                        {last_name} {first_name} {patronymic}
                    </p>
                    <p className="patient__genderAge">
                        {gender_label}, {ageCalculator(birthday)}
                    </p>
                </div>
            </div>
            <nav className="patient__nav">
                <button className="patient__navMedicalData" onClick={()=>{setIsNavigatePatientPage(false)}} >
                    <span className={`patient__navSpan ${!isNavigatePatientPage ? "patient__navActive" : ""}`}></span>
                </button>
                <button
                    className={`patient__navConsultations ${isNavigatePatientPage ? "patient__navConsultationsActive" : ""}`}
                    onClick={()=>{setIsNavigatePatientPage(true)}}
                >
                    Консультации
                    <span className={`patient__navSpan ${isNavigatePatientPage ? "patient__navActive" : ""}`}></span>
                </button>
            </nav>
            {/* {patientDocuments.length > 0 ?
              <PatientDiv>
                <div className='patient-data__title'>Документы</div>
                {patientDocuments.map((doc) => (
                    <Flex
                        key={doc}
                        justify='space-between'
                        margin='0 0 32px'
                        align='center'>
                        <div>
                            <div className='patient-document'>Заключение терапевта</div>
                            <div className='patient-document'>24.10.2021.pdf</div>
                        </div>
                        <Button green S margin='0'>Посмотреть</Button>
                    </Flex>
                ))}
            </PatientDiv>
            : null} */}
            {isNavigatePatientPage ? <PatientPageConsultation /> : <PatientPageMedicalData /> }
        </PatientDiv>
    );
}
