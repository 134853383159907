import React from 'react';
import { NewFormSTL } from './newFormSTL.styled';
import { useSelector } from 'react-redux';
import { SpecializationList } from '../../shared/SpecializationList';

const Specialization = () => {
    // const { trigger, control } = useFormContext();
    const { allSpecializations } = useSelector((state) => state.doctorProfile);
    const { specialization } = useSelector((state) => state.doctorProfile.doctorProfile);

    const formatedSpecializations = specialization?.map((spec) => {
        const value = allSpecializations?.find((item) => item.id === spec.id).id;
        return { value, label: spec.name };
    });
    return (
        <NewFormSTL>
            <h2 className="title">Специализация</h2>
            <SpecializationList
                allSpecializations={allSpecializations}
                formatedSpecializations={formatedSpecializations}
            />
        </NewFormSTL>
    );
};

export default Specialization;
