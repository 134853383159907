import { useController, useFormContext } from 'react-hook-form';

import { FieldWrapper } from '../FieldWrapper';
import InputMask from 'react-input-mask';
import React from 'react';
import styled from 'styled-components';

const DateFieldStl = styled(FieldWrapper)`
    max-width: max-content;

    > input {
        padding: 11px 16px 12px 16px;
        width: 156px;
    }
`;

const InputDate = ({ name, label, placeholder, defaultValue, validation = {}, isRequired }) => {
    // outSideOnChange можем передать внешний коллбек, если будет нужно
    const { control, trigger } = useFormContext();
    const defaultValidation = {
        required: { value: false, message: 'Введите дату' },
        pattern: {
            value: /^(19|20)\d{2}$/,
            message: 'Некорректная дата',
        },
        validate: {
            // нельзя ввести будущую дату
            isFutureDate: (value) => {
                const currentDate = new Date();
                const enteredDate = new Date(value);
                if (enteredDate > currentDate) {
                    return 'Дата не может быть больше текущего времени';
                }
                return true;
            },
            // нельзя ввести слишком прошлую дату
            isPasteDate: (value) => {
                const enteredDate = new Date(value);
                // const minDate = new Date();
                // minDate.setFullYear(minDate.getFullYear() - 60); // 60 лет назад
                const minDate = new Date(0);
                minDate.setFullYear(minDate.getFullYear() - 20); // 1950 г
                if (enteredDate < minDate) {
                    // return 'Дата не может быть более чем 60 лет назад';
                    return 'Дата не может быть ранее 1950 года';
                }
                return true;
            },
        },
        ...validation,
    };
    const {
        field,
        fieldState: { error },
    } = useController({ name, control, defaultValue, rules: defaultValidation });
    const clearedView = '';

    const handleBlur = () => {
        trigger(field.name);
    };

    return (
        <DateFieldStl
            error={error}
            label={label}
            isRequired={isRequired}
            clearCondition={!!field.value}
            onClear={() => {
                field.onChange(clearedView);
            }}
        >
            <InputMask
                {...field}
                inputMode="numeric"
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                type="text"
                mask={defaultValue}
                maskChar=""
                onBlur={handleBlur}
                onChange={(e) => {
                    field.onChange(e.target.value);
                    trigger(field.name);
                }}
            />
        </DateFieldStl>
    );
};

export default InputDate;
