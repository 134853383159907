import React from 'react';
import { DoctorCard } from '../doctorCard/doctorCard';
import { Loader } from '../../loader/loader';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchGetDoctors } from '../api/doctorsSlise';
import { ReactComponent as ArrowUp } from '../../../img/icons/newDoctorIcons/up-arrow.svg';

export const DoctorList = ({ allDoctors }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.doctors.isLoading);
    const count = useSelector((state) => state.doctors.count);

    // если лоудер будет отрабатывать нормально УБРАТЬ закомментированую строчку
    // if (isLoading && !allDoctors.length) return <div className='loader'><Loader /></div>

    return (
        <>
            {/* {!isLoading && allDoctors.length === 0 ? (
                <div className="not_found">
                    <h1>Докторов не найдено</h1>
                </div>
            ) : ( */}
            {allDoctors.length > 0 && (
                <ul className="doctors__list">
                    {allDoctors.map((doctor) => {
                        return <DoctorCard key={doctor.id} doctor={doctor} />;
                    })}
                </ul>
            )}
            {allDoctors.length === 0 && !isLoading && (
                <p className="not_found">По вашему запросу ничего не&nbsp;найдено</p>
            )}

            {/* )} */}
            {isLoading && (
                <div className="loader">
                    <Loader />
                </div>
            )}
            <div className="btns">
                {count > allDoctors.length && (
                    <span
                        onClick={() => {
                            dispatch(fetchGetDoctors());
                        }}
                    >
                        Показать больше
                    </span>
                )}
                {count > 1 && (
                    <span
                        onClick={() => {
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                    >
                        Наверх
                        <ArrowUp />
                    </span>
                )}
            </div>
        </>
    );
};
