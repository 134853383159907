import { StyledAvatar, StyledLink } from './avatar.styled';

import { Link } from 'react-router-dom';
import React from 'react';
import { consultationDelete } from '../../lkPatient/api/patientApi';
import { identifyWhoAreYou } from '../helpers/identifyWhoAreYou';

function Avatar({ role, avatar, size = 24 }) {
    const path = identifyWhoAreYou(role);
    return (
        <StyledLink to={path}>
            <StyledAvatar src={avatar ? avatar : '/img/header/profile.svg'} alt="profile avatar" size={size} />
        </StyledLink>
    );
}

export default Avatar;
