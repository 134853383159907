import styled from 'styled-components';

export const DoctorAboutStl = styled.div`
    width: 100% !important;
    .about {
        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        &__inner {
        }
        &__title {
            font-weight: 500;
            font-size: 20px;
            line-height: 130%;
            color: #212121;
            &-desease {
                margin-top: 0;
                line-height: 34px;
            }
        }
        &__list {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            &-item {
                margin-left: 30px;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #212121;
            }
        }
        &__message {
            margin-left: 25px;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #212121;
        }
    }

    @media (max-width: 620px) {
        .about__title {
            font-size: 18px;
        }

        .about__list-item {
            font-size: 14px;
        }
    }
`;
