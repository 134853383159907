import styled from 'styled-components';

export const StyledLkPatient = styled.section`
    .content {
        width: 100%;
        display: flex;
        gap: 60px;
    }

    .rightBlock {
        width: calc((100% - 200px - 60px));
    }

    @media (max-width: 980px) {
        .content {
            gap: 30px;
        }
        .rightBlock {
            width: calc((100% - 200px - 30px));
        }
    }

    @media (max-width: 700px) {
        .content {
            position: relative;
            flex-direction: column;
            justify-content: center;
        }
        .rightBlock {
            width: 100%;
        }
    }
`;
export const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;

    .nav-button {
        width: 220px;
        display: block;
        margin: 32px 0;
        padding: 16px 32px;
        border: 0;
        border-radius: 8px;
        font-weight: 500;
        text-decoration: none;
        text-align: center;
        color: #ffffff;
        background-color: #19be6f;
        cursor: pointer;
        transition: opacity 0.3s linear;

        &:hover {
            opacity: 0.7;
        }
    }
`;
