import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ReviewCard } from '../../../../doctorProfile/doctorReviews/reviewCard/ReviewCard';
import { fetchFeedback } from '../../../api/lkDoctorConsultationsSlice';

export const Feedback = ({ feedback, feedbackId }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (feedbackId) {
            dispatch(fetchFeedback(feedbackId));
        }
    }, [feedbackId]);

    console.log(feedback, feedbackId);

    return <>{feedback ? <ReviewCard review={feedback} /> : null}</>;
};
