import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledLkPatientFavorites } from './lkPatientFavorites.styled';
import { fetchFavoriteDoctors, resetFavoritesDoctors } from '../api/favoriteDoctorsSlice';
import {DoctorFavoritesList} from './doctorFavoritesList/DoctorFavoritesList';
import { ReactComponent as SearchSvg } from '../../../img/icons/newDoctorIcons/search.svg';
import InputText from '../../share/formFields/InputText';
import Form from '../../share/formFields/Form';
import { isEnteredLetter } from '../../share/helpers';

export const LkPatientFavorites = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [isSpiner, setIsSpiner] = useState(false);

    const { 
        favorite,
    } = useSelector((state) => state.favoriteDoctors);

    useEffect(() => {
        dispatch(resetFavoritesDoctors());
        requestFavoriteDoctor();
    }, [search]);

    const requestFavoriteDoctor = () => {
        setIsSpiner(true);
        dispatch(fetchFavoriteDoctors([search]))
            .then(() => setIsSpiner(false));
    }

    const onSearch = (value) => {
        return (setSearch(value.toLowerCase()));
    };

    return (
        <StyledLkPatientFavorites>
                <Form>
                    <label className="inputSearch">
                        <InputText
                            name="search"
                            placeholder={'Поиск по Ф.И.О, специализации'}
                            validation={{ required: false, pattern: null }}
                            onChange={onSearch}                           
                        />
                        <SearchSvg />
                    </label>
                </Form>
            <DoctorFavoritesList favorite={favorite} requestFavoriteDoctor={requestFavoriteDoctor} search={search} isSpiner={isSpiner}/>
        </StyledLkPatientFavorites>
    );
};
