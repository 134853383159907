import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUpcomingConsultation } from './api';

export const fetchUpcomingConsultation = createAsyncThunk('timer/fetchUpcomingConsultation', async (role) => {
    try {
        const response = await getUpcomingConsultation(role);

        return response.data;
    } catch (e) {
        console.log(e);
    }
});

const initialState = {
    isLoading: false,
    isError: false,
    upcomingConsultation: {},
    isShowTimer: false,
};

const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        showTimer(state, action) {
            state.isShowTimer = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUpcomingConsultation.pending, (state) => {
            // state.isLoading = true; //временно закомментировано 09.09.24
            state.isError = false;
        });
        builder.addCase(fetchUpcomingConsultation.fulfilled, (state, action) => {
            const data = action.payload?.data;

            if (data) {
                state.isLoading = false;
                // Прошедшие консультации висят ~15 минут после их окончания
                // state.upcomingConsultation = data.consultations[data.consultations?.length - 1];
                state.upcomingConsultation = data.consultations[0];
            }
        });
        builder.addCase(fetchUpcomingConsultation.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
        });
    },
});

export const { showTimer } = timerSlice.actions;
export default timerSlice.reducer;
