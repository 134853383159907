import React from 'react'
import { DateStl, FioStl, PayCardStl } from './transaction.styled'
import { dateFormatterFullMnthForPayCard } from '../../../share/helpers';

export const PayCard = ({ pay }) => {
    const { payed_date, income_amount, patient: { first_name, last_name, patronymic } } = pay;

    return (
        <PayCardStl>
            <div className='pay__info'>
                <DateStl>
                    {dateFormatterFullMnthForPayCard(payed_date)}
                </DateStl>
                <FioStl>{`${first_name} ${last_name} ${patronymic}`}</FioStl>
            </div>
            <div className='pay__status'>
                <p>{income_amount.toFixed(0)}&nbsp;₽</p>
            </div>
        </PayCardStl>
    )
}