import styled from 'styled-components';

export const StyledReviewPagination = styled.ul`
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
gap: 10px;

.paginationPage {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #F0FAFF;
}

.paginationPage:last-child {
  margin-right: 0;
}

.paginationPage__button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #212121;
  cursor: pointer;
}

.paginationPage__button:hover {
  opacity: 0.7;
}

.active {
  background: #19BE6F;
  color: #F6F6F6;
}

.remove {
  display: none;
}

@media(max-width: 550px) {
  .paginationPage {
    width: 40px;
    height: 40px;
  }
  .paginationPage__button {
    line-height: 130%;
  }
}
`