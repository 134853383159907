import styled from 'styled-components';

export const PatientDiv = styled.div`
    width: 100%;
    overflow: hidden;
    p {
        margin: 0;
        padding: 0;
    }
    .patient__pageBack {
        width: 100%;
        text-align: left;
        margin-bottom: 50px;
        border: none;
        background: #ffffff;
        font-size: 16px;
        line-height: 150%;
        color: #19be6f;
        cursor: pointer;
        transition: opacity 0.5s linear;
        &:hover {
            opacity: 0.7;
        }
    }
    .patient__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
    }
    .patient__avatar {
        width: 107px;
        height: 107px;
        border-radius: 50%;
        object-fit: cover;
    }
    .patient__content {
        width: calc(100% - 107px - 30px);
        word-wrap: break-word;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .patient__name {
        font-size: 20px;
        line-height: 130%;
        color: #212121;
    }
    .patient__genderAge {
        font-size: 18px;
        line-height: 150%;
        color: #666666;
    }
    .patient__nav {
        width: max-content;
        display: flex;
        gap: 20px;
        margin-top: 35px;
        border-bottom: 1px solid #B6B7BA;
    }
    .patient__navMedicalData, .patient__navConsultations {
        display: flex;
        flex-direction: column;
        border: none;
        background: transparent;
        color: #212121;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        cursor: pointer;
        transition: opacity 0.5s linear;
        &:hover {
            opacity: 0.7;
        }
    }
    .patient__navConsultations {
        padding-right: 20px;
    }
    .patient__navConsultationsActive {
        padding-right: 0;
    }
    .patient__navMedicalData::before {
        content: 'Медицинские данные';
    }
    .patient__navSpan {
        width: 100%;
        height: 4px;
    }
    .patient__navActive {
        border-radius: 12px 12px 0px 0px;
        background: #19BE6F;
    }

    @media (max-width: 800px) {
        .patient__genderAge {
            line-height: 130%;
        }
    }

    @media (max-width: 767px) {
        margin: 0 auto;
        max-width: 500px;
    }

    @media (max-width: 500px) {
        .patient__pageBack {
            margin-bottom: 30px;
        }
        .patient__info {
            gap: 20px;
        }
        .patient__avatar {
            width: 75px;
            height: 75px;
        }
        .patient__content {
            width: calc(100% - 75px - 20px);
        }
        .patient__name {
            font-size: 18px;
        }
        .patient__genderAge {
            font-size: 16px;
        }
        .patient__navMedicalData::before {
            content: 'Мед. данные';
        }
    }
`;
