import styled from 'styled-components';

export const StyledConsultationTime = styled.div`
    grid-column: 2;

    .dateAndTime {
        display: flex;
        flex-wrap: wrap;
        justify-content: ${(props) => props.isModal && 'center'};
        width: ${(props) => props.isModal && '275px'};
        gap: 12px;
        & img {
            margin-right: 8px;
        }

        & .dateValue,
        .timeValue {
            font-weight: 400;
            font-size: 14px;
            color: ${(props) => (props.card === true ? '#666666' : '#212121')};
        }
    }

    .date,
    .time {
        display: flex;
        align-items: center;
    }

    @media (max-width: 500px) {
        grid-column: 1/3;
        
        .dateAndTime {
            width: ${(props) => props.isModal && '220px'};
            & img {
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }
        }
    }
    @media (max-width: 370px) {
        .dateAndTime {
            width: auto;
            .dateValue,
            .timeValue {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
`;
