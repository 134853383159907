import React from 'react';
import styled from 'styled-components';

const StyledSpinner = styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${(props) => props.marginTop || '30px'};
    
    .spinner {
        height: ${(props) => props.height || '50px'};
        width: ${(props) => props.width || '50px'};
        border-left: ${(props) => props.color || '3px solid greenyellow'};
        border-bottom: ${(props) => props.color || '3px solid greenyellow'};
        border-right: ${(props) => props.color || '3px solid greenyellow'};
        border-top: ${(props) => (props.color ? '2px ' : '3px')} solid transparent;
        border-radius: 50%;
        animation: spinner 1s linear infinite;
    }
    @keyframes spinner {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
`;

export const Spinner = (props) => {
    return (
        <StyledSpinner {...props}>
            <div className="spinner"></div>
        </StyledSpinner>
    );
};
