import React from 'react';
import { DocrotConsultationCard } from './docrotConsultationCard/doctorConsultationCard';
import { Spinner } from '../../../share/Spinner';
import { useDispatch } from 'react-redux';
import {
    fetchGetDoctorFutureConsultations,
    fetchGetDoctorPastConsultations,
} from '../../api/lkDoctorConsultationsSlice';
import { ScrollToTopArrowUp } from '../../../share/ScrollToTopArrowUp';

export const ConsultationList = ({ list, pathname, isLoading }) => {
    const dispatch = useDispatch();

    const requestConsultationsDoctor = () => {
        if (pathname.includes('future')) {
            dispatch(fetchGetDoctorFutureConsultations());
        } else {
            dispatch(fetchGetDoctorPastConsultations());
        }
    };

    return (
        <>
            {list?.consultations?.length > 0 ? (
                list.consultations.map((consultation) => <DocrotConsultationCard key={consultation.id} consultation={consultation} />)
            ) : (!isLoading &&
                <div>
                    <p className={'no-consult'}>Записей нет</p>
                </div>
            )}

            {isLoading ? (
                <Spinner />
            ) : (
                <div className="buttonsPaginate">
                    {list.next !== null && (
                        <button className="buttonAddDoctors" onClick={requestConsultationsDoctor}>
                            Показать больше
                        </button>
                    )}
                    <ScrollToTopArrowUp />
                </div>
            )}
        </>
    );
};
