import React from 'react';

const ArrowIcon = ({ isOpen }) => (
    <svg
        width="13"
        height="8"
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            transform: isOpen ? 'rotate(0)' : 'rotate(180deg)',
            transition: 'transform 0.3s ease',
        }}
    >
        <path
            d="M1.5 6.5L6.5 1.5L11.5 6.5"
            stroke="#B6B7BA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ArrowIcon;
