import styled from 'styled-components';

export const StyledStartConsultationModal = styled.div`
    max-width: 355px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 52px 40px 40px;
    cursor: auto;
    
    > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    .modalStart__heading {
        color: #666;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .modalStart__nameDoctor {
        width: 100%;
        word-wrap: break-word;
        color: #212121;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-align: center;
    }
    
    .modalStart__timeBeforeConsultation {
        color: var(--primary-text, #212121);
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; 
    }

    .modalStart__delayBtn {
        width: 100%;
        text-decoration: none;
        text-align: center;
        border: 2px solid #19be6f;
        border-radius: 8px;
        background: #ffffff;
        height: 47px;
        padding: 14px 24px;
        color: #19BE6F;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        .textContent {
            font-weight: 500;
            font-size: 24px;
            line-height: 130%;
        }
        .modalStart {
            padding: 15px;
        }
    }
    @media (max-width: 600px) {
        .textContent {
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            margin-bottom: 18px;
        }
    }
    @media (max-width: 500px) {
        .buttons {
            flex-direction: column;
        }
        .delayBtn,
        .startBtn {
            width: 100%;
        }
    }
`;
