/* eslint-disable react/react-in-jsx-scope */

import styled from 'styled-components';
const SocialsStl = styled.article`
  display: flex;
  align-items: center;
`;

const SocialsList = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
`;
const SocialsListItem = styled.li`
  margin-right: 30px;
`;

const SocialsLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

`;

const Socials = () => {

  const socialNetwork = [
    {
      title: 'telegram',
      img: '/img/social/telega-green.png',
      href: 'https://t.me/+8fkJzIb8wWMyYmZi'
    },
    {
      title: 'odnoklassniki',
      img: '/img/social/ok-green.png',
      href: 'https://ok.ru/group/61412337516611'
    },
    {
      title: 'vk',
      img: '/img/social/vk-green.png',
      href: 'https://vk.com/doct24'
    },
  ]

  const socialTest = socialNetwork.map((social) => {
    return(
        <SocialsListItem key={social.title}>
          <SocialsLink href={social.href}>
            <img src={social.img} alt={social.title}/>
          </SocialsLink>
        </SocialsListItem>
    )
  })

  return (
      <>
        <SocialsStl>
          <SocialsList>
            {socialTest}
          </SocialsList>
        </SocialsStl>
      </>
  );
};

export default Socials;
