import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledCarouselReview } from './carouselReview.styled';
import { Link } from 'react-router-dom';
import { fetchPortalReviews, resetState } from '../../newReviews/api/reviewsSlice';
import { ReviewCard } from '../../doctorProfile/doctorReviews/reviewCard/ReviewCard';
import dataCardDTO from '../../newReviews/reviewsList/dataCardDTO';
import useObserver from '../../../hooks/useObserver';

function CarouselReview() {
    const reviews = useSelector((state) => state.portalReviews.reviews);
    const dispatch = useDispatch();
    const selector = 'reviews';
    const {show, setWatch} = useObserver(selector);
    const reviewsSectionRef = useRef();


    useEffect(() => {
        if(reviewsSectionRef.current && !show) {
            setWatch(true);
        }
        if(show) {
            dispatch(fetchPortalReviews());
        }
        return () => {
            dispatch(resetState());
        };
    }, [reviewsSectionRef.current, show]);

    return (
        <StyledCarouselReview className={selector} ref={reviewsSectionRef}>
            {reviews.length !== 0 && (
                    <>
                    <h2 className="reviews__title">Что о нас говорят</h2>
                    <div className='reviews__container'>
                    <ul className="reviews__list">
                        {reviews.map((review, index) => {
                          if(index > 2) {
                            return null
                          }
                            return <ReviewCard key={review.id} review={dataCardDTO(review)} />;
                        })}
                    </ul>
                    </div>
                
            <Link to={'/reviews'} className="reviews__link">
                Смотреть больше отзывов
            </Link>
            </>
            )}
        </StyledCarouselReview>
        
    );
}
export default CarouselReview;
