import styled from 'styled-components';

export const DoctorPriseStl = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    form {
        width: 100%;
    }

    svg {
        cursor: pointer;
    }

    form svg {
        position: absolute;
        top: 10px;
        right: 16px;
    }

    .info__wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &-training {
            width: 100%;
        }
    }
    
    .info__wrapper svg {
        margin-left: 10px;
        display: none;

        @media(max-width: 1000px) {
            display: block;
        }
    }

    .info__data {
        font-size: 16px;
        line-height: 150%;
        color: #212121;
        @media (max-width: 600px) {
            font-size: 14px;
        }
        &-training {
            display: flex;
            justify-content: space-between;
            width: 100%;
            > img {
                width: 50px;
                cursor: pointer;
            }
        }
        &_uppercase::first-letter {
            text-transform: uppercase
        }
    }

    .btn__recive {
        margin-top: 60px;
        padding: 16px 32px;
        border-radius: 10px;
        border: 2px solid #19be6f;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #fff;
        background-color: #19be6f;
        transition: opacity 0.3s linear;
        width: 100%;
        cursor: pointer;
        :hover {
            opacity: 0.7;
        }
        &-disabled {
            text-align: center;
        }
        &:disabled {
            opacity: 0.7;
            cursor: auto;
        }
    }
`;

export const TitleWithPencilStl = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title {
        font-size: 24px;
        font-weight: 500;
        color: #212121;

        @media(max-width: 800px) {
            font-size: 22px;
        }

        @media(max-width: 600px) {
            font-size: 20px;
        }
    }
    .title__price {
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #212121;

        @media(max-width: 600px) {
            font-size: 12px;
            line-height: 15.6px;
        }
    }
    .title__price::after {
        content: "*";
        color: #FF3636;
    }

`;
