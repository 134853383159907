import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDoctorPatients } from '../api/doctorPatientsSlice';
import { Loader } from '../../loader/loader';
import { ReactComponent as ArrowUp } from '../../../img/icons/newDoctorIcons/up-arrow.svg';
import PatientCard from './patientCard/PatientCard';
import { Grid } from './patients.stlyled';

export const PatientList = () => {
    const dispatch = useDispatch();

    const patients = useSelector((state) => state.doctorPatients.patients);
    const isLoading = useSelector((state) => state.doctorPatients.isLoading);
    const next = useSelector((state) => state.doctorPatients.next);

    if (isLoading && !patients.length)
        return (
            <div className="loader">
                <Loader />
            </div>
        );

    return (
        <>
            {patients.length ? (
                <>
                    <Grid>
                        {patients.map((item) => (
                            <li key={item.user.uid}>
                                <PatientCard patient={item} />
                            </li>
                        ))}
                    </Grid>
                    {isLoading && (
                        <div className="loader">
                            <Loader />
                        </div>
                    )}
                    <div className="btns">
                        {next !== null && (
                            <span
                                onClick={() => {
                                    dispatch(fetchDoctorPatients());
                                }}
                            >
                                Показать ещё
                            </span>
                        )}
                        <span
                            onClick={() => {
                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                            }}
                        >
                            Наверх
                            <ArrowUp />
                        </span>
                    </div>
                </>
            ) : (
                <h2 className="patientNo__title">Пациентов нет</h2>
            )}
        </>
    );
};
