import styled from 'styled-components';
import FeedBack from '../feedback/feedBack';

export const StyledCarouselReview = styled.section`
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 104px;
    max-width: 1070px;
    margin: 0 auto;

    .reviews__title {
        font-family: 'Rubik';
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        @media (max-width: 768px) {
            font-size: 36px;
            line-height: 120%;
        }
        @media (max-width: 500px) {
            font-size: 28px;
            line-height: 110%;
            margin-bottom: 40px;
        }
    }

    .reviews__container {
        padding-top: 60px;
        padding-bottom: 35px;
        overflow: hidden;
        @media (max-width: 768px) {
            padding-bottom: 25px;
        }
        @media (max-width: 500px) {
            padding-top: 0px;
            padding-bottom: 0px;
            height: auto;
        }
    }

    .reviews__list {
        display: flex;
        flex-direction: row;
        width: max-content;
        column-gap: 20px;
        > div {
            border-radius: 20px;
            background: #f4f4f4;
            width: 510px;
            padding: 28px;
        }
        @media (max-width: 500px) {
            position: relative;
            padding-top: 40px;
            padding-bottom: 30px;
            width: 100%;
            flex-direction: column;
            row-gap: 16px;
            > div {
                width: 100%;
                padding: 16px;
            }
        }
    }
    
    .docReview__card {
        width: 100%;
        overflow: hidden;
        /* блок с аватаркой */
        > div:first-child {
            width: 80px;
            height: 80px;
            gap: 16px;
            @media (max-width: 550px) {
                gap: 12px;
            }
            > div > img {
                width: 80px;
                height: 80px;
            }
        }
    }

    .docReview__card_header {
        justify-content: flex-start;
        row-gap: 8px;
        @media (max-width: 550px) {
            overflow: hidden;
        }
    }

    .docReview__card_patient-name {
        font-size: 20px;
        @media (max-width: 550px) {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .docReview__card_date {
        display: none;
    }

    .docReview__card_body {
        margin: 0;
        height: 96px;
        @media (max-width: 550px) {
            min-height: 85px;
            height: auto;
        }
    }

    .docReview__card_description {
        margin: 0;
        /* Обрезка более 4 строк */
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media (max-width: 550px) {
            font-size: 13px;
            -webkit-line-clamp: 6;
        }
    }

    .reviews__link {
        cursor: pointer;
        color: var(--primary-accent, #19be6f);
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;
