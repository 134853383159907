import React, { useState } from 'react';
import { VacanciesFormStl } from './vacanciesForm.styled';
import InputTel from '../../share/formFields/newElements/InputTel';
import InputEmail from '../../share/formFields/newElements/InputEmail';

import AgreementField from '../../share/formFields/AgreementField';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import InputText from '../../share/formFields/newElements/InputText';

export const VacanciesForm = () => {
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            specialization: '',
            phoneNumber: '',
            email: '',
            checkbox: false,
        },
    });
    const { isValid, isSubmitting } = useFormState({ control: methods.control });
    const onSubmit = (data) => {
        console.log(data);
    };
    return (
        <VacanciesFormStl>
            <h2 className="vacancies__title">Связаться со мной</h2>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <InputText
                        name="name"
                        placeholder="Иванов"
                        label="Как Вас называть"
                        validation={{
                            required: { value: true, message: 'Поле обязательно для заполнения' },

                            maxLength: {
                                value: 152,
                                message: 'Максимальная длина: 152 символа',
                            },
                            minLength: {
                                value: 2,
                                message: 'Минимальная длина: 2 символа',
                            },
                        }}
                        isRequired={true}
                    />
                    <InputText
                        name="specialization"
                        placeholder="Невролог"
                        label="Специальность"
                        validation={{
                            maxLength: {
                                value: 100,
                                message: 'Максимальная длина: 100 символов',
                            },
                            minLength: {
                                value: 2,
                                message: 'Минимальная длина: 2 символа',
                            },
                            required: { value: true, message: 'Поле обязательно для заполнения' },
                        }}
                        isRequired={true}
                    />
                    <InputTel
                        name="phoneNumber"
                        label="Номер телефона"
                        placeholder="+7 (999) 999-99-99"
                        isRequired={true}
                    />
                    <InputEmail
                        name="email"
                        label="Email"
                        placeholder="Example@gmail.com"
                        validation={{
                            required: { value: true, message: 'Поле обязательно для заполнения' },
                        }}
                        isRequired={true}
                    />
                    <AgreementField name="checkbox" />
                    <button className="btn" disabled={!isValid || isSubmitting}>
                        Отправить
                    </button>
                </form>
            </FormProvider>
        </VacanciesFormStl>
    );
};
