import styled from 'styled-components';

export const ReviewCardStl = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    .docReview__card {
        display: flex;
        gap: 19px;
        &_header {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap:7px;
            > div {
                display: flex;
                gap: 16px;
            }
        }

        // &_patient-name {
        //     margin-bottom: 7px;
        // }

        &_date {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #666666;
        }
        &_body {
            margin: 0 0 0 13px;
            width: 100%;
        }
        &_description {
            overflow-wrap: anywhere;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            margin: 6px 32px 0 0;
            text-align: justify;
        }
    }
`;
