import styled from 'styled-components';

export const DoctorNavigationStl = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 40px;
    max-width: 312px;
    .link {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-decoration: none;
        color: #212121;
        border-bottom: 1px solid #b6b7ba;
        text-align: center;
        cursor: pointer;
        height: 32px;
        &:not(:first-child) {
            white-space: nowrap;
        }
    }
    .chosen {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #19be6f;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }
    }

    @media (max-width: 620px) {
        margin-bottom: 24px;
        height: auto;
        .link {
            font-size: 16px;
        }
    }
    @media (max-width: 575px) {
        max-width: none;
    }
`;
