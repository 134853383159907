import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

const BackgroundModalWindow = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 3000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    padding: 10px;
`;
const Content = styled.div`
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    overflow: auto;
`;

export const Modal = ({ toggle, autoClose = 0, children }) => {
    const listenersStackRef = useRef([]);

    if (autoClose) {
        setTimeout(() => toggle(false), autoClose);
    }

    const closeModal = (e) => {
        e.stopPropagation();
        if (e.keyCode === 27) {
            toggle(false);
            listenersStackRef.current = listenersStackRef.current.slice(0, -1); // удаляем последний слушатель из стека
        }
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        listenersStackRef.current = [...listenersStackRef.current, closeModal]; // добавляем слушатель в стек при открытии модального окна
        // console.log(listenersStackRef.current, children); 
    }, []);

    useEffect(() => {
        const lastListener = listenersStackRef.current[listenersStackRef.current.length - 1]; // получаем последний слушатель из стека
        window.addEventListener('keydown', lastListener);

        return () => {
            window.removeEventListener('keydown', lastListener);
            document.body.style.overflow = 'visible';
        };
    }, []);

    return (
        <BackgroundModalWindow
            onClick={(e) => {
                e.stopPropagation();
                toggle(false);
                listenersStackRef.current = listenersStackRef.current.slice(0, -1); // удаляем последний слушатель из стека при закрытии модального окна
            }}
        >
            <Content onClick={(e) => e.stopPropagation()}> {children} </Content>
        </BackgroundModalWindow>
    );
};
