import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../img/Logo.svg';
import {
    // fetchGetFutureConsultations,
    // fetchGetPastConsultations,
    getPatientConsultation,
    setIsModalSuccessfulConsultationPatient,
} from '../../componets/lkPatient/api/PatientConsultationsSlice';
import {
    fetchConsultation,
    // fetchGetDoctorFutureConsultations,
    // fetchGetDoctorPastConsultations,
    setIsModalSuccessfulConsultationDoctor,
} from '../../componets/lkDoctor/api/lkDoctorConsultationsSlice';
import { Spinner } from '../../componets/share/Spinner';
import { changeStatusOfConsultationToPassedRequest } from '../../componets/lkDoctor/api/api';
import './videoChatJitsiPage.styled.js';
import { calculateElapsedTime } from '../../componets/share/helpers.js';
import { fetchUpcomingConsultation } from '../../componets/timerConsultation/api/timerSlice.js';

export default function VideoChatJitsi() {
    const DOMAIN = 'videocall.dev.doct24.com';
    const TIME_OF_SUCCESFULL_CONSULTATIOIN = 60;

    const [currentConsiliumId, setCurrentConsiliumId] = useState(false);
    const [fullName, setFullName] = useState('');
    const [avatar, setAvatar] = useState('');
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());

    const { id: consultationId } = useParams();
    const patientConsultations = useSelector((state) => state.patientConsultations);
    const doctorConsultations = useSelector((state) => state.doctorConsultations);
    const keyPatient = useSelector((state) => state.patientConsultations.keyConsultationPatient);
    const keyDoctor = useSelector((state) => state.doctorConsultations.keyConsultationDoctor);
    const role = useSelector((state) => state.authentification.whoAreYou);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (role === 'patient') {
            dispatch(getPatientConsultation(consultationId));
        } else if (role === 'doctor') {
            dispatch(fetchConsultation(consultationId));
        }
    }, [role]);

    useEffect(() => {
        const elapsedTime = calculateElapsedTime(startTime, endTime);
        if (elapsedTime > TIME_OF_SUCCESFULL_CONSULTATIOIN) {
            changeStatusOfConsultationToPassedRequest(consultationId)
                .then(() => {
                    dispatch(fetchUpcomingConsultation(role));
                })
                .then(()=>{
                    // if (role === 'doctor') {
                        dispatch(setIsModalSuccessfulConsultationDoctor(elapsedTime));
                    // }
                    // if (role === 'patient') {
                        dispatch(setIsModalSuccessfulConsultationPatient(elapsedTime));
                    // }
            });
        }
    }, [endTime]);

    useEffect(() => {
        const key = role === 'patient' ? keyPatient : keyDoctor;
        if (key) {
            setCurrentConsiliumId(key);
        }
    }, [role, keyPatient, keyDoctor]);

    useEffect(() => {
        const consultation = role === 'patient' ?
            patientConsultations.patientConsultations.future.consultations.find((item)=>item.security_key === currentConsiliumId) :
            doctorConsultations.doctorConsultations.future.consultations.find((item)=>item.security_key === currentConsiliumId);
        const user = role === 'patient' ?
            consultation?.patient?.user :
            consultation?.doctor;
        setFullName(`${user?.last_name} ${user?.first_name} ${user?.patronymic}`);
        setAvatar(user?.avatar);
    }, [currentConsiliumId]);

    const jitsiConference = (
        <JitsiMeeting
            domain={DOMAIN}
            roomName={currentConsiliumId}
            userInfo={{
                displayName: fullName || '',
                // avatarUrl: avatar || '',
            }}
            configOverwrite={{
                startWithAudioMuted: true,
                disableModeratorIndicator: true,
                startScreenSharing: true,
                enableEmailInStats: false,
                toolbarButtons: [
                    'hangup',
                    'microphone',
                    'recording',
                    'desktop',
                    'camera',
                    'fullscreen',
                    'chat',
                    'tileview',
                ],
                disableTileView: false,
                disableResponsiveTiles: true,
            }}
             interfaceConfigOverwrite={{
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                SHOW_JITSI_WATERMARK: false,
                HIDE_DEEP_LINKING_LOGO: true,
                SHOW_BRAND_WATERMARK: false,
                DEFAULT_LOGO_URL: logo,
                // DEFAULT_LOGO_URL: {logo},
                // DEFAULT_WELCOME_PAGE_LOGO_URL: {logo},
                // DEFAULT_BACKGROUND: '#fff',
                TILE_VIEW_MAX_COLUMNS: 4,
            }}
            onApiReady={(externalApi) => {
                externalApi.on('participantJoined', (e) => {
                    setStartTime(Date.now());
                });
                externalApi.on('participantLeft', (e) => {
                    setEndTime(Date.now());
                });
            }}
            onReadyToClose={() => {
                navigate(-1);
            }}
            getIFrameRef={(iframeRef) => {
                iframeRef.style.height = '600px';
            }}
        />
    );
    return (
        <>
            <div>{currentConsiliumId ? jitsiConference : <Spinner />}</div>
        </>
    );
}
