import { NavLink, useLocation } from 'react-router-dom';
import React, { useState } from 'react';

import ArrowIcon from '../../share/ArrowIcon';
import { MainMenuStyled } from './mainMenu.styled';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

export const MainMenu = ({ naviData = [] }) => {
    const lastName = useSelector((state) => state.authentification.user?.last_name);
    return (
        <>
            {naviData.map((el) => (
                <MenuItem key={el.key} menuItem={el} lastName={lastName} />
            ))}
        </>
    );
};

const MenuItem = ({ menuItem, lastName }) => {
    const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

    const [subMenuVisible, setSubMenuVisible] = useState(false);
    const hasChildren = menuItem.children ? true : false;

    const menuRef = useRef(null);
    const { pathname } = useLocation();

    useEffect(() => {
        if (menuRef.current) {
            setSubMenuVisible(menuRef.current.classList.contains('active'));
        }
    }, [pathname]);

    const toggleSubMenu = () => {
        setSubMenuVisible(!subMenuVisible);
    };

    return (
        <>
            {lastName ? (
                <MainMenuStyled>
                    {isTablet ? (
                        <>
                            {hasChildren ? (
                                <span onClick={toggleSubMenu} className="nav-link nav-link__head-link nav-link--static">
                                    {menuItem.title}
                                    <ArrowIcon isOpen={subMenuVisible} />
                                </span>
                            ) : (
                                <NavLink
                                    ref={menuRef}
                                    className="nav-link nav-link__head-link"
                                    key={menuItem.key}
                                    to={menuItem.linkPathTo}
                                >
                                    {menuItem.title}
                                </NavLink>
                            )}
                        </>
                    ) : (
                        <NavLink
                            ref={menuRef}
                            className="nav-link nav-link__head-link"
                            key={menuItem.key}
                            to={menuItem.linkPathTo}
                        >
                            {menuItem.title}
                        </NavLink>
                    )}
                    {isTablet ? (
                        <>
                            {hasChildren && subMenuVisible && (
                                <div className="nav-link__sub-link">
                                    <MainMenu naviData={menuItem.children} lastName={lastName} />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {hasChildren && subMenuVisible && (
                                <div className="nav-link__sub-link">
                                    <MainMenu naviData={menuItem.children} lastName={lastName} />
                                </div>
                            )}
                        </>
                    )}
                </MainMenuStyled>
            ) : (
                <MainMenuStyled>
                    {menuItem.title !== 'Профиль' && menuItem.title !== 'Настройки' ? (
                        <span className="nav-link nav-link__head-link nav-link--static nav-link--disabled">
                            {menuItem.title}
                        </span>
                    ) : (
                        <NavLink
                            ref={menuRef}
                            className="nav-link nav-link__head-link"
                            key={menuItem.key}
                            to={menuItem.linkPathTo}
                        >
                            {menuItem.title}
                        </NavLink>
                    )}
                </MainMenuStyled>
            )}
        </>
    );
};

// const MenuItem = ({ menuItem, lastName }) => {
//     const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

//     const [subMenuVisible, setSubMenuVisible] = useState(false);
//     const hasChildren = menuItem.children ? true : false;

//     const menuRef = useRef(null);
//     const { pathname } = useLocation();

//     useEffect(() => {
//         if (menuRef.current) {
//             setSubMenuVisible(menuRef.current.classList.contains('active'));
//         }
//     }, [pathname]);

//     const toggleSubMenu = () => {
//         setSubMenuVisible(!subMenuVisible);
//     };

//     return (
//         <>
//             {lastName ? (
//                 <MainMenuStyled>
//                     {isTablet ? (
//                         <>
//                             <span
//                                 onClick={toggleSubMenu}
//                                 className={`nav-link nav-link__head-link ${hasChildren ? 'nav-link--static' : ''}`}
//                             >
//                                 {menuItem.title}
//                             </span>
//                         </>
//                     ) : (
//                         <NavLink
//                             ref={menuRef}
//                             className="nav-link nav-link__head-link"
//                             key={menuItem.key}
//                             to={menuItem.linkPathTo}
//                         >
//                             {menuItem.title}
//                         </NavLink>
//                     )}
//                     {hasChildren && subMenuVisible && (
//                         <div className="nav-link__sub-link">
//                             <MainMenu naviData={menuItem.children} lastName={lastName} />
//                         </div>
//                     )}
//                 </MainMenuStyled>
//             ) : (
//                 <MainMenuStyled>
//                     {menuItem.title !== 'Профиль' && menuItem.title !== 'Настройки' ? (
//                         <span className="nav-link nav-link__head-link nav-link--static nav-link--disabled">
//                             {menuItem.title}
//                         </span>
//                     ) : (
//                         <NavLink
//                             ref={menuRef}
//                             className="nav-link nav-link__head-link"
//                             key={menuItem.key}
//                             to={menuItem.linkPathTo}
//                         >
//                             {menuItem.title}
//                         </NavLink>
//                     )}
//                 </MainMenuStyled>
//             )}
//         </>
//     );
// };
