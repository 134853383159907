import React from 'react';
import { StyledConsultationTime, CardConsultationTime } from './consultationTime.styled';

import { getDateForConsultation, getTimeFromDate } from '../helpers';

export const ConsultationTimeAndPrice = ({ date, price, card, priceDisplay, isModal }) => {
    return (
        <StyledConsultationTime card={card} isModal={isModal}>
            <div className="dateAndTime">
                <div className="date">
                    <img src="/img/icons/calendar.png" alt="calendar" />
                    <span className="dateValue">{getDateForConsultation(date)}</span>
                </div>
                <div className="time">
                    <img src="/img/icons/clock.png" alt="clock" />
                    <span className="timeValue">{getTimeFromDate(date)}</span>
                </div>
                {!isModal && (
                    <div className="time">
                        <img src="/img/icons/hourglass-03.png" alt="time" />
                        <span className="timeValue">30 мин</span>
                    </div>
                )}
                {priceDisplay === true ? (
                    <>
                        {price && (
                            <div className="time">
                                <img src="/img/icons/currency-rubel.png" alt="price" />
                                <span className="timeValue">{price}</span>
                            </div>
                        )}
                    </>
                ) : null}
            </div>
        </StyledConsultationTime>
    );
};
