import styled from 'styled-components';

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    border: 1px solid #f4f4f4;
    transition: .3s background-color;
    :hover {
        background-color: #f4f4f4;
        cursor: pointer;
    }

    .card {
        &__img {
            width: 100%;
            height: 245px;
            overflow: hidden;
            object-fit: cover;
        }
        &__content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            margin: 16px;
        }
        &__name {
            width: 100%;
            justify-self: flex-start;
            margin-bottom: 12px;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            color: #212121;
            word-wrap: break-word;
        }
        &__genderAge {
            width: 100%;
            margin-top: auto;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #666666;
        }
    }

    @media (max-width: 850px) {
        .card {
            &__img {
                height: 239px;
            }
            &__name {
                line-height: 130%;
            }
        }
    }

    @media (max-width: 450px) {
        .card {
            &__img {
                height: 288px;
            }
            &__name {
                line-height: 120%;
                margin-bottom: 8px;
            }
            &__genderAge {
                line-height: 130%;
            }
        }
    }
`;