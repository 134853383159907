import styled from 'styled-components';

export const AttentionStl = styled.p`
    position: relative;
    padding-left: 17px;
    text-align: left;
    font-size: 16px;
    line-height: 150%;
    color: #212121;
    &:before {
        position: absolute;
        content: '!';
        top: 0;
        left: 0;
        color: #ff3636;
        font-size: 24px;
        font-weight: 500;
    }

    @media (max-width: 768px) {
        line-height: 130%;
    }

    @media (max-width: 420px) {
        font-size: 12px;
    }
`;
