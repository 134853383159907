import styled from 'styled-components';

export const StyledFeedBack = styled.div`
    width: 1570px;
    padding-top: 60px;
    padding-bottom: 35px;
    display: block;
    justify-content: center;
    align-items: start;
    @media (max-width: 550px) {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 30px;
    }

    & .slick-list {
        overflow: visible;
        @media (max-width: 550px) {
            overflow: hidden;
        }
        .slick-slide {
            > div {
                border-radius: 20px;
                background: #f4f4f4;
                width: 510px;
                padding: 28px;
                margin-left: 10px;
                margin-right: 10px;
                @media (max-width: 550px) {
                    margin-left: 0;
                    margin-right: 0;
                    padding: 16px;
                    width: 100%;
                    border: none;
                    border-top: 16px solid #fff;
                    > div {
                        gap: 12px;
                    }
                }
            }

            .docReview__card {
                width: 100%;
                overflow: hidden;
                /* блок с аватаркой */
                > div:first-child {
                    width: 80px;
                    height: 80px;
                    gap: 16px;
                    @media (max-width: 550px) {
                        gap: 12px;
                    }
                    > div > img {
                        width: 80px;
                        height: 80px;
                    }
                }
            }
            .docReview__card_header {
                justify-content: flex-start;
                row-gap: 8px;
                @media (max-width: 550px) {
                    overflow: hidden;
                }
            }
            .docReview__card_patient-name {
                font-size: 20px;
                @media (max-width: 550px) {
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
            .docReview__card_date {
                display: none;
            }

            .docReview__card_body {
                margin: 0;
                height: 96px;
                @media (max-width: 550px) {
                    min-height: 85px;
                    height: auto;
                }
            }
            .docReview__card_description {
                margin: 0;
                /* Обрезка более 4 строк */
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                @media (max-width: 550px) {
                    font-size: 13px;
                    -webkit-line-clamp: 6;
                }
            }
        }
    }
`;
