import { Link, useNavigate } from 'react-router-dom';
import { LogoutButtonStl, StyledHeader } from './header.styled';
import React, { useEffect, useState } from 'react';
import { fetchMeUser, setAuth, setWhoAreYou } from '../../authentification/api/authentificationSlice';
import { fetchUpcomingConsultation, showTimer } from '../../timerConsultation/api/timerSlice';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from '../avatar/avatar';
import MobileMenu from '../mobileMenu/MobileMenu';
import { Modal } from '../../share/Modal';
import Navbar from '../navbar/navbar';
import PopUpConfirm from '../../share/PopUpConfirm';
import SearchButton from '../search/searchButton';
import SearchInput from '../search/searchInput';
import TimerConsultation from '../../timerConsultation/timerConsultationUI/TimerConsultation';
import { logoutDoctor } from '../../lkDoctor/api/doctorUserProfileSlice';
import { logoutPatient } from '../../lkPatient/api/PatientProfileSlice';
import { useMediaQuery } from 'react-responsive';

function Header() {
    // 30 минут (через сколько отключится таймер после начала консультации)
    const MAX_TIME_CONSULTATION = 1800;
    // 15 минут (до начала консультации)
    const START_TIMER_AFTER_SECONDS = 900;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSearchActive, setSearchActive] = useState(false);
    const { isAuth, isEmail, isEmailCheck, user, whoAreYou } = useSelector((state) => state.authentification);
    const isMob = useMediaQuery({ query: '(max-width: 700px)' });
    const { upcomingConsultation, isShowTimer } = useSelector((state) => state.timer);

    const [popUpExitProfile, setPopUpExitProfile] = useState(false);

    const handleLogOut = async () => {
        await dispatch(whoAreYou === 'patient' ? logoutPatient() : logoutDoctor());
        setPopUpExitProfile(false);
        navigate('/');
    };

    const clearIntervalAndHideTimer = (interval) => {
        clearInterval(interval);
        dispatch(showTimer(false));
    };

    useEffect(() => {
        if (isAuth && whoAreYou) {
            dispatch(fetchUpcomingConsultation(whoAreYou));
        }
    }, [isAuth, whoAreYou]);

    // useEffect(()=>{
    //     localStorage.clear();
    // })
    useEffect(() => {
        if (localStorage.getItem('refresh') && isEmail) {
            dispatch(fetchMeUser());
            dispatch(setAuth(true));
        } else {
            dispatch(setAuth(false));
            // navigate('/')
        }
    }, [localStorage.getItem('refresh')]);

    useEffect(() => {
        dispatch(setWhoAreYou(user?.role));
    }, [isAuth, user]);

    useEffect(() => {
        const calculateTimeBeforeConsultation = () => {
            const nowInSec = Math.floor(Date.now() / 1000);
            const timeDifference = Math.floor(upcomingConsultation?.date - START_TIMER_AFTER_SECONDS - nowInSec);

            if (timeDifference <= 0) {
                dispatch(showTimer(true));
                clearInterval(intervalId);
            }
        };

        let intervalId;

        const startInterval = () => {
            const nowInSec = Math.floor(Date.now() / 1000);
            const timeDifference = Math.floor(upcomingConsultation?.date - nowInSec);

            if (timeDifference > 900 && timeDifference <= 1800) {
                intervalId = setInterval(calculateTimeBeforeConsultation, 30000);
            } else if (timeDifference <= 900) {
                dispatch(showTimer(true));
            }
        };

        startInterval();

        if (!isAuth) {
            clearIntervalAndHideTimer(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [upcomingConsultation, document.hidden]);

    useEffect(() => {
        const nowInSec = Math.floor(Date.now() / 1000);
        const consultationTime = upcomingConsultation?.date;
        const secAfterConsultation = consultationTime + MAX_TIME_CONSULTATION;
        // если до консультации осталось 15 минут или меньше:
        const conditionBeforeStartCons =
            consultationTime - nowInSec > 0 && consultationTime - nowInSec <= START_TIMER_AFTER_SECONDS;
        // если консультация началась и еще не закончилась:
        const conditionAfterStartCons =
            secAfterConsultation - nowInSec > 0 && MAX_TIME_CONSULTATION >= secAfterConsultation - nowInSec;

        if (conditionBeforeStartCons || conditionAfterStartCons) {
            dispatch(showTimer(true));
        }
    }, [upcomingConsultation, document.hidden]);

    const showSearch = (isSearchActive, isAuth) => {
        return (
            <>
                {!isSearchActive ? (
                    <>
                        <Navbar />
                        {isShowTimer && (
                            <TimerConsultation
                                clearIntervalAndHideTimer={clearIntervalAndHideTimer}
                                MAX_TIME_CONSULTATION={MAX_TIME_CONSULTATION}
                                START_TIMER_AFTER_SECONDS={START_TIMER_AFTER_SECONDS}
                            />
                        )}

                        <div className="search-container">
                            <SearchButton isSearchActive={isSearchActive} setSearchActive={setSearchActive} />
                            {showAvatar(isAuth)}
                        </div>
                    </>
                ) : (
                    <>
                        {isShowTimer && (
                            <TimerConsultation
                                clearIntervalAndHideTimer={clearIntervalAndHideTimer}
                                MAX_TIME_CONSULTATION={MAX_TIME_CONSULTATION}
                                START_TIMER_AFTER_SECONDS={START_TIMER_AFTER_SECONDS}
                            />
                        )}

                        <div className="search-container">
                            <SearchInput isSearchActive={isSearchActive} setSearchActive={setSearchActive} />
                            {showAvatar(isAuth)}
                        </div>
                    </>
                )}
            </>
        );
    };

    const showAvatar = (isAuth) => {
        return isAuth ? (
            <>
                <Avatar role={user?.role} avatar={user?.avatar} />
                <LogoutButtonStl onClick={() => setPopUpExitProfile(true)}>
                    <img src="/img/header/exit.svg" alt="logout" />
                </LogoutButtonStl>
            </>
        ) : (
            <Link className="header__signin-link" to={'login'}>
                {' '}
                Войти{' '}
            </Link>
        );
    };

    if (isMob) {
        return (
            <StyledHeader>
                <div className="header__inner">
                    <Link to={'/'}>
                        {' '}
                        <img src="/img/header/headerLogo.svg" alt="header logo" width="123" />{' '}
                    </Link>
                    <MobileMenu user={user} />
                </div>
                {isShowTimer && (
                    <TimerConsultation
                        clearIntervalAndHideTimer={clearIntervalAndHideTimer}
                        MAX_TIME_CONSULTATION={MAX_TIME_CONSULTATION}
                        START_TIMER_AFTER_SECONDS={START_TIMER_AFTER_SECONDS}
                    />
                )}
            </StyledHeader>
        );
    }

    return (
        <StyledHeader>
            <div className="header__inner">
                <Link to={'/'}>
                    {' '}
                    <img src="/img/header/headerLogo.svg" alt="header logo" width="123" />{' '}
                </Link>
                {showSearch(isSearchActive, isAuth)}
            </div>
            {popUpExitProfile && (
                <Modal toggle={setPopUpExitProfile}>
                    <PopUpConfirm
                        onClose={() => setPopUpExitProfile(false)}
                        onConfirm={() => handleLogOut()}
                        contentHeader={
                            <span>
                                Вы уверены, что хотите <br /> выйти из личного кабинета?
                            </span>
                        }
                        isClarification={false}
                    />
                </Modal>
            )}
        </StyledHeader>
    );
}

export default Header;
