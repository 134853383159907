import { apiRequest, fileLoader } from '../../share/api';

// эта функция не будет использоваться. мы её заменяем на аналогичную для user-a
/**
 * Функция для редактирования данных пациента с использованием метода PATCH.
 * @async
 * @function patientDataUpdate
 * @param {Object} data - Данные для обновления профиля пациента.
 * @param {string} data.id - Уникальный идентификатор пациента.
 * @param {string} data.first_name - Имя пациента.
 * @param {string} data.last_name - Фамилия пациента.
 * @param {string} [data.middle_name] - Отчество пациента.
 * @param {string} data.date_birth - Дата рождения пациента в формате YYYY-MM-DD.
 * @param {string} data.sex - Пол пациента, например, 'М' или 'Ж'.
 * @param {string} data.email - Электронная почта пациента.
 * @param {string} [data.city] - Город пациента.
 * @returns {Promise<Object>} Обновлённые данные пациента.
 * @throws {Error} Если запрос не был успешным или произошла ошибка при выполнении.
 * @example
 * const updatedData = {
 *   id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
 *   username: 'vasia',
 *   phone: '+79167775544'
 *   first_name: 'Василий',
 *   last_name: 'Иванов',
 *   middle_name: 'Михайлович',
 *   date_birth: '1990-01-01',
 *   sex: 'М',
 *   email: 'ivanov@example.com',
 * };
 */
export const patientDataUpdate = async (data) => {
    try {
        const response = await apiRequest.patch('/users/partial_update/', {
            id: data.id,
            username: data.username,
            phone: data.phone,
            first_name: data.first_name,
            last_name: data.last_name,
            middle_name: data.patronymic,
            date_birth: data.birthday,
            sex: data.gender,
            email: data.email,
        });

        if (response.status !== 200) {
            throw new Error(response.status, 'Ошибка при редактировании данных пациента');
        }

        const updatedPatient = await response.data;
        return updatedPatient;
    } catch (error) {
        console.error(error.response.data.code);
        return error.response ? error.response : { message: error.message };
    }
};

// ------------------------------------------------------------------------------

//консультации, пока не готовы
export const consultations = async ({ status = 0, date = 'date', next = null }) => {
    if (next) {
        return await apiRequest.get(next);
    }
    const result = await apiRequest.get(`/consultations/?status=${status}&ordering=${date}`);
    return result;
};

// отправка файла для консультации по ее id
export const consultationSendFile = async ({ consultationId, file }) => {
    await fileLoader.post(`/consultations/${consultationId}/files/`, { file });
};

// удаление файла из консультации по id файла
export const consultationDeleteFile = async (id) => {
    await apiRequest.delete(`/consultations/files/${id}/`);
};

// получение консультации пациента по ее id
export const getConsultation = async (consultationId) => {
    return await apiRequest.get(`/consultations/${consultationId}`);
};

/* Отмена консультации пациентом */
export const setClosePatientConsultation = async (id) => {
    return await apiRequest.patch(`/consultations/cansel/${id}`);
};

export const consultationUpdate = async (id, data) => {
    console.log(id, data, '+updateConsult');
    return await apiRequest.patch(`/consultations/${id}`, { ...data });
};

export const consultationDelete = async (id) => {
    console.log(id, 'deletingConsult');
    return await apiRequest.delete(`/consultations/${id}/`);
};

//------------------------------------------------------------------------------
// проверить, будем ли использовать
export const getFavoriteDoctors = async () => {
    //return await apiRequest.get('/patients/favorities/');
    return await apiRequest.get('/doctors/favorites/by_patient/?page_size=10');
};
// export const setFavoriteDoctors = async (id) => {
//    return await apiRequest.post('/patients/favorities/', { doctor_uid: id });
// 	//return await apiRequest.post('/doctors/favorites/by_patient/', { doctor_uid: id });
// };

export const setFavoriteDoctors = async (id) => {
    return await apiRequest.post('/doctors/favorites/by_patient/', { doctor_uid: id });
};

export const removeFavoriteDoctors = async (id) => {
    //return await apiRequest.delete(`/patients/favorities/${id}/`);
    return await apiRequest.delete(`/doctors/favorites/by_patient/${id}/`);
};

export const removeFavoriteDoctorsUid = async (uid) => {
    return await apiRequest.delete(`/doctors/favorites/by_doctor/${uid}`);
};

export const getAllDoctors = async (data) => {
    return await apiRequest.get(`/doctors/?search=${data.search}&page_size=${data.page_size}`);
};

export const feedbackСonsultation = async ({ data, consultationId }) => {
    return await apiRequest.post(`/feedback/consultation/${consultationId}/`, data);
};

export const getFeedback = async (id) => {
    return await apiRequest.get(`/feedback/${id}`);
};

// ------------------------------------------------------------------------------
// для удаления, это не используется
// на этой функции что-то завязано, перед удалением - проверить
export const createPayment = async (id) => {
    console.log(id);
    return await apiRequest.post(`/payments/`, { consultation: id });
};
