import React from 'react';
import styled from 'styled-components';
import first from './GalleryImg/first.jpeg';
import second from './GalleryImg/second.jpeg';
import third from './GalleryImg/third.png';
import fourth from './GalleryImg/fourth.jpeg';
import fifth from './GalleryImg/fifth.jpeg';
import Container from '../../share/Container';
import Slider from 'react-slick';

const PhotoStl = styled.img`
    height: 300px;
    width: 300px;
    object-fit: cover;
`;

const GalrStl = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    & > div {
        max-width: 100%;
    }
`;

function Gallery() {
    const data = [first, second, third, fourth, fifth, first, second, third, fourth, fifth];
    const content = data.map((photo, index) => <PhotoStl key={index} src={photo} alt={'first'} />);
    const carouselSettings = {
        autoplay: true,
        infinite: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        pauseOnFocus: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <GalrStl>
            <Container maxWidth={'100%'} padding={'0'}>
                <Slider {...carouselSettings}>{content}</Slider>
            </Container>
        </GalrStl>
    );
}
export default Gallery;
