import React from 'react';

export const consultationStatus = (status, isDoctorCancelled) => {
    if (status === 0) {
        return <div className="statusConsultation">Онлайн-консультация</div>;
    } else if (status === 2) {
        return (
            <div className="statusConsultation red">{`${
                isDoctorCancelled ? 'Вы отменили консультацию' : 'Пациент отменил консультацию'
            }`}</div>
        );
    } else if (status === 1) {
        return <div className="statusConsultation green">Онлайн-консультация</div>;
    } else if (status === 9) {
        return <div className="statusConsultation red">Онлайн-консультация не состоялась</div>;
    }
    return !isDoctorCancelled ? 'Врач еще не подтвердил консультацию. Дождитесь подтверждения.' : 'Онлайн-консультация';
};
