import styled from 'styled-components';

export const StyledServiceCard = styled.div`
    position: relative;
    height: 130px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 250px;

    img {
        align-self: center;
        display: flex;
        width: 52px;
        height: 51.5px;
    }

    p {
        font-style: normal;
        width: 155px;
        height: 51px;
        font-size: 18px;
        font-weight: 400;
        line-height: 23px;
        display: flex;
        letter-spacing: 0;
        padding: 0 5px 0 16px;
        align-self: center;
        color: #212121;
    }

    .btn-more {
        text-transform: lowercase;
        position: absolute;
        right: 8px;
        top: 8px;
        border: none;
        color: #fff;
        background-color: #062a3f;
        border-radius: 5px;
        min-width: 82px;
        font-size: 16px;
        line-height: 20px;
        padding: 6px 18px;
    }
`;
