import React from 'react';
import { InputStl } from './checkboxStl.styled';

const Checkbox = ({ field, trigger, label }) => {
    return (
        <>
            <label>
                {label}
                <InputStl type="checkbox" {...field} onClick={() => trigger()} />
            </label>
        </>
    );
};

export default Checkbox;
