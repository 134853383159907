import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearConsultation, fetchConsultation } from '../../../api/lkDoctorConsultationsSlice';
import { DoctorConsultationPageStyled } from './doctorConsultationPage.styled';
import { consultationStatus } from '../lkDoctorConsultationHelpers';
import { ageCalculator } from '../../../../share/helpers';
import { Spinner } from '../../../../share/Spinner';
import { ConsultationButtons } from '../../consultationButtons/consultationButtons';
import { RecommendationForm } from '../../recommendationForm/recommendationForm';
import { Feedback } from './Feedback';
import { ConsultationFiles } from './consultationFiles/ConsultationFiles';
import { ConsultationTimeAndPrice } from '../../../../share/consultationTime/consultationTime';

export default function DoctorConsultationPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const currentConsultation = useSelector((state) => state.doctorConsultations.currentConsultation);

    useEffect(() => {
        dispatch(fetchConsultation(id));

        return () => {
            dispatch(clearConsultation());
        };
    }, [id]);

    if (!currentConsultation) {
        return <Spinner />;
    }

    const { status, date, recommendation, request, registration_log, price, files, feedback, feedback_id } =
        currentConsultation;
    const { last_name, first_name, patronymic, avatar, gender_label, birthday, uid } = currentConsultation.patient.user;
    let fullName = `${last_name} ${first_name} ${patronymic}`;
    const isDoctorCancelled = uid !== registration_log?.cancelled_by?.uid;

    const doctorFiles = files.filter((el) => el.owner.uid === currentConsultation.doctor.id);
    const patientFiles = files.filter((el) => el.owner.uid === currentConsultation.patient.user.uid);

    return (
        <DoctorConsultationPageStyled>
            <div onClick={() => navigate(-1)} className={'go-back-btn'}>
                Назад к списку
            </div>
            <div className="consultation-data">
                <div className="schedule">
                    {consultationStatus(status, isDoctorCancelled)}
                    <ConsultationTimeAndPrice date={date} price={price} />
                </div>
                <div className="patient-data">
                    <div className="patient-data__avatar">
                        <img src={avatar} alt="photo" />
                    </div>
                    <div className="patient-data__name-and-age">
                        <div className="patient-data__name-and-age__name">{fullName}</div>
                        <div className="patient-data__name-and-age__age">
                            {gender_label}, {ageCalculator(birthday)}
                        </div>
                    </div>
                </div>
                <div className="patient-comments">
                    <h4 className="patient-comments__title">Комментарии пациента</h4>
                    <div className="patient-comments__text">{request}</div>
                    {patientFiles.length ? <ConsultationFiles files={patientFiles} readOnly /> : ''}
                </div>
                {status === 0 && <ConsultationButtons consultation={currentConsultation} />}
                {status === 1 && recommendation.status !== 'sent' ? (
                    <>
                        <RecommendationForm
                            id={id}
                            recommendation={recommendation}
                            feedbackId={feedback_id}
                            doctorFiles={doctorFiles}
                        />
                    </>
                ) : (
                    status === 1 && (
                        <div className="patient-comments">
                            <h4 className="patient-comments__title">Рекомендации</h4>
                            <p className="patient-comments__text">{recommendation.text}</p>
                            <ConsultationFiles files={doctorFiles} readOnly />
                        </div>
                    )
                )}
                {status === 1 && <Feedback feedback={feedback} feedbackId={feedback_id} />}
            </div>
        </DoctorConsultationPageStyled>
    );
}
