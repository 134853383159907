import styled from 'styled-components';

export const ReviewsRegulationsStyled = styled.div`
	h2 {
		font-weight: 500;
		font-size: 30px;
		line-height: 120%;
		color: #06A7B3;
		text-align: center;
	}

	h3 {
		margin-left: 4px;
		text-align: justify;
		font-size: 16px;
		line-height: 130%;
		color: #000000;
		font-weight: 600;
	}

	p {
		text-align: justify;
		font-size: 16px;
		line-height: 130%;
		color: #000000;
		margin: 3px;
		margin-left: 0;
	}

	ol {
	font-size: 16px;
	line-height: 130%;
	color: #000000;
	counter-reset: item;
	// padding-left: 10px;
		.li_body{
			font-weight: 400;
		}
	}

	li {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	}
	li:before {
	content: counters(item, ".") ".";
	counter-increment: item;
	}

	.li_name{
	font-weight: 600;
	margin: 25px 0;
	&:first-of-type {
		margin-top: 0;
	}
		p {
			font-weight: 600;
		}
	}

	.li_body{
		display: block;
		//display: flex;
		// flex-wrap: nowrap;
		// align-items: baseline;
		// list-style-position: inside;
		p {
			font-weight: 400;
		}
		&::before {
			display: inline-flex;
			float: left;
			margin-right: 4px;
		}
	}
`;