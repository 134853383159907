import styled from 'styled-components';

export const StyledPayment = styled.section`
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
`;