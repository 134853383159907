import React from 'react';
import { NavLink } from 'react-router-dom';

function FooterUseful() {
    const onScroll = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div className='footer__block'>
            <h2 className="footer__title">Полезное</h2>
            <NavLink to="news" onClick={onScroll} className="footer__link">
                Блог
            </NavLink>
            <NavLink to="vacancies" onClick={onScroll} className="footer__link">
                Вакансии
            </NavLink>
            <NavLink to="reviews" onClick={onScroll} className="footer__link">
                Отзывы
            </NavLink>
            <NavLink to="politics" onClick={onScroll} className="footer__link">
                Юридическая<br />
                информация
            </NavLink>
        </div>
    );
}

export default FooterUseful;