import React from 'react';
import { RowArticleStyled } from './rowArticle.styled';
import { ReactComponent as AuthorIcon } from '../../../../img/newsImg/newsIcons/news-author-icon.svg';
import { ReactComponent as FormattedDate } from '../../../../img/newsImg/newsIcons/news-date-icon.svg';
import { ReactComponent as CommentsIcon } from '../../../../img/newsImg/newsIcons/news-comments-icon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { getDateForBlog } from '../../../share/helpers';

const maxCharacters = 170;
const minScreenWidth = 625;
const screenWidth = window.innerWidth;

export const RowArticle = ({ props, isFullTextContent }) => {
    const navigate = useNavigate();
    const { themeId } = useParams();

    function cutPre_text(text) {
        if (text.length > maxCharacters) {
            return screenWidth <= minScreenWidth ? text.substring(0, maxCharacters) + '...' : text;
        }
        return text;
    }

    function openArticle(postId) {
        navigate(`/news/${themeId}/article/${postId}`);
        window.scrollTo({ top: 0, left: 0 });
    }

    return (
        <RowArticleStyled fullSize={isFullTextContent} onClick={() => openArticle(props.id)}>
            <div className="previewPictureBlog">
                <img src={props.cover_img} alt="" />
            </div>
            <div className="blogContent">
                <div className="textContent">
                    <h2 className="contentTitle">{props.title}</h2>
                    {isFullTextContent ? <p className="contentPreText">{cutPre_text(props.pre_text)}</p> : ''}
                </div>
                <div className="bottomContentInfo">
                    <div className="infoBlock">
                        <AuthorIcon />
                        <p>{props.author}</p>
                    </div>
                    <div className="infoBlock">
                        <FormattedDate />
                        <p>{getDateForBlog(props.created_at)}</p>
                    </div>
                    {/* {props.chat?.length && (
                        <div className="infoBlock">
                            <CommentsIcon />
                            <p></p>
                        </div>
                    )} */}
                </div>
            </div>
        </RowArticleStyled>
    );
};
