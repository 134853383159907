import styled from 'styled-components';
import Form from '../../share/formFields/Form';

export const VacanciesFormStl = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;

    .vacancies__title {
        font-size: 24px;
        font-weight: 500;
        line-height: 130%;
        color: #212121;
        text-align: center;
        margin-bottom: 35px;
    }

    .btn {
        padding: 14px 24px;
        border-radius: 8px;
        border: none;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        background-color: #19be6f;
        transition: opacity 0.3s linear;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
        &:disabled {
            opacity: 0.4;
            cursor: default;
        }
    }
`;
