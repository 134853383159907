import React from 'react';
import { ArticlesNewsCardStyled } from './articlesNewsCard.styled';
import { useNavigate } from 'react-router-dom';
import { getDateForBlog } from '../../../../share/helpers';
import { ReactComponent as CalendarSvg } from '../../../../../img/icons/calendar_icon.svg';

const ArticlesNewsCard = ({ postId, img, date, title, theme }) => {
    const navigate = useNavigate();

    return (
        <ArticlesNewsCardStyled onClick={() => navigate(`/news/${theme[0].id}/article/${postId}`)}>
            <div className="imgContainer">
                <img className="articleNews_img" src={img} alt="news picture" />
                <h4 className="articleNews_title">{title}</h4>
            </div>

            <div className="articleNews_footer">
                <div className="categoryBlock">
                    <img className="folder" src="/img/icons/folder.svg" alt="folder" />
                    <span className="articleNews_theme">{theme[0]?.title}</span>
                </div>
                <div className="categoryBlock">
                    <CalendarSvg />
                    <span className="articleNews_date">{getDateForBlog(date)}</span>
                </div>
            </div>
        </ArticlesNewsCardStyled>
    );
};

export default ArticlesNewsCard;
