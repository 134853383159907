import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { NewFormSTL } from './newFormSTL.styled';
import { TrainigList } from '../../shared/TrainigList';

const AdvancedTraining = () => {
    const { control, trigger } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: 'advanced_training',
        control,
    });

    const onAdd = () => {
        const newBlock = {
            organization: '',
            position: '',
            end_date: '',
            file: '',
            newField: true,
        };
        append(newBlock);
    };

    return (
        <NewFormSTL>
            <h2 className="title">Повышение квалификации</h2>
            {fields.map((field, index) => {
                return (
                    <div key={field.id}>
                        <TrainigList name={`advanced_training.${index}`} />
                        {fields.length > 1 && (
                            <button
                                type="button"
                                className="button button_del button_border"
                                onClick={() => remove(index)}
                            >
                                Удалить блок
                            </button>
                        )}
                    </div>
                );
            })}
            <button
                type="button"
                className="button"
                onClick={() => {
                    onAdd();
                }}
            >
                <span className="span">+</span> Добавить повышение квалификации
            </button>
        </NewFormSTL>
    );
};

export default AdvancedTraining;
