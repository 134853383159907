import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import './index.css';
import store from './store/index.ts';
import router from './routes/Routes';

const theme = {
    // медиа запросы
    device: {
        mobile: `(max-width: 400px)`,
        tablet: `(max-width: 768px)`,
        laptop: `(max-width: 1024px)`,
        dasktop: `(max-width: 1200px)`,
    },
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    </Provider>
);
