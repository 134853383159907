import { ReactComponent as Pensil } from '../../../../../img/icons/pencil.svg';
import React from 'react';
import { TitleWithPencilStl } from './titleWithPencil.styled';

export const TitleWithPencil = ({ title, toggle, type = 'h2' }) => {
    const TagName = type;
    const className = type === 'h2' ? 'title' : 'subtitle';
    return (
        <TitleWithPencilStl type={type}>
            <TagName className={className}>{title}</TagName>
            <Pensil onClick={() => toggle((prev) => !prev)} />
        </TitleWithPencilStl>
    );
};
